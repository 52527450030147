import React, { useContext } from "react";
import {
  Typography,
  Dialog,
  DialogContent,
  Button,
  Box,
  TextField,
  IconButton,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { SocialContext } from "src/context/SocialAuth";
import CloseIcon from "@material-ui/icons/Close";

export default function NotificationConfirmation({
  title,
  desc,
  isLoading,
  open,
  handleClose,
  note,
}) {
  const history = useHistory();

  return (
    <Dialog
      open={open}
      onClose={() => {
        if (!isLoading) {
          handleClose();
        }
      }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      maxWidth="xs"
    >
      <div className="dialogpurpleShade"></div>
      <div className="dialogpinkShade"></div>
      <DialogContent style={{ position: "relative" }}>
        <Box style={{ position: "absolute", top: "0px", right: "0px" }}>
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Box align="center" my={3}>
          <Typography variant="h2">{title}</Typography>
        </Box>
        <Box align="center" my={note !== undefined ? 1 : 3}>
          <Typography variant="h5">{desc}</Typography>
        </Box>
        {note !== undefined && (
          <Box>
            <ul
              style={{
                padding: "0px",
                marginLeft: "23px",
                marginTop: "2px",
              }}
            >
              <li>
                <Typography variant="body2">{note}</Typography>
              </li>
            </ul>
          </Box>
        )}
      </DialogContent>
    </Dialog>
  );
}
