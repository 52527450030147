import { Box, Typography, makeStyles, Paper } from "@material-ui/core";
import React from "react";
import { useHistory } from "react-router-dom";
import PoolProgressBar from "./poolsection/PoolProgressBar";

const useStyles = makeStyles((theme) => ({
  pollresultCardBox: {
    position: "relative",
    "& .poolcontentBox": {
      [theme.breakpoints.down("xs")]: {
        padding: "20px",
      },
    },
  },
}));

export default function PollResultCards({ pollDetails }) {
  const classes = useStyles();
  const history = useHistory();
  return (
    <Paper elevation={1} className="main_postCardBox">
      <Box className={classes.pollresultCardBox}>
        <Box className="poolcontentBox">
          <Box mt={2} mb={2}>
            <Typography variant="h4" color="secondary">
              {pollDetails?.pollDetails?.poll_topic}
            </Typography>

            <Box mt={5}>
              <PoolProgressBar pollDetails={pollDetails} />
            </Box>

            <Box mt={3} className="displayStart">
              <Typography variant="body2" style={{ color: "#fff" }}>
                {pollDetails?.pollDetails?.total_votes} votes
              </Typography>
              <span
                style={{
                  width: "5px",
                  height: "5px",
                  borderRadius: "50px",
                  color: "#fff",
                }}
              ></span>
              <span style={{ color: "#FDC763" }}> Final results</span>
            </Box>
          </Box>
        </Box>
      </Box>
    </Paper>
  );
}
