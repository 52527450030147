import React from "react";
import { motion } from "framer-motion";

const MotionDiv = ({ children }) => {
  return (
    <div>
      <motion.div
        initial={{ x: 30, opacity: 0 }}
        animate={{ x: 0, opacity: 1 }}
        exit={{ x: -50, opacity: 0 }}
        transition={{ duration: 0.7 }}
      >
        {children}
      </motion.div>
    </div>
  );
};

export default MotionDiv;
