import { Box, Dialog, DialogContent, IconButton } from "@material-ui/core";
import React from "react";
import { MdCancel } from "react-icons/md";
import Comment from "./Comment";
import { useHistory } from "react-router-dom";

export default function PostCommonModal({
  modalData,
  setOpenModal,
  openModal,
  arrayData,
  type,
  shareType,
  callBackFun,
  setImagePostList,
  index,
  stateData,
}) {
  const history = useHistory();
  return (
    <Dialog
      open={openModal}
      onClose={() => setOpenModal(false)}
      fullScreen={true}
      maxWidth="xxl"
      height
    >
      {shareType !== "shareId" && (
        <Box className="commentBoxModalHeader">
          <IconButton
            className="canelIcon"
            onClick={() => {
              stateData === "notification_sec" && history.goBack();
              setOpenModal(false);
            }}
          >
            <MdCancel />
          </IconButton>
        </Box>
      )}
      <DialogContent>
        <Comment
          modalData={modalData}
          type={type}
          arrayData={arrayData}
          callBackFun={callBackFun}
          setOpenModal={setOpenModal}
          setImagePostList={(item) => setImagePostList(item)}
          index={index}
        />
      </DialogContent>
    </Dialog>
  );
}
