import { postAPIHandlerIdInBody } from "src/Apiconfigs/service";
import {
  Container,
  Typography,
  Box,
  makeStyles,
  Grid,
  TextField,
  InputAdornment,
  Button,
  Divider,
  FormHelperText,
} from "@material-ui/core";
import { Form, Formik } from "formik";
import React from "react";
import * as yep from "yup";

const useStyles = makeStyles((theme) => ({
  mainbox: {
    display: "flex",
    justifyContent: "center",
  },
  logobox: {
    "& p": {
      width: "100%",
    },
  },
  gridflex1: {
    "& h6": {
      borderLeft: "2px solid #EAB73B",
      paddingLeft: "15px",
      marginBottom: "10px",
    },
    "& p": {
      fontSize: "12px",
      padding: "10px 17px",
      [theme.breakpoints.down("xs")]: {
        fontSize: "10px",
      },
    },
    [theme.breakpoints.down("xs")]: {
      marginTop: "0px !important",
    },
  },
  gridflex2: {
    "& h6": {
      borderLeft: "2px solid #EAB73B",
      paddingLeft: "15px",
      marginBottom: "24px",
    },
    "& p": {
      fontSize: "12px",
      padding: "5px 0px",
      [theme.breakpoints.down("xs")]: {
        fontSize: "10px",
      },
    },
    "& .textfield": {
      "& button": {
        padding: "9px 26px",
        [theme.breakpoints.down("xs")]: {
          padding: "4px 18px",
        },
      },
      "& .MuiOutlinedInput-adornedEnd": {
        paddingRight: "0px",
        height: "46px",
      },
    },
    [theme.breakpoints.down("xs")]: {
      marginTop: "0px !important",
    },
  },
  bottombox: {
    display: "flex",
    justifyContent: "space-between",
    padding: "24px 0px",
    "& .rightbox": {
      display: "flex",
      "& p": {
        marginLeft: "20px",
        fontSize: "12px",
      },
      [theme.breakpoints.down("xs")]: {
        display: "none",
      },
    },
    [theme.breakpoints.down("xs")]: {
      display: "flex",
      padding: "10px",
      justifyContent: "center",
    },
  },
}));

function Footer() {
  const classes = useStyles();
  const formInitialSchema = {
    email: "",
  };

  const formValidationSchema = yep.object().shape({
    email: yep
      .string()
      .email("Please enter a valid email address.")
      .max(256, "Should not exceeds 256 characters.")
      .required("Email is required."),
  });

  return (
    <>
      <Box className={classes.mainbox}>
        <Container>
          <Box mb={3}>
            <Divider style={{ background: "#ededed59" }} />
          </Box>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={5}>
              <Box className={classes.logobox}>
                <Box>
                  <img src="./images/logologin.png" />
                </Box>
                <Box>
                  <Typography variant="body1">
                    Lorem ipsum dolor sit amet consectetur. Sed posuere eu risus
                    et sit. Senectus sit enim vulputate sed. Elementum eu
                    condimentum dictum viverra amet orci vel urna. Eros eu
                    tristique sem quam velit nascetur morbi platea habitasse.
                    Neque nisi nec elementum consequat egestas dignissim sed.
                    Ut.
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              {" "}
              <Box mt={6} className={classes.gridflex1}>
                {window.location.pathname !== "/policy-privacy" ||
                  window.location.pathname !== "/term-conditions" ||
                  (window.location.pathname !== "/faq-section" && (
                    <>
                      <Typography variant="body1">Privacy Policy</Typography>
                      <Typography variant="body1">About Us</Typography>
                      <Typography variant="body1">
                        Terms & Conditions
                      </Typography>
                    </>
                  ))}
              </Box>
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
              <Formik
                initialValues={formInitialSchema}
                initialStatus={{
                  success: false,
                  successMsg: "",
                }}
                validationSchema={formValidationSchema}
              >
                {({
                  errors,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  touched,
                  values,
                  setFieldValue,
                }) => (
                  <Form>
                    <Box mt={6} className={classes.gridflex2}>
                      <Typography variant="h6">
                        SIGN UP FOR EMAIL UPDATE
                      </Typography>
                      <TextField
                        type="text"
                        variant="outlined"
                        fullWidth
                        placeholder="Your e-mail address"
                        className="textfield"
                        name="email"
                        id="email"
                        value={values.email}
                        error={Boolean(touched.email && errors.email)}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <Button
                                fullWidth
                                variant="contained"
                                color="secondary"
                                type="submit"
                              >
                                Subscribe
                              </Button>
                            </InputAdornment>
                          ),
                        }}
                      />
                      <FormHelperText error>
                        {touched.email && errors.email}
                      </FormHelperText>
                      <Typography variant="body1">
                        Subscribe us using your email address to receive news
                        and updates.
                      </Typography>
                    </Box>
                  </Form>
                )}
              </Formik>
            </Grid>
          </Grid>
          <Box className={classes.bottombox}>
            <Box>
              <Typography variant="body1">
                Copyright @ 2022 Tarality. All rights reserved.
              </Typography>
            </Box>

            <Box className="rightbox">
              <Typography>Market</Typography>
              <Typography>Exchange</Typography>
              <Typography>Staking</Typography>
              <Typography>Wallet</Typography>
            </Box>
          </Box>
        </Container>
      </Box>
    </>
  );
}

export default Footer;
