import React, { useState, useEffect, useContext, useMemo } from "react";
import {
  makeStyles,
  Dialog,
  IconButton,
  Typography,
  Box,
  Button,
  Avatar,
  Radio,
  FormControlLabel,
  Checkbox,
  TextField,
  FormHelperText,
  Grid,
} from "@material-ui/core";
import Picker from "emoji-picker-react";
import { IoClose } from "react-icons/io5";
import { motion, AnimatePresence } from "framer-motion";
import axios from "axios";
import ApiConfig from "src/Apiconfigs/config";
import { SocialContext } from "src/context/SocialAuth";
import { IMAGE_PATH } from "src/constants";
import { MentionsInput, Mention } from "react-mentions";
import { Autocomplete } from "@material-ui/lab";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import { BsEmojiSmile } from "react-icons/bs";
import { formatTextDescrition } from "src/utils";
import ReactHashtag from "react-hashtag";
import { useHistory } from "react-router-dom";
import { MdPermMedia, MdDelete } from "react-icons/md";

import {
  getDataHandlerAPI,
  getPerticularIdByPath,
} from "src/Apiconfigs/service";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .headingSec": {
      position: "absolute",
      background: "rgba(255, 255, 255, 0.03)",
      width: "100%",
      top: "-8 ",
      left: "0",
      padding: "13px 20px",
      marginTop: "-20px",
    },
    "& .headingSec1": {
      position: "absolute",
      background: "rgba(255, 255, 255, 0.03)",
      width: "100%",
      bottom: "0",
      left: "0",
      padding: "13px 20px",
      marginTop: "41px",
      "& button": {
        background: "rgba(255, 255, 255, 0.03)",
        marginRight: "24px",
        color: "#C7762B",
      },
    },
    "& .cancelBtn": {
      top: "8px !important",
    },
  },
  createmainmodal: {
    "& .MuiFormControlLabel-root": {
      marginRight: "0px !important",
    },
    "& .textBox": {
      [theme.breakpoints.down("xs")]: {
        maxWidth: "179px",
      },
    },
    "& .MuiDropzoneArea-icon": {
      color: "#404040",
      width: "35px",
      height: "35px",
    },
    "& .MuiOutlinedInput-root": {
      color: "gray",
      height: "40px",
    },
    "& .addphotos": {
      cursor: "pointer",
      position: "relative",
      textAlign: "center",
      borderRadius: "0px",
      marginBottom: "80px",

      "& svg": {
        cursor: "pointer",
        border: "none",
        background: "rgba(255, 255, 255, 0.03)",
        borderRadius: "0",
        padding: "20px",
      },
      "& img": {
        width: "100%",
        borderRadius: "5px",
        height: "100%",
      },
      "& video": {
        width: "100% !important",
        borderRadius: "5px",
        height: "100% !important",
      },

      "& .mainBoxClx": {
        height: "98px",
        position: "relative",
        "& button": {
          display: "none",
        },
        "&:hover": {
          "& button": {
            display: "block  !important",
            position: "absolute !important",
            top: "-31px !important",
            right: "-22px !important",
            "& svg": {
              padding: "10px !important",
              borderRadius: "50% !important",
            },
          },
        },
      },
    },
  },
  emojiBox: {
    display: "flex",
    justifyContent: "center",
    position: "relative",
    "& .EmojiPickerReact": {
      backgroundColor: "#252525",
    },
    "& .EmojiPickerReact .epr-header .epr-header-overlay": {
      display: "none",
    },
    "& .EmojiPickerReact li.epr-emoji-category>.epr-emoji-category-label": {
      backgroundColor: "#252525",
      height: "20px",
    },
    "& aside.EmojiPickerReact.epr-main": {
      borderStyle: "none",
    },
    "& .EmojiPickerReact .epr-preview": {
      display: "none",
    },
  },
  cancelBtn1: {
    position: "absolute",
    top: "-6px",
    right: "44px",
    zIndex: "1",
  },
  usericon: {
    display: "flex",
    alignItems: "center",
    margin: "15px 0px",
    "& h4": {
      marginLeft: "20px",
    },
  },
  usericon1: {
    alignItems: "center",
    margin: "3px 0px",
    minHeight: "150px",
    "& h4": {
      marginLeft: "20px",
    },
  },
  hashTagSuggestions: {
    "& h4": {
      color: "#000",
      fontSize: "14px",
      padding: "4px",
    },
  },
  postType: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    marginTop: "10px",
    alignItems: "center",
    "& .MuiCheckbox-root": {
      color: "gray !important",
    },
    "& .MuiCheckbox-colorSecondary.Mui-checked": {
      color: "#FDC763  !important",
    },
    "& h6": {
      whiteSpace: "nowrap",
      color: "rgba(255, 255, 255, 0.4)",
    },
  },
  autocomplete: {
    "& .MuiOutlinedInput-root": {
      height: "auto !important",
    },
  },
  menClx: {
    position: "relative",
    display: "flex",
    "& textarea": {
      maxHeight: "150px",
      minHeight: "150px",
      overflow: "scroll !important",
    },

    "& .divClx": {
      maxHeight: "150px",
    },
    "& .divClx__suggestions": {
      top: "30px !important",
      maxHeight: "150px",
      overflow: "auto",
    },
    "& .divClx__highlighter": {
      maxHeight: "150px",
    },
  },
  emjclx: {
    position: "absolute",
    right: "0px",
    zIndex: "101",
  },
  timelineClx: {
    background: "#1e1d1d",
    padding: "1px 10px",
    marginTop: "8px",
  },
  mediaBox: {
    marginTop: "10px",
    cursor: "pointer",
    background: " rgba(255, 255, 255, 0.03)",
    width: "70px",
    height: "70px",
    cursor: "pointer",
    "& svg": {
      cursor: "pointer",
      fontSize: "20px",
      color: "#ACACAC",
    },
  },
}));

function CreateFeedModal({
  feedmodal,
  setFeedModal,
  type,
  businessId,
  previousData,
  timeLine,
}) {
  const classes = useStyles();
  const [showPicker, setShowPicker] = useState(false);
  const social = useContext(SocialContext);
  const { userData } = social;
  const history = useHistory();
  const [filedata, setFile] = useState([]);
  const [formattedValue, setFormattedValue] = useState("");
  const [hashTagList, setHashTagList] = useState([]);
  const [checked, setChecked] = useState(true);
  const [inputStr, setInputStr] = useState("");
  const [postType, setPostType] = useState("crypto");
  const [tags, setTags] = useState([]);
  const [mentions, setMentions] = useState([]);
  const [interestList, setInterestList] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState();
  const [errMsg, setErrMsg] = useState("");
  const [mesntionsList, setMentionList] = useState([]);
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  const handleChangeInterest = (event, value) => setSelectedOptions(value);

  const onEmojiClick = (event) => {
    setInputStr((data) => data + event.emoji);
  };

  const getHashTagList = async () => {
    try {
      const response = await axios({
        method: "GET",
        url: ApiConfig.hash_tags_suggestion,
        headers: {
          authorization: window.localStorage.getItem("token"),
        },
      });
      if (response.data.data !== 0) {
        const ArrayRecreator = response?.data?.data?.map((data, i) => {
          return {
            id: data?.id?.toString(),
            display: `#${data?.name}`,
          };
        });

        setHashTagList(ArrayRecreator);
      } else {
        setHashTagList([]);
      }
    } catch (err) {
      console.error(err);
    }
  };
  const getMentionuserList = async (id) => {
    try {
      const response = await getPerticularIdByPath("following", id, {
        page: 1,
      });
      if (response.data.data !== 0) {
        const ArrayRecreator = response?.data?.data?.map((data, i) => {
          return {
            id: data?.following_user_data?.user_id?.toString(),
            display: `@${data?.following_user_data?.name}`,
          };
        });
        setMentionList(ArrayRecreator);
      }
    } catch (error) {
      console.log(error);
    }
  };
  function renderTagSuggestion(data) {
    return (
      <Box className={classes.hashTagSuggestions}>
        <Typography variant="h4">{data?.display}</Typography>
      </Box>
    );
  }
  function onAdd(id, display) {
    setTags([...tags, display]);
  }
  function onAddMentions(id, display) {
    setMentions([
      ...mentions,
      {
        name: display,
        id: id,
      },
    ]);
  }
  const textFormatter = (text) => {
    const splitText = text?.split(" ");
    let result = [];
    for (let i = 0; i < splitText?.length; i++) {
      const findTag = splitText[i]?.includes("#");
      if (findTag) {
        const getTheHash = splitText[i]?.split("@[")[1]?.split("]")[0];
        result.push(getTheHash);
      } else {
        result.push(splitText[i]);
      }
    }

    setFormattedValue(result?.join(" "));
  };

  const areaOfInterestsHandler = async () => {
    try {
      const res = await getDataHandlerAPI("areaOfInterests");

      if (res) {
        setInterestList(res.data.data);
      }
    } catch (error) {}
  };
  useEffect(() => {
    if (inputStr !== "") {
      textFormatter(inputStr);
    }
  }, [inputStr]);

  useEffect(() => {
    if (feedmodal) {
      areaOfInterestsHandler();
      getHashTagList();
    }
  }, [feedmodal]);
  useEffect(() => {
    if (type === "edit" && previousData) {
      setInputStr(
        timeLine === "timeline"
          ? ""
          : previousData?.shared_user_data
          ? previousData?.caption
          : previousData?.description
          ? previousData?.description
          : ""
      );
      setFormattedValue(
        previousData?.description ? previousData?.description : ""
      );
      setChecked(previousData?.type === "public" ? true : false);
      setPostType(previousData?.post_type);
      setFile(
        previousData?.post_meta_data
          ? previousData?.post_meta_data[0]?.post_type === "image" &&
            previousData?.post_meta_data[0]?.url === ""
            ? []
            : previousData?.post_meta_data
          : []
      );
      setTags(previousData?.post_tags ? previousData?.post_tags : []);
      setSelectedOptions(previousData?.post_categories);
    }
  }, [previousData, type]);
  useEffect(() => {
    var result = hashTagList.filter(function (o1) {
      // filter out (!) items in result2
      return !tags.some(function (o2) {
        return o1.display?.toLowerCase() === o2?.toLowerCase(); // assumes unique id
      });
    });
    setHashTagList(result);
  }, [tags]);

  return (
    <>
      <AnimatePresence>
        <Dialog
          open={feedmodal}
          onClose={() => {
            setFeedModal(false);
            setInputStr("");
          }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          maxWidth="sm"
          fullWidth
          className={classes.root}
        >
          <motion.div>
            <Box>
              <Box className="headingSec">
                <Typography variant="h6">Create Post</Typography>
              </Box>
            </Box>
            {timeLine !== "timeline" && (
              <>
                <Box className={classes.postType} style={{ marginTop: "50px" }}>
                  <Typography variant="h6">Post category :</Typography>

                  <Box width="100%">
                    <FormControlLabel
                      control={
                        <Radio
                          checked={postType === "crypto"}
                          onChange={(e) => {
                            setPostType(e.target.value);
                            setErrMsg("");
                          }}
                        />
                      }
                      label="Crypto"
                      value="crypto"
                    />
                    <FormControlLabel
                      control={
                        <Radio
                          checked={postType === "other"}
                          onChange={(e) => {
                            setPostType(e.target.value);
                            setErrMsg("");
                          }}
                        />
                      }
                      label="Other"
                      value="other"
                    />
                  </Box>
                  {postType === "other" && (
                    <Box width="100%" className={classes.autocomplete}>
                      <Autocomplete
                        id="checkboxes-tags-demo"
                        options={interestList}
                        disableCloseOnSelect
                        onChange={handleChangeInterest}
                        getOptionLabel={(option) => option.name}
                        renderOption={(option, { selected }) => (
                          <React.Fragment>
                            <Checkbox
                              icon={icon}
                              checkedIcon={checkedIcon}
                              style={{ marginRight: 8 }}
                              checked={selected}
                            />
                            {option.name}
                          </React.Fragment>
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            placeholder="Post categories"
                            style={{ padding: "0px 5px" }}
                          />
                        )}
                      />
                      {errMsg !== "" && selectedOptions == undefined && (
                        <FormHelperText error>{errMsg}</FormHelperText>
                      )}
                    </Box>
                  )}
                </Box>
              </>
            )}
            <Box mt={timeLine !== "timeline" ? 2 : 6}>
              <Box className={classes.usericon1}>
                <Box className={classes.menClx}>
                  <Avatar src={`${IMAGE_PATH}` + userData?.profile_url} />

                  <MentionsInput
                    markup="display"
                    size="small"
                    fullWidth
                    placeholder="Write a caption...|"
                    onChange={(e) => {
                      if (e?.target?.value?.length <= 500) {
                        setInputStr(e.target.value);
                      }
                    }}
                    rows={4}
                    style={{
                      border: "none",
                      outline: "none",
                      position: "relative",
                      zIndex: 100,
                      width: "85%",
                      marginLeft: "10px",
                    }}
                    value={inputStr}
                    className="divClx"
                  >
                    <Mention
                      trigger="#"
                      data={hashTagList}
                      onAdd={onAdd}
                      renderSuggestion={renderTagSuggestion}
                      appendSpaceOnAdd={true}
                    />
                  </MentionsInput>
                  <Box align="end" className={classes.emjclx}>
                    <IconButton onClick={() => setShowPicker((val) => !val)}>
                      <BsEmojiSmile
                        style={{
                          color: "#FDC763",
                          fontSize: "20px",
                          cursor: "pointer",
                        }}
                      />
                    </IconButton>
                  </Box>
                </Box>
              </Box>
              <Box align="end">
                <Typography variant="body1">{inputStr?.length}/500</Typography>
              </Box>
              {showPicker && (
                <Box className={classes.emojiBox} mt={2} mb={1}>
                  <IconButton
                    className={classes.cancelBtn1}
                    onClick={() => setShowPicker(false)}
                  >
                    <IoClose className="closeIconColor" />
                  </IconButton>
                  <Picker
                    height={288}
                    width={500}
                    onEmojiClick={onEmojiClick}
                  />
                </Box>
              )}
            </Box>
            {type !== "edit" && (
              <>
                <input
                  style={{ display: "none" }}
                  id="raised-button-file"
                  multiple
                  maxSize="280000000"
                  maxlength="10"
                  type="file"
                  accept="image/png,image/jpeg,image/gif,image/jpg,video/*"
                  onChange={(e) => {
                    setFile([...filedata, ...e.target.files]);
                  }}
                />
                {filedata.length <= 9 && (
                  <Box>
                    <label
                      htmlFor="raised-button-file"
                      className={`${classes.mediaBox} displayCenter`}
                    >
                      <MdPermMedia />
                    </label>
                  </Box>
                )}
              </>
            )}

            <IconButton
              className="cancelBtn"
              onClick={() => setFeedModal(false)}
            >
              <IoClose className="closeIconColor" />
            </IconButton>

            <Box className={classes.createmainmodal}>
              <Box
                className="addphotos"
                align="center"
                mt={timeLine === "timeline" ? 3 : 1}
              >
                <Grid container spacing={2}>
                  {filedata?.length !== 0 &&
                    filedata?.map((data, i) => {
                      return (
                        <Grid
                          item
                          lg={2}
                          md={2}
                          sm={3}
                          xs={3}
                          key={`editPost${i}`}
                        >
                          <ImageView
                            data={data}
                            i={i}
                            setFile={setFile}
                            filedata={filedata}
                            type={type}
                          />
                        </Grid>
                      );
                    })}
                </Grid>

                {timeLine === "timeline" && (
                  <Box className={classes.timelineClx}>
                    <Box className={classes.usericon}>
                      <Avatar
                        src={
                          `${IMAGE_PATH}` + previousData?.user_data?.profile_url
                        }
                      />
                      <Typography variant="h4" style={{ whiteSpace: "nowrap" }}>
                        {previousData?.user_data?.name
                          ? previousData?.user_data?.name
                          : "..."}
                      </Typography>
                    </Box>
                    <Box>
                      <Typography
                        variant="h6"
                        style={{
                          wordBreak: "break-word",
                          textAlign: "start",
                        }}
                      >
                        {previousData?.description && (
                          <ReactHashtag
                            renderHashtag={(hashtagValue) => (
                              <div
                                className="hasgTagTextColor"
                                onClick={() =>
                                  history.push({
                                    pathname: "/trending-hash",
                                    search: hashtagValue,
                                  })
                                }
                              >
                                &nbsp;{hashtagValue}&nbsp;
                              </div>
                            )}
                          >
                            {formatTextDescrition(previousData?.description)}
                          </ReactHashtag>
                        )}
                      </Typography>
                    </Box>
                  </Box>
                )}
              </Box>

              <Box className="headingSec1">
                <Box className="displaySpacebetween">
                  <Box display="flex">
                    <Checkbox
                      checked={!checked}
                      onChange={() => setChecked(!checked)}
                    />
                    <Box className="textBox displayCenter">
                      <Typography variant="body1">
                        Make my posts private.
                      </Typography>
                    </Box>
                  </Box>
                  <Button
                    style={{ padding: "9px 34px", borderRadius: "0px" }}
                    onClick={() => {
                      setErrMsg("");

                      if (inputStr.trim() !== "" || filedata.length !== 0) {
                        if (
                          postType === "other" &&
                          selectedOptions === undefined
                        ) {
                          setErrMsg("Please select post category.");
                        } else {
                          social.createPostHandler({
                            filedata: filedata,
                            selectedTag: tags,
                            postType: postType,
                            formattedValue: formattedValue,
                            checked: checked,
                            businessId: businessId,
                            categories: selectedOptions,
                            type: type,
                            timeLine: timeLine,
                            postId: previousData,
                          });
                          setFeedModal(false);
                          setInputStr("");
                          setSelectedOptions();
                          setFile([]);
                        }
                      }
                    }}
                  >
                    Post
                  </Button>
                </Box>
              </Box>
            </Box>
          </motion.div>
        </Dialog>
      </AnimatePresence>
    </>
  );
}

export default CreateFeedModal;

const ImageView = ({ data, i, setFile, filedata, type }) => {
  function typeEdit(itemData) {
    return type !== "edit"
      ? data?.type === "image/png" ||
          data?.type === "image/jpeg" ||
          data?.type === "image/gif" ||
          data?.type === "image/jpg"
      : data.post_type === "image";
  }
  return (
    <Box className="mainBoxClx" key={`nana${i}`}>
      {type !== "edit" && (
        <IconButton
          onClick={() => setFile(filedata?.filter((itm, index) => index !== i))}
        >
          <MdDelete />
        </IconButton>
      )}
      {typeEdit(data) ? (
        <img
          src={
            type !== "edit"
              ? URL.createObjectURL(data)
              : `${IMAGE_PATH}${data?.url}`
          }
        />
      ) : (
        <video
          controls={false}
          loop={false}
          playsinline="true"
          className="postImg11"
        >
          <source
            src={
              type !== "edit"
                ? URL.createObjectURL(data)
                : `${IMAGE_PATH}${data?.url}`
            }
            type="video/ogg"
          />
        </video>
      )}
    </Box>
  );
};
