import React, { useState } from "react";
import {
  Box,
  Dialog,
  DialogContent,
  IconButton,
  Button,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { MdBlock } from "react-icons/md";
import CloseIcon from "@material-ui/icons/Close";
import { putAPIHandler } from "src/Apiconfigs/service";
import ButtonCircularProgress from "src/component/ButtonCircularProgress";
import toast from "react-hot-toast";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  mainbox: {
    padding: "50px",
    [theme.breakpoints.down("xs")]: {
      padding: "50px 20px",
    },
    "& h4": {
      margin: "10px 0px",
      fontWeight: "600",
    },
    "& p": {
      letterSpacing: "0.5px",
      width: "100%",
      fontWeight: "400",
    },
  },
  cancelBtn: {
    position: "absolute",
    top: 0,
    right: 0,
  },
  blockbtn: {
    "& svg": {
      border: "0.916667px solid #FDC763",
      padding: "15px",
      fontSize: "45px",
      borderRadius: "10px",
      color: "#C7762B",
    },
  },
}));

export default function BlockModal({
  openBlock,
  handleCloseBlock,
  index,
  postIdData,
  getBlockUserList,
  particularUserId,
  social,
  type,
}) {
  const classes = useStyles();
  const [blockUserLoading, setBlockUserLoading] = useState(false);
  const history = useHistory();

  const submitBlockhandle = async () => {
    setBlockUserLoading(true);
    try {
      const response = await putAPIHandler("block_unblock", {
        action: type === "Unblock" ? "0" : "1",
        to_id:
          type === "chatUser"
            ? particularUserId?.other_user_id
            : postIdData?.user_data?.user_id
            ? postIdData?.user_data?.user_id
            : postIdData?.block_user_detail?.user_id
            ? postIdData?.block_user_detail?.user_id
            : postIdData?.id,
        from_type: social?.userData?.user_type,
        to_type:
          type === "chatUser"
            ? particularUserId?.user_type
            : postIdData?.user_data?.user_type
            ? postIdData?.user_data?.user_type
            : postIdData?.to_type
            ? postIdData?.to_type
            : postIdData?.user_type,
      });
      if (response.data) {
        if (type === "Unblock") {
          toast.success(response.data.message);
          getBlockUserList();
          handleCloseBlock();
          setBlockUserLoading(false);
        } else {
          toast.success(response.data.message);
          if (type === "chatUser") {
            history.push("/home");
          }
          social.getHomePagePostList(1, social?.postListData?.length, []);
          handleCloseBlock();
          setBlockUserLoading(false);
        }
      } else {
        toast.error(response.data.message);
        setBlockUserLoading(false);
      }
    } catch (err) {
      console.log(err);
      setBlockUserLoading(false);
      toast.error(err.response.data.message);
    }
  };
  return (
    <Box>
      <Dialog
        open={openBlock}
        onClose={handleCloseBlock}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="xs"
        fullWidth
        key={`blockUnblockModal${index}`}
      >
        <IconButton
          className={classes.cancelBtn}
          onClick={handleCloseBlock}
          disabled={blockUserLoading}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <Box align="center" className={classes.mainbox}>
            <Box className={classes.blockbtn}>
              <MdBlock />
            </Box>
            <Typography variant="h4">
              {type === "Unblock" ? "Unblock" : "Block"}
            </Typography>
            <Typography variant="body2">
              Are your sure to {type === "Unblock" ? "Unblock" : "block"} this
              user?
            </Typography>

            <Box mt={3} align="center">
              <Button
                variant="contained"
                color="primary"
                onClick={submitBlockhandle}
                disabled={blockUserLoading}
                style={{ width: "85px", height: "40px" }}
              >
                Yes{blockUserLoading && <ButtonCircularProgress />}
              </Button>
              <Button
                variant="contained"
                color="secondary"
                size="large"
                style={{ marginLeft: "10px", borderRadius: "50px" }}
                onClick={handleCloseBlock}
                disabled={blockUserLoading}
              >
                Cancel
              </Button>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    </Box>
  );
}
