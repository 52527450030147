import _ from "lodash";
import { colors, createTheme, responsiveFontSizes } from "@material-ui/core";
import typography from "./typography";

const baseOptions = {
  direction: "ltr",
  typography,
  overrides: {
    MuiPickerDTTabs: {
      tabs: {
        color: "none",
        backgroundColor: "none",
      },
    },
    MuiDropzoneArea: {
      test: {
        opacity: "1",
      },
      root: {
        minHeight: "284px",
        "@media (max-width:767px)": {
          minHeight: "184px",
        },
      },
    },
    MuiAccordionSummary: {
      root: {
        background: "rgb(20 20 20)",
        borderRadius: "10px",
      },
    },
    MuiRating: {
      root: {
        fontSize: "14px",
      },
    },
    MuiDialogContent: {
      root: {
        padding: "10px !important",
        paddingTop: "10px !important",
      },
    },
    MuiFormGroup: {
      root: {
        display: "flex",
        flexWrap: "wrap",
        flexDirection: "column",
      },
    },
    MuiInputBase: {
      root: {
        color: "#fff",
      },
      input: {
        color: "#FFFFFF",
        height: "1.1876em ",
        "&::placeholder": {
          color: "#FFFFFF",
        },
      },
    },

    Component: {
      paper: {
        backgroundColor: "#2F3336 !important",
      },
    },
    MuiListSubheader: {
      root: {
        color: "#404040",
        fontSize: "18px",
        boxSizing: "border-box",
        listStyle: "none",
        fontWeight: "500",
        lineHeight: "48px",
      },
    },
    MuiDivider: {
      root: {
        backgroundColor: "#EDEDED",
      },
    },

    MuiSvgIcon: {
      fontSizeLarge: {
        fontSize: "30px",
      },
    },

    MuiDialog: {
      paperFullScreen: {},
      paper: {
        background: "#171717 !important",
        overflow: "hidden",
        position: "relative",
        border: "1px solid rgba(255, 255, 255, 0.1)",
        padding: "20px",

        "&::before": {
          top: "0",
          left: "0",
          width: "100%",
          height: "100%",
          content: "''",
          zIndex: "-1",
          position: "absolute",
          backdropFilter: "blur(30px)",
        },
      },
    },
    MuiSelect: {
      icon: {
        color: "#535353",
      },
      root: {
        display: "flex",
        alignItems: "center",
        justifyContent: "start",
        paddingLeft: "15px",
        borderRadius: "18px",
      },
    },
    MuiPickersDay: {
      day: {
        color: "#ACACAC",
      },
    },
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: "#6FCFB9",
      },
      toolbar: {
        backgroundColor: "#fdc763",
      },
    },
    MuiPickersToolbarText: {
      toolbarTxt: {
        color: "#FFFFFF !important",
      },
    },
    MuiPickersToolbarText: {
      toolbarBtnSelected: {
        color: "#FFFFFF !important",
      },
    },
    MuiTable: {
      root: {
        borderCollapse: "separate",
        borderSpacing: "0px 2px",
      },
    },
    MuiTableCell: {
      body: {
        padding: "10px 15px",
        color: "#fff",
      },
      root: {
        borderBottom: "none",
        background: "transparent",
        fontSize: "12px",
      },
      head: {
        background: "transparent",
        color: "rgba(255, 255, 255, 0.6)",
        borderTop: "1px solid rgba(255, 255, 255, 0.1)",
        borderBottom: "1px solid rgba(255, 255, 255, 0.1)",
        whiteSpace: "pre",
      },
    },
    MuiTableRow: {
      root: {
        "&:hover": {
          background: "#FFFFFF05",
        },
      },
    },
    MuiPaginationItem: {
      page: {
        "& .Mui-selected": {
          background: "rgba(255, 255, 255, 0.05) !important",
        },
      },
      ellipsis: {
        height: "32px",
      },
      root: {
        backgroundColor: "#191919",
        opacity: "1",
        color: "#fff",
      },
    },
    MuiInput: {
      root: {
        borderRadius: "10px",
      },
      underline: {
        "&::before": {
          left: "0",
          right: "0",
          bottom: "0",
          content: '"\\00a0"',
          position: "absolute",
          transition:
            "border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
          borderBottom: "1px solid rgba(255, 255, 255, 0.7)",
          pointerEvents: "none",
        },
      },
    },

    MuiFormGroup: {
      root: {
        display: "flex",
        flexWrap: "inherit",
        flexDirection: "row",
        justifyContent: "space-around",
      },
    },
    MuiFormControlLabel: {
      root: {
        cursor: "pointer",
        display: "inline-flex",
        alignItems: "center",
        marginLeft: "1px",
        verticalAlign: "middle",
      },
    },
    MuiFormControl: {
      marginDense: {
        marginTop: "0px",
      },
    },
    MuiIconButton: {
      root: {
        flex: "0 0 auto",
        color: "#ACACAC",
        padding: "8px",
        overflow: "visible",
        fontSize: "1.5rem",
        textAlign: "center",
        transition: "background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
        borderRadius: "50%",
        "&:hover": {
          backgroundColor: "none",
        },
      },
      colorSecondary: {
        "&:hover": {
          backgroundColor: "transparent",
        },
      },
    },
    MuiButton: {
      textPrimary: {
        color: "#f5f5f5 ",
      },
      root: {
        fontSize: "14px",
        borderRadius: "8px",
        "&:hover": {
          backgroundColor: "none",
        },
      },
      fullWidth: {
        width: "100%",
      },
      contained: {
        boxShadow: "none",
        backgroundColor: "transparent",
      },
      outlined: {
        boxShadow: "none",
        border: "1px solid #FDC763",
        color: "#fff",
      },
      textPrimary: {
        color: "#404040",
        fontWeight: 400,
        fontSize: "14px !important",
        lineHeight: "17px",
      },
      containedSizeSmall: {
        padding: "4px 10px",
        fontSize: "0.8125rem",
      },
      containedPrimary: {
        border: "1px solid rgba(255, 255, 255, 0.25)",
        borderRadius: "50px",
        color: "#fff",
        backgroundColor: "transparent",
        "&:hover": {
          color: "#fff !important",
          border: "1px solid rgba(255, 255, 255, 0.25)",
          background:
            "linear-gradient(108.43deg, #C7762B 0%, #FDC763 100%) !important",
        },
      },
      containedSecondary: {
        background: "linear-gradient(108.43deg, #C7762B 0%, #FDC763 100%)",
        color: "#fff",

        border: "1px solid #FDC763",
        "&:hover": {
          color: "#fff",
          border: "1px solid rgba(255, 255, 255, 0.25)",
          background: "rgba(255, 255, 255, 0.05) !important",
        },
      },

      outlinedSecondary: {
        color: "#6FCFB9",
        border: "1px solid #6FCFB9",
        backgroundColor: "#fff",
        "&:hover": {
          backgroundColor: "#6FCFB9",
          color: "#fff",
          border: "1px solid #6FCFB9",
        },
      },
    },
    MuiLinearProgress: {
      root: {
        borderRadius: 3,
        overflow: "hidden",
      },
    },
    MuiPaper: {
      root: {
        backgroundColor: "#090909 ",
        border: "1px solid rgb(14 14 14 / 98%)",
        color: "#fff !important",
        borderRadius: "0px !important",
      },

      elevation0: {},
      elevation1: {
        boxShadow: "none",
        padding: "15px",
      },
    },

    MuiOutlinedInput: {
      background: "rgba(255, 255, 255, 0.05) !important",
      inputAdornedEnd: {
        "@media (max-width:767px)": {
          padding: "6px 8px",
          fontSize: "12px",
        },
      },
      root: {
        position: "relative",
        borderRadius: "0px",
        height: "50px",
        backgroundColor: "#1E1D1D !important",
        "@media (max-width:599.9px)": {
          borderRadius: "5px",
          height: "35px",
        },
      },
      input: {
        padding: "11px 0px 11px 14px",
        "&:-webkit-autofill": {
          WebkitBoxShadow: "none",
        },
        "@media (max-width:767px)": {
          padding: " 6px 8px",
          fontSize: "10px",
        },
      },
      notchedOutline: {
        borderColor: "transparent !important",
      },
    },
    MuiList: {
      padding: {
        paddingTop: "none",
        paddingBottom: "none",
      },
    },
    MuiListItemIcon: {
      root: {
        minWidth: 32,
      },
    },
    MuiChip: {
      root: {
        backgroundColor: "rgba(0,0,0,0.075)",
      },
    },
  },
};

const themesOptions = [
  {
    name: "LIGHT",
    overrides: {
      MuiInputBase: {
        root: {},
        input: {
          "&::placeholder": {
            opacity: 1,
            color: "#FFFFFF",
          },
        },
      },
    },
    palette: {
      type: "light",
      action: {
        active: "#FDC763",
      },
      background: {
        default: "rgba(255, 255, 255, 1);",
        dark: "#f4f6f8",
        paper: "#ECECEC",
      },
      secondary: {
        main: "#FF9933",
      },
      text: {
        primary: "#FDC763",
        secondary: "#FDC763",
      },
    },
  },
  {
    name: "DARK",
    palette: {
      type: "dark",
      action: {
        active: "rgba(255, 255, 255, 0.54)",
        hover: "rgba(255, 255, 255, 0.04)",
        selected: "rgba(255, 255, 255, 0.08)",
        disabled: "rgba(255, 255, 255, 0.26)",
        disabledBackground: "rgba(255, 255, 255, 0.12)",
        focus: "rgba(255, 255, 255, 0.12)",
      },
      background: {
        default: "#F5F5F5;",
        dark: "#1c2025",
        paper: "#ffffff",
      },
      primary: {
        main: "#FDC763",
      },
      secondary: {
        main: "#FDC763",
      },
      text: {
        primary: "#404040",
        secondary: "#FDC763",
      },
    },
  },
];

export const createTheme1 = (config = {}) => {
  let themeOptions = themesOptions.find((theme) => theme.name === config.theme);

  if (!themeOptions) {
    console.warn(new Error(`The theme ${config.theme} is not valid`));
    [themeOptions] = themesOptions;
  }

  let theme = createTheme(
    _.merge({}, baseOptions, themeOptions, { direction: config.direction })
  );

  if (config.responsiveFontSizes) {
    theme = responsiveFontSizes(theme);
  }

  return theme;
};
