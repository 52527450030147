import React, { useContext } from "react";
import { Redirect } from "react-router-dom";
import { SocialContext } from "src/context/SocialAuth";

export default function AuthGuard(props) {
  const { children } = props;
  const auth = useContext(SocialContext);
  if (!localStorage.getItem("token")) {
    return <Redirect to="/" />;
  }

  return <>{children}</>;
}
