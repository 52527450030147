import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Checkbox,
  Radio,
} from "@material-ui/core";
import React from "react";

export default function SelectTimeCommon({
  handleChange,
  classes,
  age,
  time,
  week,
  isSubmit,
  title,
  name,
}) {
  return (
    <FormControlLabel
      control={
        <Radio
          name={name}
          checked={week === name}
          onChange={(e) => handleChange(e, name)}
        />
      }
      label={title}
      value={name}
    />
  );
}
