import StoryPreview from "src/views/pages/Dashboard/Story/StoryPreview";
import { Box, makeStyles, Typography } from "@material-ui/core";
import React, { useState } from "react";
import CreateFeedModal from "../CreateFeedModal";
import CreatePoolModal from "./CretePoolModal";
const styles = makeStyles((theme) => ({
  createStoryMain: {
    background: "rgba(255, 255, 255, 0.05)",
    display: "flex",
    alignItems: "center",

    minHeight: "60px",
    marginTop: "10px",
    padding: "0 20px",
    cursor: "pointer",
    "& h3": {
      fontStyle: "normal",
      fontSize: "14px",
      lineHeight: "19px",
      color: "#FFFFFF",
    },
    "& h6": {
      fontStyle: "normal",
      fontSize: "10px",
      color: "rgba(255, 255, 255, 0.6)",
    },
  },
}));

export default function CreateStory({ img, title, desc, type }) {
  const classes = styles();
  const [storyModal, setStoryModal] = useState(false);
  const [openStoryPreview, setStoryPreview] = useState(false);
  const [feedmodal, setFeedModal] = useState(false);

  return (
    <>
      <Box
        onClick={() => {
          if (type === "story") {
            setStoryModal(true);
          } else {
            setFeedModal(true);
          }
        }}
        className={classes.createStoryMain}
      >
        <img src={img} style={{ width: "25px" }} />
        <Box style={{ padding: "19px" }}>
          <Typography variant="h3">{title}</Typography>
          <Typography variant="h6">{desc}</Typography>
        </Box>
      </Box>
      <StoryPreview
        openStoryPreview={openStoryPreview}
        closeStoryPreview={() => setStoryPreview(false)}
        storyModal={storyModal}
        setStoryModal={(item) => setStoryModal(item)}
      />
      <CreatePoolModal
        setFeedModal={setFeedModal}
        feedmodal={feedmodal}
        type="other"
      />
    </>
  );
}
