import React, { lazy } from "react";
import { Redirect } from "react-router-dom";
// import DashboardLayout from "src/layouts/DashboardLayout";
import DashboardLayout from "src/layouts/DashboardLayout";
import HomeLayout from "src/layouts/HomeLayout";
import LoginLayout from "src/layouts/LoginLayout";
import WebLayout from "src/layouts/WebLayout";
import TaralSettingsLayout from "src/layouts/TaralSettingsLayout";
export const routes = [
  // {
  //   exact: true,
  //   path: "/",
  //   layout: LoginLayout,
  //   component: lazy(() => import("src/views/auth/Login")),
  // },
  {
    exact: true,
    path: "/",
    layout: WebLayout,
    component: lazy(() => import("src/views/pages/LandingPage")),
  },
  {
    exact: true,
    path: "/earn1",
    layout: DashboardLayout,
    component: lazy(() => import("src/component/Earn1")),
  },

  {
    exact: true,
    path: "/about",
    layout: WebLayout,
    component: lazy(() => import("src/views/pages/LandingPage/About")),
  },

  {
    exact: true,
    path: "/privacy",
    layout: WebLayout,
    component: lazy(() => import("src/views/pages/LandingPage/Privacy")),
  },
  {
    exact: true,
    path: "/terms",
    layout: WebLayout,
    component: lazy(() => import("src/views/pages/LandingPage/Terms")),
  },
  {
    exact: true,
    path: "/reset-otp",
    layout: LoginLayout,
    component: lazy(() => import("src/views/auth/ResetOtp")),
  },
  {
    exact: true,
    path: "/reset-password",
    layout: LoginLayout,
    component: lazy(() => import("src/views/auth/reset-password/index")),
  },
  // {
  //   exact: true,
  //   path: "/signup",
  //   layout: LoginLayout,
  //   component: lazy(() => import("src/views/auth/signup/signup")),
  // },
  // {
  //   exact: true,
  //   path: "/forget-password",
  //   layout: LoginLayout,
  //   component: lazy(() => import("src/views/auth/forget-password/index")),
  // },
  {
    exact: true,
    guard: true,
    path: "/verify-kyc",
    layout: LoginLayout,
    component: lazy(() => import("src/views/pages/KYC/index")),
  },
  {
    exact: true,
    guard: true,
    path: "/forget-otp",
    layout: LoginLayout,
    component: lazy(() => import("src/views/auth/Forgetotp")),
  },
  {
    exact: true,
    path: "/term-conditions",
    layout: LoginLayout,
    component: lazy(() => import("src/views/pages/TermsAndCondition")),
  },
  {
    // exact: true,
    path: "/faq-section",
    guard: true,
    layout: LoginLayout,
    component: lazy(() => import("src/views/pages/StaticPage/FAQ")),
  },
  {
    exact: true,
    path: "/policy-privacy",
    layout: LoginLayout,
    component: lazy(() => import("src/views/pages/PrivacyPolicy")),
  },
  {
    exact: true,
    path: "/check-email",
    component: lazy(() => import("src/views/auth/signup/CheckEmail")),
  },

  {
    exact: true,
    guard: true,
    path: "/wallet-history",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/WalletHistory/Wallethistory")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/feed",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/CIF/ComingSoon")),
  },
  {
    exact: true,
    guard: true,
    path: "/tadak",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Tadak/Tadak")),
  },

  {
    exact: true,
    guard: true,
    path: "/online-friends",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/Subscriber/AllFriendsOnline")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/invoice",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/TaralGold/Invoice")),
  },
  {
    exact: true,
    guard: true,
    path: "/edit-page",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Business/EditBusinessPage")),
  },

  {
    exact: true,
    path: "/home",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard")),
  },

  {
    exact: true,
    path: "/trending-hash",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/Trending/Trendinghash")
    ),
  },

  {
    exact: true,
    path: "/about",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/About/Index")),
  },
  {
    exact: true,
    path: "/view-othersprofile",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/OtherProfile")),
  },
  {
    exact: true,
    path: "/story-list",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/storylist/StoryList")),
  },
  {
    exact: true,
    path: "/subscriber-list",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/StaticPage/SubscriberList")),
  },

  {
    exact: true,
    path: "/trending-list",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/StaticPage/TrendingList")),
  },

  {
    exact: true,
    guard: true,
    path: "/transaction-history",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/StaticPage/TransactionHistory")
    ),
  },
  {
    exact: true,
    path: "/events",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Event")),
  },

  {
    exact: true,
    path: "/event-detail",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Event/EventDetails")),
  },
  {
    exact: true,
    path: "/event-list",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Event/EventList")),
  },

  {
    exact: true,
    path: "/add-event",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Event/AddEvent")),
  },

  {
    exact: true,
    guard: true,
    wallltGuard: true,
    path: "/taral-setting",
    layout: TaralSettingsLayout,
    component: lazy(() => import("src/views/pages/TaralSetting/Settingdash")),
  },
  {
    exact: true,
    guard: true,
    wallltGuard: true,
    path: "/help-support",
    layout: TaralSettingsLayout,
    component: lazy(() => import("src/views/pages/TaralSetting/Helpsetting")),
  },
  {
    exact: true,
    path: "/my-holding",
    guard: true,
    wallltGuard: true,
    layout: TaralSettingsLayout,
    component: lazy(() => import("src/views/pages/TaralFund/MyHolding")),
  },
  {
    exact: true,
    path: "/manage-asstes",
    guard: true,
    wallltGuard: true,
    layout: TaralSettingsLayout,
    component: lazy(() =>
      import("src/views/pages/TaralFund/ManageFund/ListAssets")
    ),
  },
  {
    exact: true,
    guard: true,
    wallltGuard: true,
    path: "/send-fund",
    layout: TaralSettingsLayout,
    component: lazy(() => import("src/views/pages/TaralFund/Sendfund")),
  },
  {
    exact: true,
    guard: true,
    wallltGuard: true,
    path: "/support-chat",
    layout: TaralSettingsLayout,
    component: lazy(() => import("src/views/pages/Support/SupportChat")),
  },
  {
    exact: true,
    guard: true,
    wallltGuard: true,
    path: "/receive-fund",
    layout: TaralSettingsLayout,
    component: lazy(() => import("src/views/pages/TaralFund/ReciveFund")),
  },
  {
    exact: true,
    guard: true,
    path: "/taral-pin",
    wallltGuard: true,
    layout: TaralSettingsLayout,
    component: lazy(() => import("src/views/pages/TaralSetting/EnterPin")),
  },
  {
    exact: true,
    guard: true,
    wallltGuard: true,
    path: "/verify-Withdraw",
    layout: TaralSettingsLayout,
    component: lazy(() => import("src/component/VerifyWithdrawOTP")),
  },

  {
    exact: true,
    guard: true,
    wallltGuard: true,
    path: "/change-pin",
    layout: TaralSettingsLayout,
    component: lazy(() => import("src/views/pages/Wallet/LoginPin")),
  },
  {
    exact: true,
    guard: true,
    wallltGuard: true,
    path: "/confirm-change",
    layout: TaralSettingsLayout,
    component: lazy(() => import("src/views/pages/Wallet/ConfirmPin")),
  },
  {
    exact: true,
    guard: true,
    wallltGuard: true,
    path: "/transaction-pin",
    layout: TaralSettingsLayout,
    component: lazy(() =>
      import("src/views/pages/TaralSetting/TransactionPin")
    ),
  },
  {
    exact: true,
    guard: true,
    wallltGuard: true,
    path: "/taral-forgot",
    layout: TaralSettingsLayout,
    component: lazy(() => import("src/views/pages/TaralSetting/ForgotPin")),
  },
  {
    exact: true,
    guard: true,
    wallltGuard: true,
    path: "/taral-gold",
    layout: TaralSettingsLayout,
    component: lazy(() => import("src/views/pages/TaralGold/TaralGold")),
  },
  {
    exact: true,
    guard: true,
    wallltGuard: true,
    path: "/fiat-wallet",
    layout: TaralSettingsLayout,
    component: lazy(() => import("src/views/pages/FiatWallet/FiatWallet")),
  },

  {
    exact: true,
    guard: true,
    wallltGuard: true,
    path: "/fiat-details",
    layout: TaralSettingsLayout,
    component: lazy(() => import("src/views/pages/FiatWallet/FiatDetails")),
  },
  {
    exact: true,
    guard: true,
    wallltGuard: true,
    path: "/fiat-adddetails",
    layout: TaralSettingsLayout,
    component: lazy(() => import("src/views/pages/FiatWallet/FiatAddDetails")),
  },
  {
    exact: true,
    guard: true,
    wallltGuard: true,
    path: "/forgot-pin",
    layout: TaralSettingsLayout,
    component: lazy(() => import("src/views/pages/Wallet/ForgotPin")),
  },
  {
    exact: true,
    guard: true,
    wallltGuard: true,
    path: "/wallet-home",
    layout: TaralSettingsLayout,
    component: lazy(() => import("src/views/pages/Wallet/WalletHome")),
  },
  {
    exact: true,
    guard: true,
    wallltGuard: true,
    path: "/statement",
    layout: TaralSettingsLayout,
    component: lazy(() => import("src/component/Statement")),
  },
  {
    exact: true,
    guard: true,
    wallltGuard: true,
    path: "/market",
    layout: TaralSettingsLayout,
    component: lazy(() => import("src/views/pages/OrderBook/PairList")),
  },
  {
    exact: true,
    guard: true,
    wallltGuard: true,
    path: "/insta-buy-sell",
    layout: TaralSettingsLayout,
    component: lazy(() => import("src/views/pages/Wallet/WalletTabs")),
  },
  {
    exact: true,
    guard: true,
    path: "/enter-pin",
    layout: TaralSettingsLayout,
    component: lazy(() => import("src/views/pages/Wallet/EnterPin")),
  },
  {
    exact: true,
    guard: true,
    wallltGuard: true,
    path: "/otp-verification",
    layout: TaralSettingsLayout,
    component: lazy(() => import("src/views/pages/Wallet/OtpVerification")),
  },
  {
    exact: true,
    guard: true,
    path: "/cif",
    wallltGuard: true,

    layout: TaralSettingsLayout,
    component: lazy(() => import("src/views/pages/CIF/ComingSoon")),
  },
  {
    exact: true,
    guard: true,
    wallltGuard: true,
    path: "/taral-dashboard",
    layout: TaralSettingsLayout,
    component: lazy(() => import("src/views/pages/TaralGold/TaralDashboard")),
  },
  {
    exact: true,
    guard: true,
    wallltGuard: true,
    path: "/crypto-history",
    layout: TaralSettingsLayout,
    component: lazy(() => import("src/views/pages/HistroyCrypto/Index")),
  },
  {
    exact: true,
    guard: true,
    wallltGuard: true,
    path: "/tarality-invoice",
    layout: TaralSettingsLayout,
    component: lazy(() => import("src/views/pages/TaralGold/TaralityInvoice")),
  },
  {
    exact: true,
    path: "/subscribe-plan",
    guard: true,
    wallltGuard: true,
    layout: TaralSettingsLayout,
    component: lazy(() => import("src/views/pages/TaralGold/SubscribePlan")),
  },
  {
    exact: true,
    path: "/upcoming-emi",
    guard: true,
    wallltGuard: true,
    layout: TaralSettingsLayout,
    component: lazy(() => import("src/views/pages/TaralGold/UpcomingEmi")),
  },
  {
    exact: true,
    path: "/active-plan",
    guard: true,
    wallltGuard: true,
    layout: TaralSettingsLayout,
    component: lazy(() => import("src/views/pages/TaralGold/ActivePlan")),
  },
  {
    exact: true,
    path: "/payment-selection",
    guard: true,
    wallltGuard: true,
    layout: TaralSettingsLayout,
    component: lazy(() => import("src/views/pages/TaralGold/PaymentSelection")),
  },
  {
    exact: true,
    path: "/payment-status",
    guard: true,
    wallltGuard: true,
    layout: TaralSettingsLayout,
    component: lazy(() => import("src/views/pages/TaralGold/PaymentStatus")),
  },
  {
    exact: true,
    path: "/payment-confirmation",
    guard: true,
    wallltGuard: true,
    layout: TaralSettingsLayout,
    component: lazy(() =>
      import("src/views/pages/TaralGold/PaymentConfirmation")
    ),
  },
  {
    exact: true,
    path: "/payment-confirmation-pay",
    guard: true,
    wallltGuard: true,
    layout: TaralSettingsLayout,
    component: lazy(() =>
      import("src/views/pages/TaralGold/PaymentConfirmationPaynow")
    ),
  },
  {
    exact: true,
    guard: true,

    path: "/create-pin",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Wallet/LoginPin")),
  },
  {
    exact: true,
    guard: true,
    path: "/new-pin",
    layout: HomeLayout,
    component: lazy(() => import("src/component/ForgetPin")),
  },

  {
    exact: true,
    guard: true,
    path: "/confirm-pin",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Wallet/ConfirmPin")),
  },

  {
    exact: true,
    guard: true,
    path: "/my-activities",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Settings/MyActivities")),
  },

  {
    exact: true,
    guard: true,
    path: "/own-profile",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Ownprofile")),
  },
  {
    exact: true,
    guard: true,
    path: "/profile-setting",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Ownprofile/ProfileSetting")),
  },
  {
    exact: true,
    guard: true,
    path: "/own-page-home",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/PageSection/Homesectionme")),
  },
  {
    exact: true,
    guard: true,
    path: "/invitation",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Invitation/Invitation")),
  },
  {
    exact: true,
    guard: true,
    path: "/invite",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Invitation/Invitepage")),
  },

  {
    exact: true,
    guard: true,
    path: "/point-earning",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Earning/PointEarn")),
  },
  {
    exact: true,
    guard: true,
    path: "/referral-earning-tree",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Earning/ReferralEarnTree")),
  },
  {
    exact: true,
    guard: true,
    path: "/earning",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Earning/Index")),
  },
  {
    exact: true,
    guard: true,
    path: "/collected-reward-details",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Earning/RewardDetails")),
  },
  {
    exact: true,
    guard: true,
    path: "/refer-and-earn",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Earning/ReferCode")),
  },
  {
    exact: true,
    guard: true,
    path: "/daily-reward-details",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Earning/DailyRewardDetails")),
  },
  {
    exact: true,
    guard: true,
    path: "/airdrop-reward",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Earning/AirDropRewards")),
  },
  {
    exact: true,
    guard: true,
    path: "/intrest-reward",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Earning/IntrestRewards")),
  },
  {
    exact: true,
    guard: true,
    path: "/myrewards-details",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Earning/MyRewardsDetail")),
  },
  {
    exact: true,
    guard: true,
    path: "/history",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/History/Index")),
  },

  {
    exact: true,
    guard: true,
    path: "/referral",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Refer&Earn/Referral")),
  },

  {
    exact: true,
    guard: true,
    path: "/my-reward",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Refer&Earn/Myreward")),
  },

  {
    exact: true,
    guard: true,
    path: "/suggestion-list",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Followers/FollowersPage")),
  },
  {
    exact: true,
    guard: true,
    path: "/followers",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Followers/FollowersPage")),
  },
  {
    exact: true,
    guard: true,
    path: "/top-users",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/TopUsers/TopUsersPage")),
  },

  {
    exact: true,
    guard: true,
    path: "/following",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Following/FollowingPage")),
  },
  {
    exact: true,
    guard: true,
    path: "/blocked-accounts",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Settings/BlockedAccounts")),
  },

  {
    exact: true,
    guard: true,
    path: "/createpage",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/CreatePageSection")),
  },
  {
    exact: true,
    guard: true,
    path: "/change-password",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Settings/ChangePassword")),
  },
  {
    exact: true,
    guard: true,
    path: "/delete-account",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Settings/DeleteAccount")),
  },
  {
    exact: true,
    guard: true,
    path: "/verify-myprofile",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Settings/VerifyMyProfile")),
  },
  {
    exact: true,
    path: "/comment",
    guard: true,
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Dashboard/Comment")),
  },

  {
    exact: true,
    path: "/crypto-login",
    guard: true,
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/TaralSetting/EnterPin")),
  },

  {
    exact: true,
    path: "/trade-exchange",
    guard: true,
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/TradeExchange")),
  },
  {
    exact: true,
    path: "/chart",
    component: lazy(() => import("src/component/Chart")),
  },
  {
    exact: true,
    path: "/post-detail",
    guard: true,
    layout: HomeLayout,
    component: lazy(() => import("src/component/PostDetails")),
  },
  {
    exact: true,
    path: "/short-detail",
    guard: true,
    layout: HomeLayout,
    component: lazy(() => import("src/component/ShortDetail")),
  },
  {
    exact: true,
    path: "/edit-profile",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Ownprofile/EditProfile")),
  },
  {
    exact: true,
    path: "/refer-&-earn",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Refer&Earn/Refer&earn")),
  },

  {
    exact: true,
    path: "/edit-business-page",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/PageSection/EditCreatepage")),
  },

  {
    exact: true,
    path: "/search",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Search/SearchSection")),
  },

  {
    exact: true,
    guard: true,
    path: "/chat-history",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/StaticPage/ChatList")),
  },

  {
    exact: true,
    path: "/notification-list",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Notification/index")),
  },
  {
    exact: true,
    path: "/notification-lists",
    guard: true,
    layout: TaralSettingsLayout,
    component: lazy(() => import("src/views/pages/Notification/index")),
  },

  {
    exact: true,
    path: "/about-us",
    // guard: true,
    layout: WebLayout,
    component: lazy(() => import("src/views/pages/AboutUs")),
  },
  {
    exact: true,
    path: "/contact-us",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/ContactUs")),
  },
  {
    exact: true,
    guard: true,
    path: "/ico",
    layout: TaralSettingsLayout,
    component: lazy(() => import("src/views/pages/Ico/Ico")),
  },
  {
    exact: true,
    path: "/terms-conditions",
    layout: WebLayout,
    component: lazy(() => import("src/component/TermsAndCondition")),
  },

  {
    exact: true,
    path: "/privacy-policy",
    layout: WebLayout,
    component: lazy(() => import("src/views/pages/PrivacyPolicy")),
  },
  {
    exact: true,
    guard: true,
    path: "/business",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Business/Business")),
  },
  {
    exact: true,
    guard: true,
    path: "/boost-now",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/BoostPage/FollowerPage")),
  },
  {
    exact: true,
    guard: true,
    path: "/Choose-yourplane",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/BoostPage/ChooseYourplane")),
  },
  {
    exact: true,
    guard: true,
    path: "/payment-confirm",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/PaymentConfirm")),
  },
  {
    exact: true,
    guard: true,
    path: "/apply-as-influencer",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Influencer")),
  },
  {
    exact: true,
    guard: true,
    path: "/influencer-list",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Influencer/InfluencerList")),
  },
  {
    exact: true,
    guard: true,
    path: "/my-invitations",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Influencer/MyInvitationList")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/completed-influencer-list",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Influencer/CompletedInfluencerList")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/influencer-pending",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Influencer/ApprovalPending")),
  },
  {
    exact: true,
    guard: true,
    path: "/influencer-rejected",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Influencer/ApprovalRejected")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/influencer-payment",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Influencer/Payment")),
  },
  {
    exact: true,
    guard: true,
    path: "/influencer-paymentConfirmation",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Influencer/PaymentConfirmation")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/influencer-details",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Influencer/InfluencerDetails")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/being-influencer-request",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Influencer/RequestForBeingInfluencer")
    ),
  },

  {
    exact: true,
    guard: true,
    path: "/business-detail",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Business/BusinessDetail")),
  },

  {
    exact: true,
    guard: true,
    path: "/campaign",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Campaign/Campaign")),
  },
  {
    exact: true,
    guard: true,
    path: "/createCampaign",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Campaign/CreateCampaign")),
  },
  {
    exact: true,
    guard: true,
    path: "/payment",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Campaign/Payment")),
  },
  {
    exact: true,
    guard: true,
    path: "/Campaign-paymentconfirmation",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Campaign/PaymentConfirmation")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/payment-successfully",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Campaign/PaymentSuceesfully")
    ),
  },
  {
    exact: true,
    path: "/404",
    component: lazy(() => import("src/views/errors/NotFound")),
  },
  {
    exact: true,
    guard: true,
    path: "/boost-payment",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/BoostPage/BoostPayment")),
  },
  {
    exact: true,
    path: "/stake",
    guard: true,
    wallltGuard: true,
    layout: TaralSettingsLayout,
    component: lazy(() => import("src/views/pages/Stake/Stake")),
  },
  {
    component: () => <Redirect to="/404" />,
  },
];
