import React, { useState, useContext } from "react";
import {
  makeStyles,
  Dialog,
  DialogContent,
  IconButton,
  Typography,
  Box,
  Button,
  Grid,
  Hidden,
  Divider,
  Avatar,
  TextField,
  FormControl,
  MenuItem,
  Select,
  InputAdornment,
  FormHelperText,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import { IMAGE_PATH } from "src/constants";
import { IoClose } from "react-icons/io5";
import InputLabel from "@material-ui/core/InputLabel";
import AddIcon from "@material-ui/icons/Add";
import { Delete } from "@material-ui/icons";
import { dataPostHandler } from "src/Apiconfigs/service";
import SelectTimeCommon from "./SelectTimeCommon";
import { SocialContext } from "src/context/SocialAuth";
import ButtonCircularProgress from "../ButtonCircularProgress";
import { toast } from "react-hot-toast";
const useStyles = makeStyles((theme) => ({
  connectModalBox: {},
  poolDialogBox: {
    padding: "20px",
    border: "1px solid rgba(255, 255, 255, 0.1)",
    "& .MuiOutlinedInput-root": {
      position: "relative",
      border: "1px solid rgba(255, 255, 255, 0.1)",
      borderRadius: "0px",
      backgroundColor: "#171717 !important",
      marginRight: "0px",
    },
    "& .MuiOutlinedInput-input": {
      fontSize: "14px",
      background: "#171717",
    },
    "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
      transform: "translate(14px, -6px) scale(0.75)",
      top: "11px",
      marginBottom: "10px",
      color: "gray",
      fontSize: "15px",
    },
    "&. .MuiSelect-selectMenu": {
      background: "#171717",
    },
  },
  cancelBtn: {
    position: "absolute",
    top: 0,
    right: 0,
    "& svg": {
      fontWeight: "700",
    },
  },
  usericon: {
    display: "flex",
    alignItems: "center",
    margin: "15px 0px",
    "& h4": {
      marginLeft: "20px",
    },
  },
}));

function CreatePoolModal({ setFeedModal, feedmodal }) {
  const social = useContext(SocialContext);
  const classes = useStyles();
  const [question, setQuestion] = useState("");
  const [week, setWeek] = useState({
    week1: "",
    week2: "",
    day1: "day1",
    day2: "",
  });

  const [type, setType] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [isValid, setIsValid] = useState([
    {
      state: "",
    },
  ]);
  const [poolData, setPoolData] = useState([
    {
      option: "",
      error: false,
    },
    {
      option: "",
      error: false,
    },
  ]);

  const addFormFields = () => {
    setPoolData([...poolData, { option: "" }]);
  };
  const removeFormFields = (i) => {
    let newFormValues = [...poolData];
    newFormValues.splice(i, 1);
    setPoolData(newFormValues);
  };
  const handleChange = async (i, e) => {
    let newFormValues = [...poolData];

    newFormValues[i][e.target.name] = e.target.value;
    setPoolData(newFormValues);
    if (e?.target?.name === "option") {
      let newValidationValues = [...isValid];
    }
  };

  const showError = (value, error) => value.trim().length === 0 && error;
  const addPoolHandler = async () => {
    const emptyOptions = poolData.every((obj) => {
      return obj?.option?.trim()?.length > 0;
    });
    const dataSend = {
      poll_topic: question,
      poll_title_1: poolData[0]?.option ? poolData[0]?.option : undefined,
      poll_title_2: poolData[1]?.option ? poolData[1]?.option : undefined,
      poll_title_3: poolData[2]?.option ? poolData[2]?.option : undefined,
      poll_title_4: poolData[3]?.option ? poolData[3]?.option : undefined,
      is_poll: "yes",
      poll_time: week?.week1
        ? 1
        : week?.week2
        ? 2
        : week.day1
        ? 1
        : week.day2
        ? 2
        : "",
      poll_time_type:
        type === "week1" ? "week" : type === "week2" ? "week" : "day",
      post_type: "other",
      type: "public",
    };

    if (!emptyOptions) {
      setPoolData(
        [...poolData].map((object) => {
          if (object.option?.trim() === "") {
            return {
              ...object,
              error: true,
            };
          } else return object;
        })
      );
    } else {
      if (poolData) {
        if (question.trim() !== "") {
          try {
            setIsLoading(true);
            const res = await dataPostHandler("createPost", dataSend);
            if (res) {
              toast.success(res.message);
              setIsLoading(false);
              setFeedModal(false);
              setPoolData([
                {
                  option: "",
                  error: false,
                },
                {
                  option: "",
                  error: false,
                },
              ]);
              setQuestion("");
              setWeek("");
              social.getHomePagePostList(1, social?.postListData?.length, []);
            }
          } catch (error) {
            setIsLoading(false);
          }
        } else {
          setIsSubmit(true);
        }
      }
    }
  };

  const _onInputChange = (e, b) => {
    setType(b);
    const name = e.target.name;
    const value = e.target.value;
    const temp = { [name]: value };

    setWeek(temp);
  };

  return (
    <>
      <Dialog
        open={feedmodal}
        onClose={() => {
          setFeedModal(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="sm"
        fullWidth
      >
        <IconButton
          className={classes.cancelBtn}
          onClick={() => setFeedModal(false)}
        >
          <IoClose />
        </IconButton>
        <Box align="center">
          <Box mt={1}>
            <Typography variant="h6">Create Poll</Typography>
          </Box>
        </Box>
        <DialogContent className="borderShadowBox">
          <Divider style={{ backgroundColor: "rgba(255, 255, 255, 0.1)" }} />
          <Box className={classes.usericon}>
            <Avatar src={`${IMAGE_PATH}` + social?.userData?.profile_url} />
            <Typography variant="h4">
              {" "}
              {social?.userData?.name ? social?.userData?.name : "..."}
            </Typography>
          </Box>
          <TextField
            id="standard-basic"
            placeholder="Ask a Question..."
            fullWidth
            value={question}
            disabled={isLoading}
            onChange={(e) => setQuestion(e.target.value)}
          />
          {isSubmit && question === "" && (
            <FormHelperText error>Please enter your question</FormHelperText>
          )}
          <Box className={classes.poolDialogBox} mt={2}>
            <Box mt={2} className="displaySpacebetween">
              <Box width="100%">
                {poolData &&
                  poolData.map((item, i) => {
                    return (
                      <Box width="100%" mt={1} key={`choice${i}`}>
                        <TextField
                          id="outlined-basic"
                          placeholder={`Choice ${i + 1}`}
                          variant="outlined"
                          name="option"
                          fullWidth
                          {...(showError(item?.option, item?.error) && {
                            ...{
                              error: item?.error,
                              helperText: "Please enter the value",
                            },
                          })}
                          value={item?.option}
                          onChange={(e) => {
                            handleChange(i, e);
                          }}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                {poolData.length === 2 ? null : (
                                  <IconButton
                                    onClick={() => removeFormFields(i)}
                                    className="delete"
                                  >
                                    <Delete style={{ color: "red" }} />
                                  </IconButton>
                                )}
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Box>
                    );
                  })}
                {poolData.length < 4 && (
                  <IconButton>
                    <AddIcon
                      onClick={() => {
                        if (poolData.length - 1) {
                          if (poolData.length < 4) {
                            addFormFields();
                          }
                        } else {
                          removeFormFields();
                        }
                      }}
                      style={{ color: "#C7762B", fontSize: "30px" }}
                    />
                  </IconButton>
                )}
              </Box>
            </Box>
            <Box
              mt={2}
              className="displaySpacebetween"
              style={{ flexWrap: "wrap" }}
            >
              <SelectTimeCommon
                week={week.day1}
                handleChange={(e, a) => _onInputChange(e, a)}
                title="1 Day"
                name="day1"
              />
              <SelectTimeCommon
                week={week.day2}
                handleChange={(e, a) => _onInputChange(e, a)}
                title="2 Days"
                name="day2"
              />
              <SelectTimeCommon
                week={week.week1}
                handleChange={(e, a) => _onInputChange(e, a)}
                title="1 Week"
                name="week1"
              />
              <SelectTimeCommon
                week={week.week2}
                handleChange={(e, a) => _onInputChange(e, a)}
                title="2 Weeks"
                name="week2"
              />
            </Box>
          </Box>
          <Box mt={3} align="center">
            <Button
              variant="contained"
              onClick={addPoolHandler}
              color="secondary"
              type="submit"
              disabled={isLoading}
              style={{ padding: "6px 60px", borderRadius: "50px" }}
            >
              Create Poll {isLoading && <ButtonCircularProgress />}
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default CreatePoolModal;
