import {
  Box,
  DialogContent,
  Typography,
  Button,
  IconButton,
  makeStyles,
} from "@material-ui/core";
import React from "react";
import { Dialog } from "@material-ui/core";
import { IoCloseOutline } from "react-icons/io5";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  mainbox: {
    position: "absolute",
    top: "5px ",
    right: "5px",
  },
  btn: {
    borderRadius: "30px",
    marginTop: "20px",
  },
}));
export default function SuccessFullModal({
  successfulOpen,
  setSuccessfulOpen,
  key,
  type,
}) {
  const classes = useStyles();
  const history = useHistory();
  return (
    <Dialog
      open={successfulOpen}
      onClose={setSuccessfulOpen}
      fullWidth
      maxWidth="xs"
    >
      <DialogContent>
        <Box className={classes.mainbox}>
          {key !== "bankacc" && (
            <IconButton onClick={setSuccessfulOpen}>
              <IoCloseOutline />
            </IconButton>
          )}
        </Box>

        <Box align="center" style={{ padding: "25px 0px 0px" }}>
          <Box>
            <img src="images/status.gif" alt="" />
          </Box>
          <Box mb={3} mt={2}>
            <Typography
              style={{ color: "#49B585", fontWeight: "600" }}
              variant="h5"
            >
              {type}
            </Typography>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
