import React from "react";

import { Box, Paper, makeStyles } from "@material-ui/core";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import Skeleton from "@material-ui/lab/Skeleton";
const useStyles = makeStyles((theme) => ({
  PostBox: {
    backgroundColor: "theme",
    position: "relative",
    padding: "12px",
    "& .MuiCardHeader-root": {
      padding: "0 0 16px 0",
    },
    "& .MuiCardContent-root": {
      padding: "16px 16px 16px 0",
    },
  },
  postImg: {
    height: 300,
  },
}));
export default function MightSkeleton({ type }) {
  const classes = useStyles();

  return (
    <Box className={classes.PostBox}>
      {type !== "history" && (
        <CardHeader
          avatar={
            <Skeleton animation="wave" variant="circle" width={40} height={40} />
          }
          title={
            <Skeleton
              animation="wave"
              height={10}
              width="80%"
              style={{ marginBottom: 6 }}
            />
          }
          subheader={<Skeleton animation="wave" height={10} width="40%" />}
        />
      )}

      {type === "reportList" ? (
        ""
      ) : (
        <Box>
          <Skeleton animation="wave" height={40} variant="rect" />
        </Box>
      )}
    </Box>
  );
}
