import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Paper } from "@material-ui/core";
import CardHeader from "@material-ui/core/CardHeader";
import Skeleton from "@material-ui/lab/Skeleton";
const useStyles = makeStyles((theme) => ({
  PostBox: {
    marginTop: "15px",
    "& .MuiCardHeader-root": {
      padding: "0 0 16px 0",
    },
    "& .MuiCardContent-root": {
      padding: "16px 16px 16px 0",
    },
  },
}));

export default function UserLoader({ type }) {
  const classes = useStyles();
  return (
    <div>
      <Box className={classes.PostBox}>
        {type === "story" && (
          <CardHeader
            avatar={
              <Skeleton
                animation="wave"
                variant="circle"
                width={150}
                height={150}
              />
            }

          />
        )}
        <CardHeader
          avatar={
            <Skeleton
              animation="wave"
              variant="circle"
              width={40}
              height={40}
            />
          }
          title={
            <Skeleton
              animation="wave"
              height={10}
              width="80%"
              style={{ marginBottom: 6 }}
            />
          }
          subheader={<Skeleton animation="wave" height={10} width="40%" />}
        />
      </Box>
    </div>
  );
}
