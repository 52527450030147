import React, { useContext, useState } from "react";
import { IMAGE_PATH } from "src/constants";
import {
  FormControl,
  Box,
  TextField,
  Avatar,
  Button,
  withStyles,
  InputAdornment,
  IconButton,
  makeStyles,
  Dialog,
  DialogContent,
} from "@material-ui/core";
import ButtonCircularProgress from "src/component/ButtonCircularProgress";
import SendIcon from "@material-ui/icons/Send";
import Gifphy from "src/Gif/Gifphy";
import Menu from "@material-ui/core/Menu";
import { AiOutlineGif } from "react-icons/ai";
import { SocialContext } from "src/context/SocialAuth";
const useStyles = makeStyles((theme) => ({
  gifDisplay: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: "10px",

    "& img": {
      borderRadius: "5px",
    },
  },
  gifModalParent: {
    position: "relative",
    "& button": {
      [theme.breakpoints.down("xs")]: {
        fontSize: "10px !important",
        height: "34px !important",
      },
    },
  },
  modalGif: {
    position: "absolute",
    bottom: "50px",
    right: "0px",
  },
  mainDig: {
    "& .MuiDialog-paperScrollPaper": {
      height: "500px",
    },
    "& .MuiDialogContent-root:first-child": {
      height: "500px",
    },
  },
  "& .newsaearchModal": {
    "& button": {
      border: "none",
      background: "none",
    },
  },
}));
const StyledMenu = withStyles((theme) => ({
  paper: {
    color: "#8B8B8B",
    border: "1px solid rgb(255 254 254 / 13%)",
    minWidth: "127px !important",
    boxShadow: "0px 0px 11px rgb(0 0 0 / 16%)",
    borderRadius: "10px",
    marginLeft: "-60px",
    padding: "10px",
    backgroundColor: "#2d2d2deb !important",
    [theme.breakpoints.down("sm")]: {
      marginLeft: "0px",
    },
  },
}))((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));
export default function CommentSection({
  setComment,
  commentHandler,
  comment,
  isLoading,
  data,
  setGif,
  gif,
  calssType,
}) {
  const social = useContext(SocialContext);
  const classes = useStyles();
  const [anchorElGif, setAnchorElGif] = useState(false);

  return (
    <Box
      className={calssType !== "modal" ? "" : "searchaddress newsaearchModal"}
    >
      <Box
        display="flex"
        alignItems="center"
        className={calssType !== "modal" ? "" : classes.gifModalParent}
      >
        <Box style={{ marginRight: "10px" }}>
          <Avatar src={`${IMAGE_PATH}` + social?.userData?.profile_url} />
        </Box>

        <FormControl fullWidth className="figure">
          <TextField
            id="outlined-basic"
            variant="outlined"
            name="Text Field"
            placeholder="Write a comment..."
            type="text"
            fullWidth
            autoComplete="off"
            value={comment ? comment : ""}
            onChange={(e) => {
              if (e.target.value.length <= 256) {
                setComment(e.target.value);
              }
            }}
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                commentHandler(data?.id, "text");
              }
            }}
            disabled={isLoading}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end" style={{ paddingRight: "13px" }}>
                  <IconButton
                    style={{
                      position: "absolute",
                      right: "0px",
                      color: "#fff",
                    }}
                    onClick={() => setAnchorElGif(true)}
                    aria-controls="gif-menu"
                    aria-haspopup="true"
                  >
                    <AiOutlineGif />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </FormControl>

        <Box style={{ padding: "0px" }}>
          <Button
            onClick={() => commentHandler(data?.id, "text")}
            disabled={isLoading}
          >
            {isLoading ? (
              <ButtonCircularProgress />
            ) : (
              <SendIcon style={{ color: "#FFFFFF" }} />
            )}
          </Button>
        </Box>
      </Box>
      {gif && (
        <Box
          className={
            calssType !== "modal" ? classes.gifDisplay : classes.modalGif
          }
        >
          {gif && <img src={gif} />}
        </Box>
      )}

      <Dialog
        className={classes.mainDig}
        open={anchorElGif}
        onClose={() => setAnchorElGif(false)}
      >
        <DialogContent>
          <Gifphy
            setComment={(item) => setComment(item)}
            selectGif={(item) => setGif(item)}
            closeAnchorElGif={() => setAnchorElGif(false)}
            anchorElGif={anchorElGif}
          />
        </DialogContent>
      </Dialog>
    </Box>
  );
}
