import React from "react";
import { useHistory } from "react-router-dom";
const Logo = (props) => {
  const history = useHistory();
  const { type } = props;
  return (
    <img
      className="Logoimg"
      onClick={() => history.push(type === "crypto" ? "/wallet-home" : "/home")}
      src="/images/logo.svg"
      alt="Logo"
      style={{ cursor: "pointer", paddingTop: "9px", width: "129px" }}
      {...props}
    />
  );
};

export default Logo;
