import React, { useEffect } from "react";
import PropTypes from "prop-types";
import {
  makeStyles,
  Grid,
  Box,
  Typography,
  Container,
  List,
  Link,
} from "@material-ui/core";
import { Redirect, useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { RiArrowLeftSLine } from "react-icons/ri";
import Footer from "./Footer";
const useStyles = makeStyles((theme) => ({
  left: {},
  wrapper: {
    minHeight: "100vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    overflow: "hidden",
    backgroundColor: "#090909",
    position: "relative",
    zIndex: "9",
    "& .shadeRight": {
      zIndex: "0",
      left: "auto",
      top: "-200px",
      right: "-200px",
      width: "550px",
      bottom: "auto",
      filter: "blur(100px)",
      height: "550px",
      opacity: "0.55",
      position: "absolute",
      transform: "rotate(45deg)",
      borderRadius: "1000px",
      backgroundImage:
        "radial-gradient(36.67% 9.68% at 67.26% 8.27%, rgb(255 202 100 / 35%) 0%, rgb(255 202 100 / 53%) 95.78%)",
    },
    "& .shadeLeft": {
      zIndex: "0",
      left: "-200px",
      top: "80%",
      right: "auto",
      width: "550px",
      bottom: "auto",
      filter: "blur(100px)",
      height: "550px",
      opacity: "0.55",
      position: "absolute",
      transform: "rotate(45deg)",
      borderRadius: "1000px",
      backgroundImage:
        "radial-gradient(36.67% 9.68% at 67.26% 8.27%, rgb(255 202 100 / 35%) 0%, rgb(255 202 100 / 53%) 95.78%)",
    },
  },
  mainbox: {
    maxWidth: "100%",
    width: "auto",
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    borderRadius: "15px",
  },
  root: {
    backgroundColor: "#0d0d0d",
    "& .MuiInput-underline::before": {
      left: "0",
      right: "0",
      bottom: "0",
      content: '"\\00a0"',
      position: "absolute",
      transition: "border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
      borderBottom: "1px solid rgb(255 255 255 / 29%)",
      pointerEvents: "none",
    },
  },
  mainbox1: {
    width: "100%",
    border: "1px solid rgba(255, 255, 255, 0.1)",
    background: "rgba(255, 255, 255, 0.05)",
    borderRadius: "10px",
    [theme.breakpoints.down("sm")]: {
      backdropFilter: "none",
    },
  },
  main: {
    padding: "20px 0px",
    position: "relative",
    zIndex: "1",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  mainClass: {
    textAlign: "center",
    "& h1": {
      color: "#000000",
      marginTop: "-27px",
    },
    "& h2": {
      fontWeight: "400",
      fontSize: "20px",
      lineHeight: "24px",
      letterSpacing: "0.005em",
      color: "#000000",
    },
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  backbtnLogin: {
    backgroundColor: "#5c5c5c",
    borderRadius: "100px",
    width: "35px",
    height: "35px",
    display: "flex",
    alignItems: "center",
    position: "relative",
    left: "35px",
    top: "15px",
    marginBottom: "-32px",
    justifyContent: "center",
    cursor: "pointer",
    "& svg": {
      color: "#fff",
      fontSize: "20px",
    },
  },
  imgBox: {
    background: "rgba(0, 0, 0, 0.25)",
    borderRadius: "10px",
    padding: "30px",
    margin: "70px 32px",
  },
  textHead: {
    position: "absolute",
    padding: "100px 0px",
    "& h3": {
      maxWidth: "240px",
      padding: "10px 0px",
      lineHeight: "30px",
    },
    "& p": {
      maxWidth: "300px",
    },
  },
  listpager: {
    padding: "8px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexWrap: "wrap",
    "& a": {
      fontSize: "13px",
      color: "rgba(255,255,255,0.57)",
      paddingRight: "9px",
    },
  },
}));

const LoginLayout = ({ children }) => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const data = window?.location?.pathname;

  return (
    <Box>
      <Box className={classes.root}>
        <div className={classes.wrapper}>
          <Container>
            <Box className={classes.main}>
              {data === "/verify-kyc" ? (
                <Box className={classes.content}>{children}</Box>
              ) : (
                <>
                  <Box className={classes.mainbox1}>
                    {window.location.pathname === "/term-conditions" ||
                    window.location.pathname === "/policy-privacy" ||
                    window.location.pathname === "/faq-section" ? (
                      <>
                        <Box className={classes.content}>{children}</Box>
                      </>
                    ) : (
                      <>
                        <Grid container>
                          <Grid
                            item
                            xs={12}
                            sm={12}
                            md={6}
                            lg={7}
                            className={classes.mainClass}
                          >
                            {data === "/forget-password" ? (
                              <>
                                <Box>
                                  <Box
                                    onClick={() => history.goBack()}
                                    className={classes.backbtnLogin}
                                  >
                                    <RiArrowLeftSLine />
                                  </Box>
                                </Box>
                              </>
                            ) : data === "/forget-otp" ? (
                              <>
                                <Box>
                                  <Box
                                    onClick={() => history.goBack()}
                                    className={classes.backbtnLogin}
                                  >
                                    <RiArrowLeftSLine />
                                  </Box>
                                </Box>
                              </>
                            ) : data === "/reset-otp" ? (
                              <>
                                <Box>
                                  <Box
                                    onClick={() => history.goBack()}
                                    className={classes.backbtnLogin}
                                  >
                                    <RiArrowLeftSLine />
                                  </Box>
                                </Box>
                              </>
                            ) : data === "/reset-password" ? (
                              <>
                                <Box>
                                  <Box
                                    onClick={() => history.goBack()}
                                    className={classes.backbtnLogin}
                                  >
                                    <RiArrowLeftSLine />
                                  </Box>
                                </Box>
                              </>
                            ) : (
                              <></>
                            )}

                            <Box className={classes.imgBox}>
                              {data === "/forget-password" ? (
                                <>
                                  <img
                                    src="images/forgetpass.png"
                                    alt="dummy"
                                    className={classes.mainbox}
                                  />
                                </>
                              ) : data === "/forget-otp" ? (
                                <>
                                  <img
                                    src="images/forgateotppage.png"
                                    alt="dummy"
                                    className={classes.mainbox}
                                  />
                                </>
                              ) : data === "/reset-otp" ? (
                                <>
                                  <img
                                    src="images/forgateotppage.png"
                                    alt="dummy"
                                    className={classes.mainbox}
                                  />
                                </>
                              ) : data === "/" ? (
                                <>
                                  <Box align="center">
                                    <Box className={classes.textHead}>
                                      <Typography variant="h3">
                                        Change The Quality Of Your Life
                                      </Typography>
                                      <Typography variant="body2">
                                        Lorem ipsum dolor sit amet consectetur.
                                        Pulvinar senectus sodales.
                                      </Typography>
                                    </Box>
                                    <img
                                      src="images/loginpage.png"
                                      alt="dummy"
                                      className={classes.mainbox}
                                    />
                                  </Box>
                                </>
                              ) : data === "/signup" ? (
                                <>
                                  <Box align="center">
                                    <Box className={classes.textHead}>
                                      <Typography variant="h3">
                                        Change The Quality Of Your Life
                                      </Typography>
                                      <Typography variant="body2">
                                        Lorem ipsum dolor sit amet consectetur.
                                        Pulvinar senectus sodales.
                                      </Typography>
                                    </Box>
                                    <img
                                      src="images/signuppage.png"
                                      alt="dummy"
                                      className={classes.mainbox}
                                    />
                                  </Box>
                                </>
                              ) : data === "/reset-password" ? (
                                <>
                                  <img
                                    src="images/resetotppass.png"
                                    alt="dummy"
                                    className={classes.mainbox}
                                  />
                                </>
                              ) : (
                                <></>
                              )}
                            </Box>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            sm={12}
                            md={6}
                            lg={5}
                            className={classes.left}
                          >
                            <Box className={classes.content}>{children}</Box>
                          </Grid>
                        </Grid>
                      </>
                    )}
                    <Box>
                      <List className={classes.listpager}>
                        <Link href="/term-conditions" target="_blank">
                          Terms & Conditions
                        </Link>
                        <Link href="/policy-privacy" target="_blank">
                          Privacy Policy
                        </Link>
                      </List>
                    </Box>
                  </Box>
                </>
              )}
            </Box>
            {window.location.pathname === "/term-conditions" ||
            window.location.pathname === "/policy-privacy" ||
            window.location.pathname === "/faq-section" ? (
              <>
                <Footer />
              </>
            ) : (
              ""
            )}
          </Container>
        </div>
      </Box>
    </Box>
  );
};

LoginLayout.propTypes = {
  children: PropTypes.node,
};

export default LoginLayout;
