import React, { createContext, useState, useEffect, useContext } from "react";
import axios from "axios";
import Apiconfigs from "src/Apiconfigs/config";
import { getDataHandlerAPI } from "src/Apiconfigs/service";
import { SocialContext } from "src/context/SocialAuth";

export const WalletContext = createContext();

export default function SocialAuth(props) {
  const [balanceINR, setBalance] = useState(0);
  const social = useContext(SocialContext);
  const { userDataWallet: socialUserDetails } = social;
  const [totalsBalances, setTotalsBalances] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [walletList, setWalletList] = useState([]);
  const [userData, setUserData] = useState({});
  const [coinList, setCoinList] = useState([]);
  const [taxesData, setTaxesData] = useState({});
  const [hide, setHide] = useState(false);
  const [customToastData, setCustomToastData] = useState({
    state: false,
    text: "",
    color: "",
  });
  const getUserProfileDatahandler = async (token) => {
    try {
      const response = await getDataHandlerAPI("get_credit_limit", token);
      const creditData = response.data.data;
      setUserData({ ...socialUserDetails, ...creditData });
    } catch (error) {
      console.log(error);
      if (error.response) {
        if (error.response.data.statusCode == "404") {
          window.localStorage.removeItem("token");
        }
      }
    }
  };

  const WalletCoinList = async () => {
    try {
      const res1 = await axios({
        method: "GET",
        url: Apiconfigs.getFiatBalanceByUserid,
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      if (res1.status == 200) {
        setBalance(res1.data.data.inr_value);
      }

      if (window.localStorage.getItem("checkWalletPIn") && socialUserDetails) {
        const res = await axios({
          method: "POST",
          url: Apiconfigs.walletCoinList,
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        });
        if (res.data.status) {
          setCoinList(res.data.data);
        }
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };
  const usericohistoryAPI = async () => {
    try {
      const res = await axios({
        method: "GET",
        url: Apiconfigs.managewallet,
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      if (res.data.status) {
        setWalletList(res.data.data);
        setIsLoading(false);
      }
      let price = res.data.data;
      let sumOfBalances = 0;
      if (price.length > 0) {
        price.forEach((element) => {
          sumOfBalances =
            sumOfBalances + element.coin_fiat_price[0].value * element.balance;
        });
      }
      setTotalsBalances(sumOfBalances);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };
  const getTaxesAPIHandler = async () => {
    try {
      const response = await getDataHandlerAPI(
        "get_settings",
        window.localStorage.getItem("token")
      );
      setTaxesData(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    if (!window.localStorage.getItem("type")) {
      WalletCoinList();
    }
    if (window.localStorage.getItem("checkWalletPIn") && socialUserDetails) {
      usericohistoryAPI();
      getTaxesAPIHandler();
      getUserProfileDatahandler(window.localStorage.getItem("token"));
    }
  }, [
    window.localStorage.getItem("checkWalletPIn"),
    socialUserDetails,
    window.localStorage.getItem("type"),
  ]);

  let data = {
    isLoading,
    walletList,
    totalsBalances,
    balanceINR,
    userData,
    coinList,
    taxesData,
    customToastData,
    hide,
    setCustomToastData: (data) => setCustomToastData(data),
    setHide: (data) => setHide(data),
    getUserProfileDatahandler: getUserProfileDatahandler,
    WalletCoinList: WalletCoinList,
    usericohistoryAPI: usericohistoryAPI,
  };

  return (
    <WalletContext.Provider value={data}>
      {props.children}
    </WalletContext.Provider>
  );
}
