import { Box, makeStyles } from "@material-ui/core";
import React from "react";
import CreateStory from "./CreateStory";
import ProfileActivity from "./ProfileActivity";
import Story from "./Story";
const styles = makeStyles((theme) => ({
  storyBody: {
    border: "1px solid rgba(255, 255, 255, 0.1)",
    padding: "15px",
  },
}));

export default function StorySideBar() {
  const classes = styles();
  return (
    <>
      <Box className={classes.storyBody}>
        <Story />
      </Box>

      <Box className={classes.storyBody} style={{ borderTop: "none" }}>
        <ProfileActivity />
      </Box>
      <Box className={classes.storyBody} style={{ borderTop: "none" }}>
        <CreateStory
          img="images/pool.png"
          title="Create Poll"
          desc="Click here to add Poll."
          type="poll"
        />
      </Box>
      <Box className={classes.storyBody} style={{ borderTop: "none" }}>
        <img
          src="https://alternative.me/crypto/fear-and-greed-index.png"
          alt="Bitcoin Fear &amp; Greed Index"
          style={{ maxWidth: "100%", filter: "invert(1)" }}
        />
      </Box>
    </>
  );
}
