import React from "react";
import { AnimatePresence } from "framer-motion";
import MotionDiv from "src/component/MotionDiv";

export default function CustomTaost({ text, color }) {
  return (
    <AnimatePresence>
      <MotionDiv>
        <div
          style={{
            height: "41px",
            background: color,
            color: "rgb(0, 0, 0)",
            borderRadius: "10px",
            display: "flex",
            alignItems: "center",
            marginBottom: "10px",
            padding: "0px 15px",
            zIndex: "99999",
            position: "fixed",
            bottom: 60,
            right: 27,
          }}
        >
          <h1 style={{ fontSize: "18px", fontWeight: "400" }}>{text}</h1>
        </div>
      </MotionDiv>
    </AnimatePresence>
  );
}
