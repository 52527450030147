import React from "react";
import { IMAGE_PATH } from "src/constants";
export default function VideoCard({
  setModalData,
  setOpenModal,
  typeData,
  data,
  videotype,
}) {
  return (
    <video
      id={videotype === "profile" ? "" : "myAudio"}
      autoplay={videotype === "profile" ? false : true}
      loop
      webkit-playsinline="true"
      playsinline="true"
      controls={true}
      muted={true}
      style={{ cursor: "pointer" }}
      onClick={() => {
        if (videotype === "profile") {
          if (typeData !== "modalcino") {
            setModalData(data?.id);
            setOpenModal(true);
          }
        }
      }}
    >
      <source
        src={
          data?.post_meta_data && `${IMAGE_PATH}${data?.post_meta_data[0]?.url}`
        }
        type="video/mp4"
      />
    </video>
  );
}
