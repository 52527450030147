
import React from "react";
import Player from "./Player";
import { Button } from "@material-ui/core";
import SongSkeletonLodaer from "./SongSkeletonLodaer";

const Index = ({
  selectMusic,
  setSelectedMusic,
  songs,
  setLoadSongs,
  laodSongs,
  setSearchMusic,
  isSongLodaing
}) => {
  return (
    <div className="App">
      {isSongLodaing ? [1, 2, 3].map((data) => {
        return (
          <SongSkeletonLodaer />
        )
      }) : (
        <>
          {songs?.length > 0 && (
            <>
              {songs &&
                songs.map((data, index) => {
                  return (
                    <div key={index}>
                      <Player
                        data={data}
                        selectMusic={selectMusic}
                        setSelectedMusic={(item) => setSelectedMusic(item)}

                      />
                    </div>
                  );
                })}
            </>
          )}
          {songs?.length === 0 && (
            <Button
              variant="contained"
              color="secondary"
              onClick={() => {
                setLoadSongs(!laodSongs);
                setSearchMusic("");
              }}
            >
              Load Songs
            </Button>
          )}
        </>
      )}

    </div>
  );
};

export default Index;
