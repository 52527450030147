import { Box, Typography } from "@material-ui/core";
import React from "react";

export default function NodatafoundImage({ dataFound, type }) {
  return (
    <Box align="center" mt={4} width="100%" pb={3}>
      {type !== "bostPlan" && (
        <img src="/images/NoDataFound.png" style={{ maxWidth: "176px" }} />
      )}

      <Box mt={3} align="center">
        <Typography
          variant="h6"
          style={{
            marginTop: "-15px",
            textAlign: "center",
          }}
        >
          {dataFound ? dataFound : "No data, please try again later"}
        </Typography>
      </Box>
    </Box>
  );
}
