import React from "react";
import {
  Button,
  Dialog,
  DialogContent,
  Typography,
  Box,
  IconButton,
  makeStyles,
  TextField,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { MdPayment } from "react-icons/md";
import { VscFolderActive } from "react-icons/vsc";
import ButtonCircularProgress from "./ButtonCircularProgress";
const useStyles = makeStyles((theme) => ({
  deactivatemadalbox: {
    "& h4": {
      paddingBottom: "20px",
      fontWeight: "600",
    },
    "& .btn1": {
      borderRadius: "10px",
      padding: "6px 25px",
    },
    "& .btn2": {
      padding: "6px 35px",
      marginLeft: "10px",
    },
    "& svg": {
      height: "50px",
      width: "50px",
      color: "#27ef27",
    },
  },
  cancelBtn: {
    position: "absolute",
    top: 0,
    right: 0,
    "& svg": {
      color: "#FDC763",
    },
  },
}));
export default function DeleteConfirmation({
  type,
  isLoading,
  deleteHandler,
  open,
  setOpen,
  postIdData,
}) {
  const classes = useStyles();
  return (
    <Dialog
      open={open}
      onClose={() => {
        if (!isLoading) {
          setOpen(false);
        }
      }}
      fullWidth
      maxWidth="xs"
    >
      <IconButton
        className={classes.cancelBtn}
        onClick={() => {
          if (!isLoading) {
            setOpen(false);
          }
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <Box align="center" className={classes.deactivatemadalbox}>
          <Box mt={2} mb={2}>
            {type === "payment" ? (
              <MdPayment />
            ) : (
              <img src="images/deactivate.png" />
            )}
          </Box>

          <Typography variant="h4">
            {type === "payment" && "Pay"}{" "}
            {type === "post"
              ? "Delete post"
              : type === "account"
              ? "Account"
              : "delete page"}
          </Typography>
          <Typography variant="body2">
            {type === "payment" ? (
              <>Are you sure you want to pay?.</>
            ) : (
              <>
                Are you sure you want to delete this{" "}
                {type === "post"
                  ? "post"
                  : type === "account"
                  ? "Account"
                  : "page"}
                .
              </>
            )}
          </Typography>

          <Box mt={3} mb={4}>
            <Button
              disabled={isLoading}
              variant="contained"
              color="primary"
              className="btn1"
              onClick={() => {
                if (!isLoading) {
                  setOpen(false);
                }
              }}
            >
              Cancel
            </Button>
            <Button
              disabled={isLoading}
              variant="contained"
              color="secondary"
              className="btn2"
              onClick={(id) => deleteHandler(postIdData?.id)}
            >
              Yes{" "}
              {isLoading && (
                <ButtonCircularProgress size="15px" classes="classes" />
              )}
            </Button>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
