import {
  makeStyles,
  Typography,
  Box,
  Dialog,
  Button,
  DialogContent,
  FormHelperText,
  IconButton,
} from "@material-ui/core";
import React, { useContext, useState, useEffect } from "react";
import { DropzoneArea } from "material-ui-dropzone";
import { motion, AnimatePresence } from "framer-motion";
import { ImAttachment } from "react-icons/im";
import { dataPostHandler } from "src/Apiconfigs/service";
import toast from "react-hot-toast";
import ButtonCircularProgress from "src/component/ButtonCircularProgress";
import { useHistory } from "react-router-dom";
import { SocialContext } from "src/context/SocialAuth";
import ApiConfig from "src/Apiconfigs/config";
import axios from "axios";
import { IoClose } from "react-icons/io5";

const useStyles = makeStyles((theme) => ({
  addphotos: {
    display: "flex",
    alignItems: "center",
    boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
    border: "1px solid rgba(255, 255, 255, 0.1)",
    cursor: "pointer",
    position: "relative",
    marginTop: "10px",
    textAlign: "center",
    borderRadius: "0px",
    "& .MuiDropzoneArea-icon": {
      color: "#404040",
      width: "35px",
      height: "35px",
    },
    "& input": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      opacity: 0,
      cursor: "pointer",
    },
    "& svg": {
      fontSize: "30px",
      cursor: "pointer",
    },

    "& .MuiDropzoneArea-root": {
      border: "none",
      backgroundColor: "rgb(23 23 23) !important",
    },
    "& svg": {
      cursor: "pointer",
      padding: "20px",
      background: "rgba(255, 255, 255, 0.05)",
      borderStyle: "dotted",
      borderRadius: "50%",
    },
    "& .MuiDropzonePreviewList-removeButton": {
      top: "7px",
      right: "43px",
      width: "30px",
      height: "30px",
    },
    "& .MuiFab-root": {
      minHeight: "30px",
    },
  },
  cancelBtn: {
    position: "absolute",
    top: 0,
    right: 0,
    "& svg": {
      color: "#D1913C",
      fontWeight: "700",
    },
  },
}));

export default function StoryPreview({
  setStoryModal,
  businessId,
  callBack,
  storyModal,
}) {
  const classes = useStyles();
  const social = useContext(SocialContext);
  const [filedata, setFile] = useState("");
  const [imgBlob1, setImgBlob1] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  const clearImage = () => {
    setImgBlob1("");
    setFile("");
  };

  const handleUploadFiles = async (filedata) => {
    var formdata = new FormData();
    formdata.append("image", filedata);

    try {
      const response = await axios({
        method: "POST",
        url: ApiConfig.uploadstory,
        headers: {
          authorization: window.localStorage.getItem("token"),
        },
        data: formdata,
      });
      if (response.status === 200) {
        const splitImg = response.data.data?.Key; //main network
        return splitImg;
      } else {
        toast.error(response.data.message);
      }
    } catch (err) {
      console.error(err.response);
    }
  };

  const createStoryAPIHandler = async () => {
    setErrorMsg("");
    if (filedata == "") {
      setErrorMsg("Please choose a file");
    } else {
      try {
        setIsLoading(true);
        const getMediaURL = await handleUploadFiles(filedata[0]);
        const response = await dataPostHandler("create", {
          url: getMediaURL,
          type: filedata[0]?.type.split("/")[0],
          business_page_id: businessId ? businessId : undefined,
        });
        if (response.message) {
          toast.success(response.message);
          setStoryModal(false);
          setIsLoading(false);
          setImgBlob1("");
          if (callBack) {
            callBack(businessId);
          }
          setStoryModal(false);
          social.getStoryDatahandler(localStorage.getItem("token"));
        } else {
          toast.error(response.data.message);
        }
        setIsLoading(false);
      } catch (error) {
        console.log(error);
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    setTimeout(() => setErrorMsg(""), 2000);
  }, [errorMsg]);

  return (
    <AnimatePresence>
      <Dialog
        open={storyModal}
        onClose={() => {
          if (!isLoading) {
            setStoryModal(false);
          }
        }}
        maxWidth="sm"
        fullWidth
        className={classes.root}
      >
        <motion.div>
          <Box align="center">
            <Box mt={1}>
              <Typography variant="h6">Create Story</Typography>
            </Box>
          </Box>

          <DialogContent>
            <IconButton
              className={classes.cancelBtn}
              onClick={() => {
                if (!isLoading) {
                  setStoryModal(false);
                }
              }}
            >
              <IoClose />
            </IconButton>
            <Box className={classes.addphotos}>
              {imgBlob1 == "" ? (
                <>
                  <DropzoneArea
                    Icon={ImAttachment}
                    onChange={(files) => {
                      if (files.length !== 0) {
                        setFile(files);

                        setImgBlob1(URL.createObjectURL(files[0]));
                      } else {
                        setFile([]);

                        setImgBlob1("");
                      }
                    }}
                    dropzoneText="Add photos/Video"
                    acceptedFiles={["image/*,.mp4"]}
                    filesLimit={10}
                    maxFileSize="100000000"
                    showAlerts={false}
                  />
                </>
              ) : (
                <>
                  {filedata[0]?.type.split("/")[0] === "image" ? (
                    <img
                      src={imgBlob1}
                      width="100%"
                      style={{ borderRadius: "5px" }}
                    />
                  ) : (
                    <video
                      controls="false"
                      autoPlay="true"
                      loop
                      muted
                      style={{
                        width: "100%",
                        height: "100%",
                        maxHeight: "300px",
                      }}
                      playsinline="true"
                      className="videoBox"
                    >
                      <source src={imgBlob1} type="video/mp4" />
                    </video>
                  )}
                </>
              )}
            </Box>
            <FormHelperText error>{errorMsg}</FormHelperText>
            <Box mt={3} align="center">
              {imgBlob1 !== "" && (
                <Button
                  variant="contained"
                  color="secondary"
                  disabled={isLoading}
                  style={{
                    borderRadius: "50px",
                    border: "1px solid #ffffff24",
                    background: "transparent",
                    minWidth: "126px",
                    marginRight: "10px",
                    color: "rgba(255, 255, 255, 0.6)",
                  }}
                  onClick={() => clearImage()}
                >
                  Remove
                </Button>
              )}
              &nbsp;
              <Button
                disabled={isLoading}
                variant="contained"
                color="secondary"
                style={{ borderRadius: "50px" }}
                onClick={() => createStoryAPIHandler()}
              >
                Share to story {isLoading && <ButtonCircularProgress />}
              </Button>
            </Box>
          </DialogContent>
        </motion.div>
      </Dialog>
    </AnimatePresence>
  );
}
