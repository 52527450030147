import React, { useState, useEffect } from "react";
import {
  Box,
  Dialog,
  DialogContent,
  IconButton,
  Button,
  makeStyles,
  Typography,
  Divider,
  Grid,
  FormControl,
  TextField,
  InputAdornment,
} from "@material-ui/core";
import { BsFacebook, BsFillChatDotsFill } from "react-icons/bs";
import { AiFillInstagram, AiFillTwitterCircle } from "react-icons/ai";
import { RiSendPlaneFill } from "react-icons/ri";
import CloseIcon from "@material-ui/icons/Close";
import {
  FacebookShareButton,
  TelegramShareButton,
  TwitterShareButton,
  InstapaperShareButton,
} from "react-share";
import { CiViewTimeline } from "react-icons/ci";
import CreateFeedModal from "src/component/CreateFeedModal";
import MightSkeleton from "src/component/MightSkeleton";
import OnlineUser from "src/views/pages/Dashboard/Subscriber/OnlineUser";
import { getParticularObjectDataHanndler } from "../Apiconfigs/service/index";
import { IoSearchOutline } from "react-icons/io5";
import useDebounce from "src/customHook/Debounce";

const useStyles = makeStyles((theme) => ({
  cancelBtn: {
    position: "absolute",
    top: 0,
    right: 0,
  },
  sharemodal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "20px 0",
    flexWrap: "wrap",
    "& button": {
      textAlign: "center",
      background: "rgba(255, 255, 255, 0.1)",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: "50px",
      height: "53px",
      borderRadius: "10px",
      margin: "7px",
      [theme.breakpoints.down("sm")]: {
        minWidth: "50px",
        margin: "3px",
      },
      "& svg": {
        fontSize: "25px",
        color: "#fff",
      },
    },
    "& .shareModalBoxback": {},
  },
  Users: {
    width: "100% !important",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    "& .UserBox": {
      display: "flex",
      alignItems: "center",
      cursor: "pointer",
      "& .figure": {
        margin: "0",
        marginRight: "15px",
        position: "relative",
        "& .profileimage": {
          height: "40px",
          width: "40px",
          borderRadius: "50%",
          overflow: "hidden",
          [theme.breakpoints.down("xs")]: {
            height: "40px",
            width: "40px",
          },
          "& img": {
            width: "auto",
            maxWidth: "100%",
            maxHeight: "50px",
            [theme.breakpoints.down("xs")]: {
              maxHeight: "40px",
            },
          },
        },
      },
    },
    "& .timeline": {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
  },
}));
export default function ShareModal({
  openShare,
  handleCloseShare,
  url,
  text,
  type,
  data,
  timeLine,
  social,
}) {
  const classes = useStyles();
  const [feedmodal, setFeedModal] = React.useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [chatModal, setChatModal] = useState(false);
  const [search, setSearch] = useState("");
  const [userList, setUserList] = useState([]);
  const [shareUser, setShareUser] = useState([]);
  const deb = useDebounce(search, 1000);

  const getUsersList = async (id) => {
    try {
      const response = await getParticularObjectDataHanndler("connectedUsers", {
        user_type: "user",
        search: search,
      });
      if (response.status === 200) {
        setUserList(response.data.data);
        setIsLoading(false);
      } else {
        setUserList([]);
      }
    } catch (error) {
      console.log(error);
      setUserList([]);
      setIsLoading(false);
    }
  };
  useEffect(() => {
    if (search !== "") {
      getUsersList();
    }
  }, [deb]);

  return (
    <Dialog open={openShare} onClose={handleCloseShare} maxWidth="sm" fullWidth>
      <DialogContent>
        <Typography
          variant="h6"
          style={{ fontSize: "20px", color: "#fff", textAlign: "center" }}
        >
          Share
        </Typography>
        <IconButton
          className={classes.cancelBtn}
          onClick={() => {
            handleCloseShare();
          }}
        >
          <CloseIcon />
        </IconButton>
        <Box className={classes.sharemodal} mb={2} align="center" mt={3}>
          {type === "sharePost" && (
            <>
              {social?.userData?.user_id !== data?.user_data?.user_id &&
                data?.is_poll !== "yes" && (
                  <Box align="center" className="shareModalBoxback ">
                    <Button
                      onClick={() => {
                        setFeedModal(true);
                        setChatModal(false);
                      }}
                    >
                      <CiViewTimeline /> <br />
                    </Button>
                    <Typography variant="body2">Feed</Typography>
                  </Box>
                )}
            </>
          )}
          <Box align="center" className="shareModalBoxback ">
            <Button onClick={() => setChatModal(false)}>
              <FacebookShareButton
                url={url}
                title={text !== undefined ? text : `Check latest post`}
                hashtag=""
              >
                <BsFacebook /> <br />
              </FacebookShareButton>
            </Button>
            <Typography variant="body2"> Facebook</Typography>
          </Box>
          <Box align="center" className="shareModalBoxback ">
            <Button onClick={() => setChatModal(false)}>
              <InstapaperShareButton
                url={url}
                title={text !== undefined ? text : `Check latest post`}
                hashtag=""
              >
                <AiFillInstagram /> <br />
              </InstapaperShareButton>
            </Button>
            <Typography variant="body2">Instagram</Typography>
          </Box>
          <Box align="center" className="shareModalBoxback ">
            <Button onClick={() => setChatModal(false)}>
              <TelegramShareButton
                url={url}
                title={text !== undefined ? text : `Check latest post`}
                hashtag=""
              >
                <RiSendPlaneFill /> <br />
              </TelegramShareButton>
            </Button>
            <Typography variant="body2"> Telegram</Typography>
          </Box>
          <Box align="center" className="shareModalBoxback ">
            <Button onClick={() => setChatModal(false)}>
              <TwitterShareButton
                url={url}
                title={text !== undefined ? text : `Check latest post`}
                hashtag=""
              >
                <AiFillTwitterCircle /> <br />
              </TwitterShareButton>
            </Button>
            <Typography variant="body2"> Twitter</Typography>
          </Box>
        </Box>

        {chatModal && (
          <Box>
            <Box mb={1}>
              <Divider style={{ background: "#FFFFFF1A" }} />
            </Box>
            <FormControl
              fullWidth
              className={`${classes.searchControl} placeholderColor`}
            >
              <TextField
                variant="outlined"
                placeholder="Search......"
                type="search"
                value={search}
                autoComplete="none"
                autoFocus={false}
                onChange={(e) => setSearch(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment
                      position="start"
                      style={{ paddingRight: "10px" }}
                    >
                      <IconButton edge="start">
                        <IoSearchOutline />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </FormControl>
            {isLoading &&
              [1, 2, 3, 4, 5].map((data, i) => {
                return (
                  <Grid item key={i} xs={12}>
                    <MightSkeleton type="reportList" />
                  </Grid>
                );
              })}

            {userList &&
              userList.map((data, i) => {
                return (
                  <Grid item key={i} xs={12}>
                    <OnlineUser
                      data={data}
                      classes={classes}
                      setShareUser={setShareUser}
                      shareUser={shareUser}
                      search={search}
                      setSearch={setSearch}
                    />
                  </Grid>
                );
              })}
          </Box>
        )}
      </DialogContent>
      <CreateFeedModal
        setFeedModal={() => {
          setFeedModal();
          handleCloseShare();
        }}
        feedmodal={feedmodal}
        previousData={data}
        type="edit"
        timeLine={timeLine}
      />
    </Dialog>
  );
}
