import { Box, makeStyles, Typography } from "@material-ui/core";
import React from "react";
const styles = makeStyles((theme) => ({
  headingSection: {
    "& h3": {
      fontStyle: "normal",
      fontSize: "16px",
      lineHeight: "22px",
      color: "#FFFFFF",
    },
  },
}));

export default function Heading({ title }) {
  const classes = styles();
  return (
    <Box className={classes.headingSection}>
      <Typography variant="h3">{title}</Typography>
    </Box>
  );
}
