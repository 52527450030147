import { Box, IconButton, makeStyles, Typography } from "@material-ui/core";
import React, { useState, useRef, useEffect } from "react";
import PlayCircleFilledIcon from "@material-ui/icons/PlayCircleFilled";
import PauseCircleFilledIcon from "@material-ui/icons/PauseCircleFilled";
const useStyles = makeStyles((theme) => ({
  musicCard: {
    padding: "10px 5px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    "& .leftSide": {
      display: "flex",
      alignItems: "center",

      "& img": {
        width: "50px",
        height: "50px",
        marginRight: "8px",
      },
      "& .description": {
        "& h4": {
          fontSize: "16px",
        },
        "& h6": {
          fontSize: "14px",
        },
      },
    },
  },
}));
function Player({ data, selectMusic, setSelectedMusic }) {
  const classes = useStyles();
  const audioEl = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);

  useEffect(() => {
    if (selectMusic?.id === data.id) {
      audioEl.current.play();
      setIsPlaying(true);
    } else {
      audioEl.current.pause();
      setIsPlaying(false);
    }
  }, [selectMusic]);

  return (
    <div className="my-player">
      <Box
        className={classes.musicCard}
        onClick={() => {
          setSelectedMusic(data);
        }}
        style={selectMusic?.id === data.id ? { background: "#fac3606b" } : {}}
      >
        <Box className="leftSide">
          {isPlaying ? (
            <img
              src="images/player_loading.gif"
              style={{ borderRadius: "100%" }}
            />
          ) : (
            <img src={data?.album?.cover_medium} alt="" />
          )}
          <Box className="description">
            <Typography variant="h4">{data?.title_short}</Typography>
            <Typography variant="h6">{data?.artist?.name}</Typography>
          </Box>
        </Box>
        <Box>
          <IconButton
            onClick={() => {
              setSelectedMusic(data);
              if (isPlaying) {
                audioEl.current.pause();
                setIsPlaying(false);
              } else {
                audioEl.current.play();
                setIsPlaying(true);
              }
            }}
          >
            {isPlaying ? <PauseCircleFilledIcon /> : <PlayCircleFilledIcon />}
          </IconButton>
        </Box>
      </Box>
      <audio src={data?.preview} ref={audioEl} loop={true} />
    </div>
  );
}

export default Player;
