import React from 'react'
import Skeleton from '@material-ui/lab/Skeleton';
import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles({
    root: {
        width: "100%",
        display: "flex",
        alignItems: 'center',
        margin: "10px"
    },
});
export default function SongSkeletonLodaer() {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <Skeleton style={{ backgroundColor: "rgba(64, 64, 64, 1.13)" }} animation="wave" variant="circle" width="50px" height="50px" />&nbsp;&nbsp;
            <div style={{ display: "block", width: "100%" }}>
                <Skeleton style={{ backgroundColor: "rgba(64, 64, 64, 1.13)" }} width="30%" animation="wave" height="20px" />
                <Skeleton style={{ backgroundColor: "rgba(64, 64, 64, 1.13)" }} width="30%" animation="wave" height="20px" />
            </div>
        </div>
    )
}
