import React, { useState } from 'react';
import { Box, Typography, Avatar, Button } from '@material-ui/core';
import { IMAGE_PATH } from 'src/constants';

function OnlineUser(props) {
  const { data, classes, shareUser, setShareUser } = props;
  const [checkUser, setCheckUser] = useState(false);

  return (
    <>
      <Box className={classes.Users} mt={2}>
        <Box className='UserBox'>
          <Box className='figure'>
            <Box className='profileimage'>
              <Avatar src={`${IMAGE_PATH}` + data.profile_img} />
            </Box>
          </Box>
          <Box className='timeline'>
            <Typography variant='body2' style={{ color: '#fff' }}>
              {data.name ? data.name : '...'}
            </Typography>
          </Box>
        </Box>
        <Button
          variant={checkUser ? 'outlined' : 'contained'}
          color={checkUser ? '' : 'secondary'}
          onClick={() => {
            setCheckUser(true);
            if (!checkUser) {
              setShareUser([...shareUser, data?._id]);
            } else {
              setCheckUser(false);
              setShareUser(shareUser?.filter((item, i) => item !== data?._id));
            }
          }}
        >
          {checkUser ? 'Undo' : 'Send'}
        </Button>
      </Box>
    </>
  );
}

export default OnlineUser;
