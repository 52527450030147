import {
  AppBar,
  Toolbar,
  makeStyles,
  Button,
  IconButton,
  Drawer,
  Grid,
  Box,
  Container,
  Menu,
  Hidden,
  SwipeableDrawer,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import { NavLink } from "react-router-dom";
import React, { useState, useContext, useEffect, useRef } from "react";
import { Link, useHistory } from "react-router-dom";
import Logo from "./../../component/Logo";
import { RxCross2 } from "react-icons/rx";
import { withStyles } from "@material-ui/core/styles";
import { SocialContext } from "src/context/SocialAuth";

const headersData = [
  {
    label: "login",
    href: "/login",
  },
];
const useStyles = makeStyles((theme) => ({
  menuButton1: {
    width: "100%",
    display: "flex",
    justifyContent: "start",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  toolbar: {
    display: "flex",
    padding: "10px 0",
    justifyContent: "space-between",
    height: "100%",
    "@media (max-width: 900px)": {
      paddingLeft: "75px",
      paddingRight: "20px",
      height: "100%",
    },
  },
  maindrawer: {
    height: "100%",
    background: "#0c0731",
    width: "260px",
  },
  logoDrawer: {
    paddingLeft: "10px",
    width: "140px",
    marginBottom: "20px",
    [theme.breakpoints.down("xs")]: {
      width: "200px",
    },
  },
  drawerContainer: {
    padding: "20px 0px ",
    height: "100%",
    background: "#000",
    width: "260px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
  },
  drawericon: {
    color: "#000",
    position: "absolute",
    top: "0px",
    right: "-10px",
    fontSize: "25px",
    display: "flex",
  },
  logoImg: {
    width: "75px",
    margin: " 14px 15px 11px 0px",
    objectFit: "contain",
    "@media (max-width: 500px)": {
      margin: " 11px 1px 3px 0px",
      width: "52px",
    },
  },

  paper1: {
    background: "black",
    color: "white",
  },
  containerHeight: {
    height: "100%",
  },
  mainHeader: {
    justifyContent: "space-between",
    padding: "0px",
  },
  search: {
    height: "31px",
    position: "relative",
    color: "#ABABAB",
    borderRadius: "100px",
    backgroundColor: "#E6E6E6",
    border: "1px solid #fff",
    "&:hover": {
      backgroundColor: "#ececec",
      border: "1px solid #300760",
    },
    marginLeft: 20,
    width: "140px",
    maxWidth: "257px",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(1),
      width: "180px",
    },
  },
  searchIcon: {
    fontSize: "16px",
    padding: theme.spacing(0, 2),
    color: "#000000",
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
    fontSize: "16px",
  },

  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    fontSize: "13px",
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100px",
    [theme.breakpoints.up("sm")]: {
      width: "100%",
      "&:focus": {
        width: "100%",
      },
    },
  },

  drawerPaper: {
    width: "100%",
    height: "100%",
  },
}));

export default function Header() {
  const social = useContext(SocialContext);
  const { setOpenLogin, setOpenSignUp } = social;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose1 = () => {
    setAnchorEl(null);
  };
  const [open11, setOpen11] = React.useState(false);

  const handleDrawerOpen11 = () => {
    setOpen11(true);
  };

  const handleDrawerClose11 = () => {
    setOpen11(false);
  };
  const classes = useStyles();

  const {
    menuButton1,
    toolbar,
    drawerContainer,
    drawericon,
    logoDrawer,
    containerHeight,
    mainHeader,
  } = useStyles();
  const history = useHistory();
  const [state, setState] = useState({
    mobileView: false,
    drawerOpen: false,
  });
  const { mobileView, drawerOpen } = state;

  useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < 1220
        ? setState((prevState) => ({ ...prevState, mobileView: true }))
        : setState((prevState) => ({ ...prevState, mobileView: false }));
    };

    setResponsiveness();

    window.addEventListener("resize", () => setResponsiveness());
  }, []);

  const displayDesktop = () => {
    return (
      <Container maxWidth="lg" className="p-0">
        <Toolbar className={toolbar}>
          {femmecubatorLogo}
          <Grid
            container
            item
            direction="row"
            justify="flex-end"
            alignItems="center"
            style={{ paddingLeft: "0px" }}
          >
            {window.localStorage.getItem("token") ? (
              <Button
                variant="contained"
                className="signupButton"
                color="secondary"
                onClick={() => history.push("/home")}
              >
                Get Started
              </Button>
            ) : (
              <>
                <Button
                  variant="h6"
                  color="primary"
                  style={{ color: "#fff", cursor: "pointer" }}
                  onClick={() => setOpenLogin(true)}
                >
                  Login
                </Button>
                <Button
                  variant="contained"
                  className="signupButton"
                  color="secondary"
                  style={{ marginLeft: "17px" }}
                  onClick={() => setOpenSignUp(true)}
                >
                  Sign Up
                </Button>
              </>
            )}
          </Grid>
        </Toolbar>
      </Container>
    );
  };

  const displayMobile = () => {
    const handleDrawerOpen = () =>
      setState((prevState) => ({ ...prevState, drawerOpen: true }));
    const handleDrawerClose = () =>
      setState((prevState) => ({ ...prevState, drawerOpen: false }));

    return (
      <Toolbar className={mainHeader}>
        <Hidden xsDown>
          <Drawer
            {...{
              anchor: "right",
              open: drawerOpen,
              onClose: handleDrawerClose,
            }}
          >
            <div className={drawerContainer}>
              <img className={logoDrawer} src="/images/logo.png" alt="" />
              {getDrawerChoices()}
            </div>
          </Drawer>
        </Hidden>
        <Hidden smUp>
          <SwipeableDrawer
            {...{
              anchor: "bottom",
              open: drawerOpen,
              onClose: handleDrawerClose,
            }}
            classes={{
              paper: classes.drawerPaper,
            }}
          >
            <div className={drawerContainer}>
              <div className={classes.IconButtonBox}>
                <IconButton onClick={() => handleDrawerClose()}>
                  <RxCross2 />
                </IconButton>
              </div>
              <img className={logoDrawer} src="/images/logo.png" alt="" />
              {getDrawerChoices()}
            </div>
          </SwipeableDrawer>
        </Hidden>

        <div>{femmecubatorLogo}</div>
        <Grid container>
          <Grid item xs={6}></Grid>
          <Grid item xs={4}>
            <Box className={drawericon}>
              <IconButton
                {...{
                  edge: "start",
                  color: "inherit",
                  "aria-label": "menu",
                  "aria-haspopup": "true",
                  onClick: handleDrawerOpen,
                }}
              >
                <MenuIcon
                  width="60px"
                  height="60px"
                  style={{ color: "orange", fontSize: "30px" }}
                />
              </IconButton>
            </Box>
          </Grid>
        </Grid>
      </Toolbar>
    );
  };

  const getDrawerChoices = () => {
    return headersData.map(({ label, href }) => {
      return (
        <>
          <Button
            {...{
              key: label,
              color: "inherit",
              to: href,
              component: Link,
              className: menuButton1,
            }}
          ></Button>
          {social.userLoggedIn ? (
            <Button
              variant="contained"
              className="signupButton"
              color="secondary"
              onClick={() => history.push("/home")}
              style={{ margin: "0px 16px" }}
            >
              Get Started
            </Button>
          ) : (
            <>
              <Button
                variant="contained"
                className="signupButton"
                color="secondary"
                style={{ marginLeft: "17px", marginTop: "20px" }}
                onClick={() => setOpenLogin(true)}
              >
                Login
              </Button>
              <Button
                variant="contained"
                className="signupButton"
                color="secondary"
                style={{ marginLeft: "17px", marginTop: "20px" }}
                onClick={() => setOpenSignUp(true)}
              >
                Sign Up
              </Button>
            </>
          )}
        </>
      );
    });
  };

  const femmecubatorLogo = (
    <Box>
      <Link to="/">
        <Logo className="logoImg" />
      </Link>
    </Box>
  );

  return (
    <>
      <AppBar
        position={history.location.pathname !== "/" ? "relative" : "absolute"}
        elevation={0}
        style={{
          border: "none",
          background: "rgba(255, 255, 255, 0.04)",
          backdropFilter: "blur(21.5px)",
        }}
      >
        <Container
          maxWidth={history.location.pathname !== "/" ? "lg" : "fixed"}
          className={containerHeight}
        >
          {mobileView ? displayMobile() : displayDesktop()}
        </Container>
      </AppBar>
      <Drawer
        variant="temporary"
        anchor="right"
        open={open11}
        onClose={handleDrawerClose11}
        style={{ overflowY: "scroll" }}
      ></Drawer>
    </>
  );
}
