import React, { useContext, useState, useEffect } from "react";
import {
  FormControl,
  Box,
  TextField,
  Avatar,
  Button,
  withStyles,
  InputAdornment,
  IconButton,
  makeStyles,
  Dialog,
} from "@material-ui/core";
import { IMAGE_PATH } from "src/constants";
import { SocialContext } from "src/context/SocialAuth";
import { AiOutlineGif } from "react-icons/ai";
import ButtonCircularProgress from "src/component/ButtonCircularProgress";
import SendIcon from "@material-ui/icons/Send";
import Gifphy from "src/Gif/Gifphy";
import Menu from "@material-ui/core/Menu";
import axios from "axios";
import Apiconfigs from "src/Apiconfigs/config";
import toast from "react-hot-toast";

const useStyles = makeStyles((theme) => ({
  mainbox: {
    background: "#0d0d0d !important",
    padding: "5px 10px",
    width: "100%",
    borderRadius: "5px",
    "& .MuiOutlinedInput-root": {
      backgroundColor: "transparent !important",
    },
  },
  modalComment: {
    "& .commentBox": {
      borderTop: "1px solid #fdc7630f",
      marginTop: "16px",
      padding: "5px 0",
      [theme.breakpoints.down("xs")]: {
        padding: "0px 0",
        marginTop: "10px",
      },
      "& .buttonBox": {
        color: "rgba(255,255,255,0.57)",
        border: "none",
        minWidth: "40px",
        [theme.breakpoints.down("sm")]: {
          minWidth: "40px",
        },
        [theme.breakpoints.down("xs")]: {
          minWidth: "40px",
        },
        "&:hover": {
          background: "transparent",
          border: "none",
          color: "#FDC763",
          "& svg": {
            color: "#FDC763",
          },
          "& p": {
            color: "#FDC763",
          },
        },
      },
      "& button": {
        [theme.breakpoints.down("xs")]: {
          fontSize: "10px !important",
          height: "34px !important",
        },
        "& p": {
          color: "rgba(255,255,255,0.57)",
        },
        "& svg": {
          fontSize: "20px",
          marginRight: "5px",
          color: "rgba(255,255,255,0.57)",
          [theme.breakpoints.down("xs")]: {
            fontSize: "15px",
          },
        },
      },
    },
  },
  mainDig: {
    "& .MuiDialog-paperScrollPaper": {
      height: "500px",
    },
    "& .MuiDialogContent-root:first-child": {
      height: "500px",
    },
  },
}));
const StyledMenu = withStyles((theme) => ({
  paper: {
    color: "#8B8B8B",
    border: "1px solid rgb(255 254 254 / 13%)",
    minWidth: "127px !important",
    boxShadow: "0px 0px 11px rgb(0 0 0 / 16%)",
    borderRadius: "10px",
    marginLeft: "-60px",
    padding: "10px",
    backgroundColor: "#2d2d2deb !important",
    [theme.breakpoints.down("sm")]: {
      marginLeft: "0px",
    },
  },
}))((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));
const ReplyComment = (props) => {
  const classes = useStyles();
  const { data, getReplyCommentList } = props;
  const social = useContext(SocialContext);
  const [comment, setComment] = useState("");
  const [gif, setGif] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [anchorElGif, setAnchorElGif] = useState(false);

  const commentHandler = async (id, commentType) => {
    setGif("");
    if (comment !== "") {
      setIsLoading(true);
      try {
        const res = await axios({
          method: "POST",
          url: Apiconfigs.sub_comment,
          headers: {
            authorization: localStorage.getItem("token"),
          },
          data: {
            comment: comment,
            post_id: data?.post_data?.id,
            comment_id: data?.id,
            type: commentType,
          },
        });
        if (res.status === 200) {
          getReplyCommentList();
          setIsLoading(false);
          setComment("");
        } else {
          toast.error(res.data.message);
          setIsLoading(false);
        }
      } catch (error) {
        setIsLoading(false);
        toast.error(error.response.data.message);
        setComment("");
      }
    }
  };

  useEffect(() => {
    const checkComment = comment.includes("giphy.com");
    if (checkComment) {
      commentHandler(data?.id, "image");
    }
  }, [comment]);

  return (
    <Box className={classes.mainbox}>
      <Box display="flex" alignItems="center">
        <Box style={{ marginRight: "10px" }}>
          <Avatar src={`${IMAGE_PATH}` + social?.userData?.profile_url} />
        </Box>

        <FormControl fullWidth className="figure">
          <TextField
            id="outlined-basic"
            variant="outlined"
            name="Text Field"
            placeholder={`Reply to ${data?.commented_user_data?.name}...`}
            type="text"
            fullWidth
            autoComplete="off"
            value={comment ? comment : ""}
            onChange={(e) => {
              if (e.target.value.length <= 256) {
                setComment(e.target.value);
              }
            }}
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                commentHandler(data?.id, "text");
              }
            }}
            disabled={isLoading}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end" style={{ paddingRight: "13px" }}>
                  <IconButton
                    style={{
                      position: "absolute",
                      right: "0px",
                      color: "#fff",
                    }}
                    onClick={() => setAnchorElGif(true)}
                    aria-controls="gif-menu"
                    aria-haspopup="true"
                  >
                    <AiOutlineGif />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </FormControl>

        <Box style={{ padding: "0px" }}>
          <Button
            onClick={() => commentHandler(data?.id, "text")}
            disabled={isLoading}
          >
            {isLoading ? (
              <ButtonCircularProgress />
            ) : (
              <SendIcon style={{ color: "#FFFFFF" }} />
            )}
          </Button>
        </Box>
      </Box>
      {gif && (
        <Box className={classes.modalGif}>{gif && <img src={gif} />}</Box>
      )}

      <Dialog
        className={classes.mainDig}
        open={anchorElGif}
        onClose={() => setAnchorElGif(false)}
      >
        <Gifphy
          setComment={(item) => setComment(item)}
          selectGif={(item) => setGif(item)}
          closeAnchorElGif={() => setAnchorElGif(false)}
          anchorElGif={anchorElGif}
        />
      </Dialog>
    </Box>
  );
};

export default ReplyComment;
