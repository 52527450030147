export default {
  h1: {
    fontWeight: 500,
    fontSize: 35,
    fontFamily: "'Nunito Sans', sans-serif",
  },
  h2: {
    fontWeight: 500,
    fontSize: 30,
    fontFamily: "'Nunito Sans', sans-serif",
  },
  h3: {
    fontWeight: 600,
    fontSize: 23,
    fontFamily: "'Nunito Sans', sans-serif",
    "@media (max-width:767px)": {
      fontSize: "16px",
    },
  },
  h4: {
    fontWeight: 400,
    fontSize: 20,
    fontFamily: "'Nunito Sans', sans-serif",
    "@media (max-width:767px)": {
      fontSize: "16px",
    },
  },
  h5: {
    fontWeight: 400,
    fontSize: 18,
    fontFamily: "'Nunito Sans', sans-serif",
    "@media (max-width:767px)": {
      fontSize: "14px",
    },
  },
  h6: {
    fontWeight: 400,
    fontSize: 16,
    fontFamily: "'Nunito Sans', sans-serif",
    "@media (max-width:767px)": {
      fontSize: "13px",
    },
  },
  body2: {
    fontWeight: 300,
    fontSize: 14,
    fontFamily: "'Nunito Sans', sans-serif",
    color: "rgba(255, 255, 255, 0.6)",
    "@media (max-width:767px)": {
      fontSize: "12px",
    },
  },
  body1: {
    fontWeight: 400,
    fontSize: 12,
    fontFamily: "'Nunito Sans', sans-serif",
    "@media (max-width:767px)": {
      fontSize: "10px",
    },
  },

  overline: {
    fontWeight: 500,
  },
  button: {
    textTransform: "capitalize",
    borderRadius: 27,
    fontFamily: "'Nunito Sans', sans-serif",
  },
  subtitle2: {
    fontWeight: 400,
    fontSize: 12,
    fontFamily: "'Nunito Sans', sans-serif",
  },
  subtitle3: {
    fontWeight: 500,
    fontSize: 11,
    fontFamily: "'Nunito Sans', sans-serif",
  },
};
