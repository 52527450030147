import UserLoader from "src/Skeletons/UserLoader";
import { Avatar, Box, Button, makeStyles, Typography } from "@material-ui/core";
import React, { useState, useEffect, useContext } from "react";
import { SocialContext } from "src/context/SocialAuth";

import StoryListData from "./StoryListData";
import { useHistory } from "react-router-dom";

const styles = makeStyles((theme) => ({
  storyListMain: {
    display: "flex",
    alignItems: "center",
    marginTop: "20px",
    "& h3": {
      fontStyle: "normal",
      fontSize: "14px",
      lineHeight: "19px",
      color: "#FFFFFF",
    },
    "& h6": {
      fontStyle: "normal",
      fontSize: "10px",
      color: "rgba(255, 255, 255, 0.6)",
    },
  },
  btnSection: {
    "& button": {
      background: "rgba(255, 255, 255, 0.05)",
      borderRadius: "10px",
      height: "40px",
      width: "100%",
      marginTop: "18px",
      color: "#FDC763 !important",
    },
  },
}));

export default function StoryList({ story }) {
  const classes = styles();
  const social = useContext(SocialContext);
  const history = useHistory();
  const [mystoryList, setMyStoryList] = useState([]);
  const [otherstoryList, setOtherStoryList] = useState([]);

  useEffect(() => {
    const filterMyStoryData = social?.storyData?.filter((data) => {
      return data?.user_id === social?.userData?.user_id;
    });
    setMyStoryList(filterMyStoryData);
    const filterotherstoryData = social?.storyData?.filter((data) => {
      return data?.user_id !== social?.userData?.user_id;
    });
    setOtherStoryList(filterotherstoryData);
  }, [social?.storyData]);

  return (
    <>
      {social?.storyLoading ? (
        <>
          <UserLoader />
        </>
      ) : (
        <>
          {mystoryList &&
            mystoryList.map((data, i) => {
              return (
                <>
                  <StoryListData data={data} index={i} classes={classes} />
                </>
              );
            })}
          {otherstoryList &&
            otherstoryList.slice(0, 4).map((data, i) => {
              return (
                <>
                  <StoryListData
                    data={data}
                    index={i}
                    classes={classes}
                    social={social}
                  />
                </>
              );
            })}

          <Box className={classes.btnSection}>
            <Button
              onClick={() => history.push("/story-list")}
              style={{ color: "#FDC763" }}
            >
              See All
            </Button>
          </Box>

          {!social?.storyLoading &&
            mystoryList &&
            mystoryList.length === 0 &&
            otherstoryList &&
            otherstoryList.length === 0 && (
              <Typography style={{ paddingTop: "10px" }}>
                No Story Available
              </Typography>
            )}
        </>
      )}
    </>
  );
}
