import {
  Dialog,
  DialogContent,
  Box,
  Typography,
  IconButton,
  TextField,
  Button,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React from "react";
import CloseIcon from "@material-ui/icons/Close";
const useStyles = makeStyles((theme) => ({
  iconbtn: {
    position: "absolute",
    top: "0",
    right: "0",
  },
  mainbox: {
    "& .typo": {
      paddingBottom: "14px",
      textAlign: "center",
      "& h5": {
        fontWeight: "700",
      },
    },
    "& .minbox": {
      height: "calc(100vh - 400px)",
      overflow: "scroll",
      padding: "17px 25px",
      background: "#FFFFFF",
      border: "1px solid #DFDFDF",
      borderRadius: "7px",
    },
    "& .imgbox": {
      paddingTop: "20px",
      display: "flex",
      alignItems: "center",
    },
    "& .typo2": {
      paddingLeft: "22px",
    },
  },
}));

export default function TagFriend({
  handleClickCloseFriendModal,
  friendModal,
}) {
  const classes = useStyles();
  return (
    <Dialog
      open={friendModal}
      onClose={handleClickCloseFriendModal}
      maxWidth="sm"
      fullWidth
    >
      <Box className={classes.iconbtn}>
        <IconButton onClick={handleClickCloseFriendModal}>
          <CloseIcon style={{ color: "#000" }} />
        </IconButton>
      </Box>
      <DialogContent>
        <Box className={classes.mainbox}>
          <Box className="typo">
            <Typography variant="h5">Tag Friends</Typography>
          </Box>
          <Box className="minbox">
            <Box>
              <TextField
                fullWidth
                variant="outlined"
                placeholder="Search for friends."
              />
            </Box>
            <Box className="imgbox">
              <img src="images/User2.png" />
              <Box className="typo2">
                <Typography variant="body2" style={{ fontWeight: "600" }}>
                  Rohit kumar
                </Typography>
                <Typography variant="body2" style={{ color: "#B6B6B6" }}>
                  @rohit
                </Typography>
              </Box>
            </Box>
            <Box className="imgbox">
              <img src="images/User2.png" />
              <Box className="typo2">
                <Typography variant="body2" style={{ fontWeight: "600" }}>
                  Jatin Dutt
                </Typography>
                <Typography variant="body2" style={{ color: "#B6B6B6" }}>
                  @Jatin
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box pt={2} align="center">
          <Button
            variant="contained"
            style={{ padding: "10px 50px" }}
            color="secondary"
            onClick={handleClickCloseFriendModal}
          >
            Done
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
