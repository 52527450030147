import { API_WALLET, API_SOCIALS, SOCKET_URL } from "src/constants";

// export const walletBaseurl = "https://api.tarality.online/"; //main
// export const socialBaseUrl = "https://api-taralgram.tarality.online/"; //noway

export const walletBaseurl = API_WALLET; //main
export const socialBaseUrl = API_SOCIALS; //noway
export const orderBookSocketUrl = `wss://orderbook.mobiloitte.io/`; //staging
// export const orderBookSocketUrl = `wss://orderbook.tarality.in/` //production

// export const walletBaseurl = "http://172.16.2.248:3000/"; //main
// export const socialBaseUrl = "http://172.16.2.248:4000/"; //noway

// export const orderBookSocketUrl = `ws://ec2-15-207-22-102.ap-south-1.compute.amazonaws.com:5000`;

// export const socketUrl = SOCKET_URL;
export const chatSocket = `${socialBaseUrl}socket`;
export const chatApiMainUrl = walletBaseurl.includes(
  "https://api.tarality.online/"
);

let walleturl = `${walletBaseurl}api/v2/`; //main
let socalurl = `${socialBaseUrl}api/v1/`; //noway
let admin = `${walletBaseurl}api/v1/`; //admin

const ApiConfig = {
  login: `${walleturl}user/login1`,
  signup: `${walleturl}user/register1`,
  check_available_user_name: `${walleturl}user/check_available_user_name`,
  forgotpassword: `${walleturl}user/forgotpassword`,
  resendOTP: `${walleturl}user/resendOTP`,
  updatepassword: `${walleturl}user/updatepassword`,
  uploadFile: `${walleturl}user/uploadimage`,
  changewalletpin: `${walleturl}user/changewalletpin`,
  verifymobilenumber: `${walleturl}user/verifymobilenumber`,
  countrycodelist: `${walleturl}user/countrycodelist`,
  verifyotp: `${walleturl}user/verifyotp`,
  verifyemail: `${walleturl}user/verifyemail`,
  addUserInfoWallet: `${walleturl}user/addUserInfo`,
  checkwalletpin: `${walleturl}user/checkwalletpin`,
  change_settings: `${walleturl}user/change_settings`,
  changepassword: `${walleturl}user/changepassword`,
  deleteMainAccount: `${walleturl}user/deleteaccount`,
  uploadimageTicket: `${walleturl}contactus/uploadimage`,
  uploadImageBase64: `${walleturl}user/uploadImageBase64`,
  log_out: `${walleturl}user/log-out`,

  //----order-book-----//
  open_order: `${walleturl}order/open_order`,
  user_order_list: `${walleturl}order/order_history`,
  order: `${walleturl}order/order`,
  cancelOrder: `${walleturl}order/cancel_order`,
  graph_data: `${walleturl}order/graph_data`,

  // SocialUser Url
  uploadImage: `${socalurl}file/upload`,
  uploadshort: `${socalurl}file/upload_short`,

  // utility
  get_settings: `${walleturl}utility/get_settings`,
  compressMedia: `${admin}admin/utility/compressMedia`,
  createPost: `${socalurl}post/create`,
  editPost: `${socalurl}post/edit`,
  post_timelines: `${socalurl}post/post_timelines`,
  postList: `${socalurl}post/list`,
  likePost: `${socalurl}post/like`,
  comment: `${socalurl}post/comment`,
  comments: `${socalurl}post/comments`,
  sub_comment: `${socalurl}post/sub_comment`,
  list_sub_comment: `${socalurl}post/list_sub_comment`,

  hashTagSearch: `${socalurl}post/hashTagSearch`,

  report_keywords: `${socalurl}post/report_keywords`,
  report: `${socalurl}post/report`,
  hash_tags_list: `${socalurl}post/hash_tags`,
  hash_tags_list_search: `${socalurl}post/searchHashUser`,
  hash_tags_suggestion: `${socalurl}post/hash_tags_suggestion`,
  search: `${socalurl}post/search`,
  detail_post: `${socalurl}post/detail`,
  followingPost: `${socalurl}post/following`,
  updatepoll: `${socalurl}post/update_poll`,
  viewpoll: `${socalurl}post/view_poll_v2`,

  // follow
  profile: `${socalurl}user/detail`,
  following: `${socalurl}user/following`,
  followers: `${socalurl}user/followers`,
  other_user_detail: `${socalurl}user/other_user_detail`,
  list_accounts_by_keyword: `${socalurl}user/list_accounts_by_keyword`,

  // Block
  loginSocial: `${socalurl}user/login`,
  addUserInfo: `${socalurl}user/addUserInfo`,
  block_unblock: `${socalurl}user/block_unblock`,
  block_user_list: `${socalurl}user/block_user_list`,
  top_profiles: `${socalurl}user/top_profiles`,
  topRewardProfiles: `${socalurl}user/top_reward_profiles`,
  areaOfInterests: `${socalurl}user/area_of_interests`,

  // post
  my_posts: `${socalurl}post/my_posts`,
  feeds: `${socalurl}post/feeds`,
  webFeeds: `${socalurl}post/web_feeds`,
  mypost_list: `${socalurl}post/mypost_list`,
  tipPost: `${socalurl}post/tip`,
  deletePost: `${socalurl}post/delete`,
  trendingPosts: `${socalurl}post/trending_posts`,
  mostViewedPosts: `${socalurl}post/most_viewed_posts`,
  userpolllist: `${socalurl}post/user_poll_list`,

  //tadak
  my_other_shorts: `${socalurl}short/my_other_shorts`,
  like_dislike: `${socalurl}short/like_dislike`,
  commentShorts: `${socalurl}short/comment`,
  commentsList: `${socalurl}short/comments`,
  tipshort: `${socalurl}short/tip`,
  createshort: `${socalurl}short/create`,
  listshort: `${socalurl}short/list`,
  shortdetail: `${socalurl}short/detail`,

  // notification

  notifications: `${socalurl}user/notifications`,
  walletnotifications: `${walleturl}wallet/notifications`,

  // activity
  activities: `${socalurl}user/activities`,

  /* SWAP */

  swapHistory: `${walleturl}swap/history`,

  // *****************Crypto*****************  wallet  ***********
  // trade
  pairCurrencyList: `${walleturl}trade/pairCurrencyList`,
  graph: `${walleturl}trade/graph`,
  conversion_rate: `${walleturl}trade/conversion`,
  createOrder: `${walleturl}trade/order`,
  tradeHistory: `${walleturl}trade/history`,

  watchlistUpdate: `${walleturl}watchlist/update`,
  watchlistList: `${walleturl}watchlist/list`,
  staticContent: `${walleturl}page/about_us`,
  getPagelist: `${walleturl}page/getPagelist/a`,

  //business page
  categoriesList: `${socalurl}business_pages/categories`,
  createBusinessPage: `${socalurl}business_pages/create`,
  pageList: `${socalurl}business_pages/list`,
  sendVerificationRequestForBusiness: `${socalurl}business_pages/send_verification_request`,

  // story
  create: `${socalurl}story/create`,
  list: `${socalurl}story/list`,
  uploadstory: `${socalurl}file/upload_story`,
  myotherstories: `${socalurl}story/my_other_stories`,
  userStoryDelete: `${socalurl}story/delete`,

  // verification
  sendverificationrequest: `${socalurl}user/send_verification_request`,
  genrelist: `${socalurl}user/genre_list`,

  //* ****************** ----REWARD---- ****************** */
  dailyRewardHistory: `${socalurl}reward/daily_reward_history`,
  collected_reward_history: `${socalurl}reward/collected_reward_history`,
  daily_reward_history: `${socalurl}reward/daily_reward_history`,
  airdropRewardHistory: `${socalurl}reward/airdrop_reward_history`,
  saveAdmobReward: `${socalurl}reward/save_admob_reward`,
  getAllRewardWallet: `${walleturl}reward/getAllRewardWallet`,
  rewardHistory: `${socalurl}reward/history`,
  rewardHistoryWallet: `${walleturl}reward/history`,
  rewardClaim: `${socalurl}reward/claim`,
  rewardUnlocked: `${socalurl}reward/unlocked`,
  plans: `${walleturl}reward/plans`,
  rewardHistorys: `${walleturl}reward/history`,
  withdrawDepositReward: `${walleturl}reward/withdrawDepositReward`,
  scratch_card_list: `${walleturl}reward/scratch_card_list`,
  scartch_the_card: `${walleturl}reward/scartch_the_card`,
  trunch_list: `${socalurl}reward/trunch_list`,
  rewards_points: `${socalurl}reward/points`,
  claim_points_reward: `${socalurl}reward/claim_points_reward`,
  rewards_info: `${socalurl}reward/rewards_info`,
  reward_amount_inr: `${walleturl}reward/reward_amount_inr`,

  checkClaimEligibility: `${socalurl}reward/check_claim_eligibility`,
  balance: `${socalurl}user/balance`,
  fifty_reward_available: `${socalurl}user/fifty_reward_available`,
  fifty_reward_api: `${socalurl}user/fifty_reward_api`,
  redeem_history: `${walleturl}user/redeem_history`,
  getOverAllStatement: `${walleturl}user/getOverAllStatement`,

  redeemCouponInfluencer: `${walleturl}user/redeemCouponInfluencer`,

  //* ****************** ----wallet---- ****************** */

  REFERRAL_LIST_API: `${walleturl}referral/history`,
  mlm_history: `${walleturl}referral/mlm_history`,
  tree: `${walleturl}referral/tree`,
  referral_note: `${walleturl}page/referral_note`,
  profile1: `${walleturl}user/userdetails`,
  redeem_coupon: `${admin}user/redeem_coupon`,
  simple_interest_history: `${walleturl}wallet/simple_interest_history`,
  lock_fundHistory: `${walleturl}lock_fund/history`,
  withdrawHistory: `${walleturl}withdraw_deposit/withdraw_history`,
  depositHistory: `${walleturl}withdraw_deposit/deposit_history`,
  sendAssets: `${walleturl}withdraw_deposit/`,
  fiatwithdraw: `${walleturl}fiatWallet/withdraw`,
  claim_deposite_amount: `${walleturl}fiatWallet/claim_deposite_amount`,

  fiatWalletHistory: `${walleturl}fiatWallet/history`,
  adminAccount: `${walleturl}fiatWallet/adminAccount`,
  addpayee: `${walleturl}wallet/addpayee`,
  getpayee: `${walleturl}wallet/getpayee`,
  addtoken: `${walleturl}wallet/addtoken`,
  balances_wallet: `${walleturl}wallet/balances`,
  searchtoken: `${walleturl}wallet/searchtoken`,
  checkouterwallet: `${walleturl}wallet/checkouterwallet`,
  walletTransactions: `${admin}wallet/transactions`,
  wallet_txn: `${walleturl}wallet/transactions`,
  walletTransactiondetails: `${walleturl}wallet/transactiondetails`,
  walletCoinList: `${walleturl}wallet/coinList2`,
  forgotPinForWeb: `${walleturl}wallet/forgotPinForWeb`,
  managewallet: `${walleturl}wallet/managewallet`,
  sendtrnxotp: `${walleturl}wallet/sendtrnxotp`,
  verifytrnxotp: `${walleturl}wallet/verifytrnxotp`,

  // busigness ico page
  myicopage: `${socalurl}business_pages/myico_page`,
  businesspagelist: `${socalurl}business_pages/list`,
  changestatus: `${socalurl}business_pages/change_status`,
  businessPageUpdate: `${socalurl}business_pages/update`,
  activeDeactiveRequest: `${socalurl}business_pages/activeDeactiveRequest`,
  buyico: `${socalurl}business_pages`,
  adddefaultico: `${socalurl}business_pages/add_default_ico`,
  viewdefaultico: `${socalurl}business_pages/view_default_ico`,

  coinListWithFilter: `${walleturl}admin/users/coinListWithFilter`,
  getFiatBalanceByUserid: `${walleturl}user/getFiatBalanceByUser_id`,

  findWalletBalance: `${walleturl}user/findWalletBalance`,

  usericohistory: `${socalurl}business_pages/user_ico_history`,
  deleteBusinessPage: `${socalurl}business_pages/delete`,
  check_business_page_name: `${socalurl}business_pages/check_business_page_name`,
  getvestingrules: `${socalurl}admin/business_pages/ico`,

  //Chat
  connectedUsers: `${socalurl}user/chat/users/connected`,
  chatDetails: `${socalurl}user/chat/details`,
  redeem: `${socalurl}user/redeem`,
  profilessuggestions: `${socalurl}user/profiles_suggestions`,

  // campagion advertisment

  uploadadvertisement: `${socalurl}file/upload_advertisement`,
  advertisementCreate: `${socalurl}advertisement/create`,
  advertisementList: `${socalurl}advertisement/list`,
  advertisementdetails: `${socalurl}advertisement/details`,
  advertisementpay: `${socalurl}advertisement/pay`,
  advertisementalllist: `${socalurl}advertisement/all_list`,
  advertisementViewcount: `${socalurl}advertisement/view_count`,
  advertisementDelete: `${socalurl}advertisement/delete`,
  advertisementUpdate: `${socalurl}advertisement/update`,

  // Influencer_apply

  categoryListForInfluencer: `${socalurl}user/categoryListForInfluencer`,
  create_update_Influencer: `${socalurl}influencer_apply/create_update`,
  detailsInfluencer: `${socalurl}influencer_apply/details`,
  influencer_payment: `${socalurl}influencer_apply/pay`,
  influencerList: `${socalurl}influencer_apply/list`,
  my_invite_list: `${socalurl}influencer_apply/my_invite_list`,
  inviteInflue: `${socalurl}influencer_apply/invite`,
  my_invite_request_list: `${socalurl}influencer_apply/my_invite_request_list`,
  pay_invited_influencer: `${socalurl}influencer_apply/pay_invited_influencer`,
  influencer_rating: `${socalurl}influencer_apply/influencer_rating`,
  influChangeStatus: `${socalurl}influencer_apply/changeStatus`,
  accept_reject_job: `${socalurl}influencer_apply/accept_reject_job`,
  mark_as_complete: `${socalurl}influencer_apply/mark_as_complete`,

  //----TARAL-GOLD----//
  bannersList: `${walleturl}banners/list`,
  coinpriceList: `${walleturl}installment/cmc_live_price_inr`,
  get_credit_limit: `${walleturl}installment/get_credit_limit`,
  plan_list: `${walleturl}installment/plan-list`,
  myPlansV2: `${walleturl}installment/myPlansV2`,
  payPenaltyFee: `${walleturl}installment/payPenaltyFee`,
  planExtensionV3: `${walleturl}installment/planExtensionV3`,
  subscribedPlanV3: `${walleturl}installment/subscribedPlanV3`,
  plans_history: `${walleturl}installment/plans_history`,
  subscription_status_update: `${walleturl}installment/subscription_status_update`,
  claim_amount: `${walleturl}installment/claim_amount`,
  payInstallmentV3: `${walleturl}installment/payInstallmentV3`,
  installment_list: `${walleturl}installment/installment_list`,
  terminateSubscription: `${walleturl}installment/terminateSubscriptionV3`,

  coinListWithFilter: `${admin}admin/users/coinListWithFilter`,
  findWalletBalance: `${admin}user/findWalletBalance`,
  claim_amountV3: `${walleturl}installment/claim_amountV3`,
  listSubscriptionHistoryV3: `${walleturl}installment/listSubscriptionHistoryV3`,

  //bank
  addNewAccount: `${walleturl}bank/addNewAccount`,
  verifyAccount: `${walleturl}bank/verifyAccount`,
  account_list: `${walleturl}bank/account_list`,
  web_account_list: `${walleturl}bank/web_account_list`,

  deleteAccount: `${walleturl}bank/deleteAccount`,
  get_name: `${walleturl}bank/get_name`,
  addBankAccount: `${walleturl}bank/addBankAccount`,
  getBankDepositSetting: `${walleturl}bank/getBankDepositSetting`,
  check_bank_account: `${walleturl}user/check_bank_account`,

  //-----CONTACT--US----//

  subject_list: `${walleturl}contactus/contact_us_subjects_list`,
  contactus: `${walleturl}contactus/contactus`,
  query_list: `${walleturl}contactus/query_list`,
  query_view: `${walleturl}contactus/query_view`,
  send_query_reply: `${walleturl}contactus/send_query_reply`,
  token_request: `${walleturl}token-request/new`,

  //-----boostPlan----//
  boostPlanList: `${socalurl}boost_plan/list`,
  boostPlanPay: `${socalurl}boost_plan/pay`,
  plan_dashboard: `${socalurl}boost_plan/plan_dashboard`,
  my_subscription_plan_list: `${socalurl}boost_plan/my_subscription_plan_list`,

  ///-----adharVerify------///
  adharVerifyacc: `${walleturl}user/aadhar_pan_validate`,
  otpadharVerifyacc: `${walleturl}user/aadhaar_otp`,
  submitVerifyacc: `${walleturl}user/validate_aadhaar_otp`,

  ///---- Stake----///
  stakePlan: `${walleturl}lock_fund/time-plan-list`,
  requestForStake: `${walleturl}lock_fund/lockFund`,
  stakeList: `${walleturl}lock_fund/history`,
  stakeInterestHistory: `${walleturl}wallet/user_staked_interest_history`,

  //---pages----//
  my_pages: `${socalurl}user/my_pages`,
  page_login: `${socalurl}user/page_login`,
};
export default ApiConfig;
