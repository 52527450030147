import React from "react";
import Heading from "src/component/storysidebar/Heading";
import {
  Box,
  makeStyles,
  Divider,
  Avatar,
  Typography,
} from "@material-ui/core";

const styles = makeStyles((theme) => ({
  root: {
    border: "1px solid rgba(255, 255, 255, 0.1)",
  },
  storyBody: {
    padding: "15px",
    "& .ethBox": {
      fontWeight: "600",
      fontSize: "14px",
      color: "#fff",
    },
  },
  divider: {
    backgroundColor: "rgba(255, 255, 255, 0.1)",
  },
  crdsec: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "13px",
    "& span": {
      color: "#41E033",
      fontSize: "12px",
      marginBottom: "5px",
    },
    "& h6": {
      fontSize: "10px",
    },
    "& h3": {
      fontSize: "12px",
      fontWeight: "300",
    },
    "& .MuiAvatar-root": {
      width: "25px",
      height: "25px",
    },
  },

  voltermeter: {
    padding: "15px",
    background: "rgba(255, 255, 255, 0.05)",
    "& h3": {
      fontSize: "18px",
    },
    "& .MuiAvatar-root": {
      width: "25px",
      height: "25px",
    },
    "& span": {
      fontSize: "10px",
      color: " rgba(255, 255, 255, 0.6)",
    },
  },
}));

const tranxarray = [
  {
    image: "/images/Coin/bnbIcon.png",
    coinNmae: "Withdraw",
    status: "Completed",
    balance: "$ 63,284.03",
    date: "20 Apr, 2022",
  },
  {
    image: "/images/Coin/bnbIcon.png",
    coinNmae: "Withdraw",
    status: "Failed",
    balance: "$ 63,284.03",
    date: "20 Apr, 2022",
  },
  {
    image: "/images/Coin/bnbIcon.png",
    coinNmae: "Add  INR",
    status: "Completed",
    balance: "$ 63,284.03",
    date: "20 Apr, 2022",
  },
  {
    image: "/images/Coin/bnbIcon.png",
    coinNmae: "Withdraw",
    status: "Failed",
    balance: "$ 63,284.03",
    date: "20 Apr, 2022",
  },
];

const RecentHistory = () => {
  const classes = styles();

  return (
    <Box className={classes.root}>
      <Box className={classes.storyBody}>
        <Heading title="Resent Transactions" />
        {tranxarray.map((data) => {
          return (
            <>
              <Box style={{ marginTop: "11px" }}>
                <Divider className={classes.divider} />
              </Box>
              <Box className={classes.crdsec}>
                <Box display="flex">
                  <Avatar src={data.image} />
                  <Box ml={1}>
                    <Typography variant="h3">
                      {data.coinNmae} <span className="ethBox">ETH</span>
                    </Typography>
                    {data.status === "Failed" ? (
                      <span style={{ color: "#FF7777", marginTop: "6px" }}>
                        {data.status}
                      </span>
                    ) : (
                      <span style={{ marginTop: "6px" }}>{data.status}</span>
                    )}

                    <Typography
                      variant="body1"
                      style={{
                        fontSize: "12px",
                        marginTop: "2px",
                        whiteSpace: "pre",
                      }}
                    >
                      {data.balance}
                    </Typography>
                  </Box>
                </Box>
                <Typography
                  variant="body2"
                  style={{ fontSize: "11px", whiteSpace: "pre" }}
                >
                  {data.date}
                </Typography>
              </Box>
            </>
          );
        })}
      </Box>
      <Box>
        <Divider className={classes.divider} />
        <Box p={2}>
          <Box className={classes.voltermeter}>
            <Box display="flex">
              <Avatar src="/images/Coin/bnbIcon.png" />
              &nbsp;&nbsp;
              <Typography variant="h3">Fear & Greed Index</Typography>
            </Box>
            <span>Multifactional Crypto Market Sentiment Analysis</span>
            <Box style={{ marginTop: "4px" }}>
              <Divider className={classes.divider} />
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default RecentHistory;
