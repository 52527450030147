import {
  LinearProgress,
  withStyles,
  Box,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { CheckCircle, Done } from "@material-ui/icons";
import React from "react";

export default function PoolProgressBar({ pollDetails }) {
  return (
    <div>
      <Box>
        <Box>
          {pollDetails?.pollDetails?.poll_title_1 != "" && (
            <Box className="CustomProgressPoll">
              <Typography variant="body2" color="primary">
                {pollDetails?.pollDetails?.poll_title_1}
              </Typography>
              <Typography variant="body2" color="primary">
                {pollDetails?.answer === 1 && <CheckCircle />}
                {pollDetails?.pollDetails?.vote_1}%
              </Typography>
              <Box
                className="CustomProgressPollInner grey"
                style={
                  pollDetails?.pollDetails?.vote_1 === 0
                    ? { background: "rgb(30, 29, 29, 0.4)", width: "100%" }
                    : { width: `${pollDetails?.pollDetails?.vote_1}%` }
                }
              ></Box>
            </Box>
          )}
          {pollDetails?.pollDetails?.poll_title_2 != "" && (
            <Box className="CustomProgressPoll">
              <Typography variant="body2" color="primary">
                {pollDetails?.pollDetails?.poll_title_2}
              </Typography>
              <Typography variant="body2" color="primary">
                {pollDetails?.answer === 2 && <CheckCircle />}
                {pollDetails?.pollDetails?.vote_2}%
              </Typography>
              <Box
                className="CustomProgressPollInner blue"
                style={
                  pollDetails?.pollDetails?.vote_2 === 0
                    ? { background: "rgb(30, 29, 29, 0.4)", width: "100%" }
                    : { width: `${pollDetails?.pollDetails?.vote_2}%` }
                }
              ></Box>
            </Box>
          )}
          {pollDetails?.pollDetails?.poll_title_3 != "" && (
            <Box className="CustomProgressPoll">
              <Typography variant="body2" color="primary">
                {pollDetails?.pollDetails?.poll_title_3}
              </Typography>
              <Typography variant="body2" color="primary">
                {pollDetails?.answer === 3 && <CheckCircle />}
                {pollDetails?.pollDetails?.vote_3}%
              </Typography>
              <Box
                className="CustomProgressPollInner green"
                style={
                  pollDetails?.pollDetails?.vote_3 === 0
                    ? { background: "rgb(30, 29, 29, 0.4)", width: "100%" }
                    : { width: `${pollDetails?.pollDetails?.vote_3}%` }
                }
              ></Box>
            </Box>
          )}
          {pollDetails?.pollDetails?.poll_title_4 != "" && (
            <Box className="CustomProgressPoll">
              <Typography variant="body2" color="primary">
                {pollDetails?.pollDetails?.poll_title_4}
              </Typography>
              <Typography variant="body2" color="primary">
                {pollDetails?.answer === 4 && <CheckCircle />}
                {pollDetails?.pollDetails?.vote_4}%
              </Typography>
              <Box
                className="CustomProgressPollInner yellow"
                style={
                  pollDetails?.pollDetails?.vote_4 === 0
                    ? { background: "rgb(30, 29, 29, 0.4)", width: "100%" }
                    : { width: `${pollDetails?.pollDetails?.vote_4}%` }
                }
              ></Box>
            </Box>
          )}
        </Box>
      </Box>
    </div>
  );
}
