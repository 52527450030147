import React, { useContext, useState } from "react";
import { Container, makeStyles } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import TopBar from "src/layouts/DashboardLayout/TopBar";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    width: "100%",
    backgroundColor: "#000000",
  },

  contentContainer: {
    flex: "1 1 auto",
    overflow: "auto",
    [theme.breakpoints.down("sm")]: {
      padding: " 20px 0px",
    },
  },
  content: {
    flex: "1 1 auto",
    position: "relative",
    WebkitOverflowScrolling: "touch",
    padding: "0px 0 60px",
    [theme.breakpoints.down("sm")]: {
      zIndex: "9",
      overflow: "hidden",
      padding: "0px 0 0px",
    },
  },
  mainBox_layout: {
    display: "flex",
    width: "100%",
  },
}));

const MainLayout = ({ children }) => {
  const classes = useStyles();
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);

  return (
    <div className={classes.root}>
      <TopBar onMobileNavOpen={() => setMobileNavOpen(true)} type="crypto" />

      <div className={`${classes.mainBox_layout} mainMobileView`}>
        <div className={classes.contentContainer}>
          <div className={classes.content} id="main-scroll">
            <div className={classes.MainLayout}>
              <Container maxWidth="fixed">{children} </Container>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainLayout;
