import React, { useState, useEffect, useContext } from "react";
import Page from "src/component/Page";
import PostCard from "src/component/PostCard";
import NodatafoundImage from "src/component/NoDataFound";
import { Box, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  NoDataBoxModal: {
    height: "98dvh",
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    overflow: "hidden",
    scrollBehavior: "unset",
  },
}));

export default function ({
  modalData,
  type,
  arrayData,
  callBackFun,
  setOpenModal,
  setImagePostList,
  index,
}) {
  const [arrayofpost, setarrayofpost] = useState([]);

  useEffect(() => {
    const filterdata = arrayData?.filter((data, i) => {
      if (data?.id?.toString() == modalData?.toString()) {
        setarrayofpost(data);
      }
    });
  }, [arrayData]);
  const classes = useStyles();
  return (
    <Page title="View Post">
      {arrayofpost.length !== 0 && arrayofpost?.is_poll !== "yes" ? (
        <PostCard
          data={arrayofpost}
          type="card"
          typeData={"modalcino"}
          viewComment={modalData}
          setOpenModalPar={setOpenModal}
          arrayData={arrayData}
          setImagePostList={(item) => setImagePostList(item)}
          index={index}
        />
      ) : (
        <Box className={classes.NoDataBoxModal}>
          <NodatafoundImage />
        </Box>
      )}
    </Page>
  );
}
