import {
  dataPostHandler,
  postAPIHandlerIdInBody,
} from "src/Apiconfigs/service";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  makeStyles,
  Typography,
  IconButton,
} from "@material-ui/core";
import React, { useState } from "react";
import toast from "react-hot-toast";
import ButtonCircularProgress from "./ButtonCircularProgress";
import { IoClose } from "react-icons/io5";
import { motion, AnimatePresence } from "framer-motion";

const useStyles = makeStyles((theme) => ({
  cancelBtn: {
    position: "absolute",
    top: 0,
    right: 0,
    "& svg": {
      color: "#D1913C",
      fontWeight: "700",
    },
  },
}));

export default function TipModal({ open, setOpen, id, type }) {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);

  const sendTipAPIHandler = async () => {
    const dataSend = {
      post_id: id,
    };
    const dataSend1 = {
      short_id: id,
    };
    try {
      setIsLoading(true);
      const response = await dataPostHandler(
        type === "post" ? "tipPost" : "tipshort",
        type === "post" ? dataSend : dataSend1
      );

      if (response.message) {
        toast.success(response.message);
        setIsLoading(false);
        setOpen(false);
      } else {
        toast.error(response.data.message);
        setIsLoading(false);
      }
    } catch (error) {
      console.log(error);
      toast.error(error?.response?.data?.message);
      setIsLoading(false);
    }
  };
  return (
    <AnimatePresence>
      <Dialog
        maxWidth="xs"
        fullWidth
        open={open}
        onClose={() => {
          if (!isLoading) {
            setOpen(false);
          }
        }}
      >
        <Box align="center">
          <Box mt={1}>
            <Typography variant="h4">Are you sure want to send tip?</Typography>
          </Box>
        </Box>
        <DialogContent>
          <IconButton
            className={classes.cancelBtn}
            onClick={() => {
              if (!isLoading) {
                setOpen(false);
              }
            }}
          >
            <IoClose />
          </IconButton>

          <Box mt={3} align="center">
            <Button
              variant="contained"
              color="secondary"
              disabled={isLoading}
              onClick={() => setOpen(false)}
            >
              No
            </Button>{" "}
            &nbsp;
            <Button
              variant="contained"
              color="secondary"
              disabled={isLoading}
              onClick={() => sendTipAPIHandler()}
            >
              Yes {isLoading && <ButtonCircularProgress />}{" "}
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
    </AnimatePresence>
  );
}
