import { getPerticularIdByPath } from "src/Apiconfigs/service";
import {
  Avatar,
  Box,
  makeStyles,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { AvatarGroup } from "@material-ui/lab";
import React, { useContext, useState, useEffect } from "react";
import { IMAGE_PATH } from "src/constants";
import CreateFeedModal from "src/component/CreateFeedModal";

import { SocialContext } from "src/context/SocialAuth";
import UserLoader from "src/Skeletons/UserLoader";
import { useHistory } from "react-router-dom";

const styles = makeStyles((theme) => ({
  mainActivity: {
    "& h3": {
      fontStyle: "normal",
      fontSize: "14px",
      lineHeight: "19px",
      color: "#FFFFFF",
      paddingTop: "17px",
    },
    "& span": {
      fontStyle: "normal",
      fontSize: "10px",
      color: "rgba(255, 255, 255, 0.6)",
      paddingLeft: "9px",
    },
    "& h6": {
      fontStyle: "normal",
      fontWeight: "600",
      fontSize: "10px",
      paddingTop: "6px",
      color: "#FFFFFF",
    },
  },
  avatraSection: {
    paddingTop: "8px",
  },
  avatargrp: {
    width: "40px",
    height: "40px",
    border: "1px solid #e7ddce",
    objectFit: "cover",
    transition: "0.3s",
    cursor: "pointer",
    left: "3px",
    "&:hover": {
      transform: "scale(1.2)",
    },
  },
}));
export default function ProfileActivity() {
  const social = useContext(SocialContext);
  const history = useHistory();
  const userData = social?.userData;
  const classes = styles();
  const [followersList, setFollowersList] = useState([]);
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [feedmodal, setFeedModal] = React.useState(false);

  const getFollowesListAPIHandler = async (id) => {
    const dataToSend = {
      page: page,
    };
    try {
      const response = await getPerticularIdByPath("followers", id, dataToSend);
      if (response.status === 200) {
        setFollowersList(response.data.data);
        setIsLoading(false);
      }
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };
  useEffect(() => {
    if (social?.userData?.user_id) {
      getFollowesListAPIHandler(social?.userData?.user_id);
    }
  }, [social?.userData?.user_id]);
  return (
    <Box className={classes.mainActivity}>
      <Box className={classes.avatraSection}>
        {isLoading ? (
          <UserLoader />
        ) : (
          <AvatarGroup max={6}>
            {followersList &&
              followersList.map((data, i) => {
                return (
                  <Tooltip
                    title={data?.follow_user_data?.name}
                    placement="top-start"
                    key={`toolTip${i}`}
                  >
                    <Avatar
                      onClick={() =>
                        history.push({
                          pathname: "/view-othersprofile",
                          search: data?.follow_user_data?.user_id.toString(),
                        })
                      }
                      className={classes.avatargrp}
                      alt={data?.follow_user_data?.name}
                      src={
                        data &&
                        `${IMAGE_PATH}` + data?.follow_user_data?.profile_url
                      }
                    />
                  </Tooltip>
                );
              })}
          </AvatarGroup>
        )}

        <Typography variant="h3">
          {userData?.total_followers} <span>Followers</span>
        </Typography>
        <Box>
          <span style={{ paddingLeft: "0px", paddingTop: "11px" }}>
            The perfect time for uploading your new post
          </span>
          <Typography
            style={{
              color: "#FDC763",
              textDecoration: "underline",
              cursor: "pointer",
            }}
            onClick={() => {
              setFeedModal(true);
            }}
          >
            Create new post
          </Typography>
        </Box>
      </Box>
      <CreateFeedModal
        setFeedModal={setFeedModal}
        feedmodal={feedmodal}
        type="new"
      />
    </Box>
  );
}
