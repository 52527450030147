import React, { useState, useContext, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles, Container, Box } from "@material-ui/core";
import NavBar from "./NavBar";
import TopBar from "./TopBar";
import CustomTaost from "src/component/CustomToast";
import { SocialContext } from "src/context/SocialAuth";
const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    width: "100%",
    backgroundColor: "#090909",
  },
  wrapper: {
    position: "relative",
    zIndex: "9",
    width: "calc(100% - 240px)",
    [theme.breakpoints.up("lg")]: {},
    "@media (max-width:1279px)": { width: "100%" },
    "@media (max-width:599px)": {},
  },
  contentContainer: {
    flex: "1 1 auto",
    padding: "0px 0px 0px 0px",
    [theme.breakpoints.down("sm")]: {
      padding: " 20px 0px",
    },
  },
  content: {
    flex: "1 1 auto",
    height: "100%",
    position: "relative",
    WebkitOverflowScrolling: "touch",
    [theme.breakpoints.down("sm")]: {
      zIndex: "9",
      overflow: "hidden",
    },
  },
  mainBox_layout: {
    display: "flex",
  },
}));

const DashboardLayout = ({ children }) => {
  const classes = useStyles();
  const social = useContext(SocialContext);
  const { createPostState, setCreatePostState } = social;
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);
  useEffect(() => {
    if (createPostState?.state === "successfull") {
      setTimeout(
        () =>
          setCreatePostState({
            text: "",
            color: "",
            state: "",
          }),
        5000
      );
    }
  }, [createPostState?.state]);
  return (
    <div className={classes.root}>
      <TopBar onMobileNavOpen={() => setMobileNavOpen(true)} />

      <Container maxWidth="lg" className="mainMobileView" id="scrollableDiv">
        <div className={classes.mainBox_layout}>
          <NavBar
            social={social}
            onMobileClose={() => setMobileNavOpen(false)}
            openMobile={isMobileNavOpen}
          />
          <div className={classes.wrapper}>
            <div className={classes.contentContainer}>
              <div className={classes.content} id="main-scroll">
                {createPostState.state !== "" && (
                  <CustomTaost
                    text={createPostState.text}
                    color={createPostState.color}
                  />
                )}
                {children}
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

DashboardLayout.propTypes = {
  children: PropTypes.node,
};

export default DashboardLayout;
