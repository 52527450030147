import firebase from "firebase";

var firebaseConfig = {
  apiKey: "AIzaSyDyoN3AXzhUD1GEtw5rE7Sf0i6cyYT6phI",
  authDomain: "tarality-development-project.firebaseapp.com",
  projectId: "tarality-development-project",
  storageBucket: "tarality-development-project.appspot.com",
  messagingSenderId: "869024873613",
  appId: "1:869024873613:web:a2d0d1e633d997ae1eb6c4",
};
firebase.initializeApp(firebaseConfig);

export default firebase;
