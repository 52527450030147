import { Box, Divider, makeStyles, Typography } from "@material-ui/core";
import React from "react";
import CreateStory from "./CreateStory";
import Heading from "./Heading";
import StoryList from "./StoryList";
const useStyles = makeStyles((theme) => ({
  headingSection: {},
}));

export default function Story() {
  const classes = useStyles();
  return (
    <Box>
      <Box className={classes.headingSection}>
        <Heading title="Stories" />
      </Box>
      <Box style={{ marginTop: "11px" }}>
        <Divider style={{ backgroundColor: "rgba(255, 255, 255, 0.1)" }} />
      </Box>
      <CreateStory
        img="images/createstorylogo.png"
        title="Create your story"
        desc=" Click button beside to create your story"
        type="story"
      />
      <StoryList />
      <Box style={{ paddingTop: "27px" }}>
        <Heading title="Profile Activity" />
      </Box>
    </Box>
  );
}
