export const API_WALLET = process.env.REACT_APP_API_MAIN_URL;
export const API_SOCIALS = process.env.REACT_APP_API_NOWWAY_URL;
// export const API_HOST = "http://172.16.1.132:3000/";
// export const MOBILE_API_HOST = process.env.REACT_APP_MOBILE;
export const CAPTCHA_KEY = process.env.REACT_APP_GOOGLE_CAPTCHA_KEY;
export const IMAGE_PATH = process.env.REACT_APP_FILE_URL;
export const SOCKET_URL = process.env.REACT_APP_SOCKET_URL;
export const BTC_ADDRESS_BTC = process.env.REACT_APP_BTC_ADDRESS;
export const BTC_TXN_BTC = process.env.REACT_APP_BTC_TXN;
export const ETH_ADDRESS_ETH = process.env.REACT_APP_ETH_ADDRESS;
export const ETH_TXN_ETH = process.env.REACT_APP_ETH_TXN;
export const BSC_TXN_BSC = process.env.REACT_APP_BNB_TXN;
export const MATIC_TXN_MATIC = process.env.REACT_APP_MATIC_TXN;
export const BSC_ADDRESS_BSC = process.env.REACT_APP_BNB_ADDRESS;
export const MATIC_ADDRESS_MATIC = process.env.REACT_APP_MATIC_ADDRESS;
export const BTCV_ADDRESS_BTCV = process.env.REACT_APP_BTCV_ADDRESS;
export const API_LOCAL_HOST = "http://10.1.4.245:3000/";

export const ETH = "0x2170Ed0880ac9A755fd29B2688956BD959F933F8";
export const USDT = "0x55d398326f99059fF775485246999027B3197955";
export const BTC = "0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c";
export const EBULLS = "0x5bd8d905e6bd267b8ee0a3a7ac6d431795792b3a"; //testnet
export const MYVEE = "0x4A64cEC042949693fc89Dd1D07D4b2068e7A168D";

export const SPOTIFY_CLIENT_ID = "9b0ad2d81bbb4521b28b19c1aecb82f7";
export const SPOTIFY_CLIENT_SECRET_ID = "facf14a568974fad822d3e7bcb66ae93";
export const MUSIC_BASE_URL = "https://deezerdevs-deezer.p.rapidapi.com/";
export const RAPID_API_KEY =
  "f91f18fa81msh9b42524fc110606p1269c5jsn87944e61349d";
export const RAPID_API_HOST = "deezerdevs-deezer.p.rapidapi.com";
export const rupeeSign = "₹";
export const validUrl = (value) => {
  const re =
    /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/;
  return re.test(value);
};
export const cmcAPIURl =
  "https://pro-api.coinmarketcap.com/v1/cryptocurrency/quotes/latest?convert=inr&symbol=";
export const dummydata = [
  {
    TrendingData: [
      {
        img: "images/bitcoin.png",
        bid: "BTC",
        coin: "Bitcoin",
        price: "63,284.03",
        change: "-19.43%",
        market: "63,284M",
      },
      {
        img: "images/eth.png",
        bid: "ETH",
        coin: "Ethereum",
        price: "63,284.03",
        change: "+23.05%",
        market: "63,284M",
      },
      {
        img: "images/bitcoin.png",
        bid: "BTC",
        coin: "Bitcoin",
        price: "63,284.03",
        change: "-19.43%",
        market: "63,284M",
      },
      {
        img: "images/eth.png",
        bid: "ETH",
        coin: "Ethereum",
        price: "63,284.03",
        change: "+23.05%",
        market: "63,284M",
      },
      {
        img: "images/bitcoin.png",
        bid: "BTC",
        coin: "Bitcoin",
        price: "63,284.03",
        change: "-19.43%",
        market: "63,284M",
      },
      {
        img: "images/eth.png",
        bid: "ETH",
        coin: "Ethereum",
        price: "63,284.03",
        change: "+23.05%",
        market: "63,284M",
      },
    ],
  },
  {
    MarketData: [
      {
        img: "images/bitcoin.png",
        bid: "BTC",
        coin: "Bitcoin",
        price: "63,284.03",
        change: "-19.43%",
        market: "63,284M",
      },
      {
        img: "images/eth.png",
        bid: "ETH",
        coin: "Ethereum",
        price: "63,284.03",
        change: "+23.05%",
        market: "63,284M",
      },
      {
        img: "images/bitcoin.png",
        bid: "BTC",
        coin: "Bitcoin",
        price: "63,284.03",
        change: "-19.43%",
        market: "63,284M",
      },
      {
        img: "images/eth.png",
        bid: "ETH",
        coin: "Ethereum",
        price: "63,284.03",
        change: "+23.05%",
        market: "63,284M",
      },
      {
        img: "images/bitcoin.png",
        bid: "BTC",
        coin: "Bitcoin",
        price: "63,284.03",
        change: "-19.43%",
        market: "63,284M",
      },
      {
        img: "images/eth.png",
        bid: "ETH",
        coin: "Ethereum",
        price: "63,284.03",
        change: "+23.05%",
        market: "63,284M",
      },
      {
        img: "images/bitcoin.png",
        bid: "BTC",
        coin: "Bitcoin",
        price: "63,284.03",
        change: "-19.43%",
        market: "63,284M",
      },
      {
        img: "images/eth.png",
        bid: "ETH",
        coin: "Ethereum",
        price: "63,284.03",
        change: "-19.43%",
        market: "63,284M",
      },
      {
        img: "images/bitcoin.png",
        bid: "BTC",
        coin: "Bitcoin",
        price: "63,284.03",
        change: "+23.05%",
        market: "63,284M",
      },
      {
        img: "images/eth.png",
        bid: "ETH",
        coin: "Ethereum",
        price: "63,284.03",
        change: "-19.43%",
        market: "63,284M",
      },
    ],
  },
];

export function getProfileBatch(type) {
  switch (type) {
    case "Commercial & Industrial":
      return "images/UserVerification/img8.png";
    case "Beauty, Cosmetic & Personal Care.":
      return "images/UserVerification/img7.png";
    case "Automotive, Aircraft":
      return "images/UserVerification/img6.png";
    case "Arts and entertainment":
      return "images/UserVerification/img5.png";
    case "Agriculture":
      return "images/UserVerification/img4.png";
    case "Advertising/marketing":
      return "images/UserVerification/img3.png";
    case "Education":
      return "images/UserVerification/img2.png";
    case "Finance":
      return "images/UserVerification/img1.png";
  }
}
