import React, { useState } from "react";
import {
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Button,
  makeStyles,
  Typography,
  TextField,
} from "@material-ui/core";
import { MdCancel } from "react-icons/md";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { dataPostHandler } from "src/Apiconfigs/service";
import toast from "react-hot-toast";
import MightSkeleton from "src/component/MightSkeleton";
import { IoClose } from "react-icons/io5";

const useStyles = makeStyles((theme) => ({
  cancelBtn: {
    position: "absolute",
    top: 0,
    right: 0,
  },
  sharemodal: {
    "& button": {
      textAlign: "center",
      "& svg": {
        fontSize: "25px",
      },
    },
  },
  reportModal: {
    "& .MuiFormGroup-root": {
      display: "flex",
      flexWrap: "wrap",
      flexDirection: "column",
    },
    "& .MuiTypography-body1": {
      color: "#fff",
      fontWeight: "300",
      fontSize: "15px",
    },
    "& .MuiFormControlLabel-root": {
      borderTop: "1px solid rgba(255, 255, 255, 0.15)",
    },
    "& .MuiRadio-root": {
      color: "#FF9933",
      padding: "12px 0",
      marginRight: "11px",
    },
  },
  cancelBtn: {
    position: "absolute",
    top: 0,
    right: 0,
    "& svg": {
      color: "#D1913C",
      fontWeight: "700",
    },
  },
}));

export default function ShareModal({
  openReport,
  handleCloseReport,
  index,
  reportListData,
  reportLoader,
  postId,
  social,
  arrayData,
  setImagePostList,
}) {
  const classes = useStyles();
  const [value, setValue] = useState("");

  const [reportLoading, setReportLoading] = useState(false);

  const submitreporthandle = async () => {
    if (value !== "") {
      setReportLoading(true);
      try {
        const response = await dataPostHandler("report", {
          post_id: postId,
          keyword_id: value,
        });
        if (response) {
          if (arrayData) {
            let newFormValues = [...arrayData];
            newFormValues.splice(index, 1);
            setImagePostList(newFormValues);
          } else {
          }
          toast.success(response.message);
          handleCloseReport();
          setReportLoading(false);
        } else {
          toast.error(response.message);
          setReportLoading(false);
        }
      } catch (err) {
        console.log(err);
        setReportLoading(false);

        toast.error(err.response.data.message);
      }
    }
  };

  return (
    <Dialog
      open={openReport}
      onClose={handleCloseReport}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="xs"
      fullWidth
      key={`reportModal${index}`}
    >
      <DialogContent>
        <IconButton
          className={classes.cancelBtn}
          onClick={() => {
            if (!reportLoading) {
              handleCloseReport();
            }
          }}
        >
          <IoClose />
        </IconButton>
        <Typography
          variant="h6"
          style={{ color: "#fff", marginBottom: "10px" }}
        >
          Why are you reporting this post ?
        </Typography>
        <Box mb={2} className={classes.reportModal}>
          {reportLoader ? (
            [1, 2, 3].map((data) => {
              return <MightSkeleton type="reportList" />;
            })
          ) : (
            <RadioGroup
              aria-label=""
              name=""
              value={value}
              onChange={(e) => setValue(e.target.value)}
            >
              {reportListData &&
                reportListData.map((data, i) => {
                  return (
                    <FormControlLabel
                      value={data?.id}
                      control={<Radio checked={value == data?.id} />}
                      label={data?.keyword}
                      disabled={reportLoading}
                    />
                  );
                })}
            </RadioGroup>
          )}
        </Box>
        <Box mb={2} align="center">
          <Button
            variant="contained"
            color="primary"
            onClick={submitreporthandle}
            disabled={reportLoading}
          >
            Report
          </Button>
          <Button
            variant="contained"
            color="secondary"
            size="large"
            style={{ marginLeft: "10px", borderRadius: "50px" }}
            onClick={handleCloseReport}
            disabled={reportLoading}
          >
            Cancel
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
