import axios from "axios";
import Apiconfigs from "../config";
import toast from "react-hot-toast";
export const dataPostHandler = async (endPoint, dataToSend) => {
  try {
    const res = await axios({
      method: "POST",
      url: Apiconfigs[endPoint],
      headers: {
        authorization: window.localStorage.getItem("token"),
      },
      data: dataToSend,
    });
    if (res) {
      return res.data;
    } else {
      return res;
    }
  } catch (error) {
    console.log(error);
    return error.response;
    if (error?.response?.status === 440) {
      window.location.href = "/";
    }
    return error?.response?.data?.responseMessage;
  }
};
export const dataPostHandlerNew = async (endPoint, dataToSend) => {
  try {
    const res = await axios({
      method: "POST",
      url: Apiconfigs[endPoint],
      headers: {
        authorization: window.localStorage.getItem("token"),
      },
      data: dataToSend,
    });
    return res;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};
export const getDataHandlerAPI = async (endPoint, token) => {
  try {
    const res = await axios({
      method: "GET",
      url: Apiconfigs[endPoint],
      headers: {
        authorization: window.localStorage.getItem("token"),
      },
    });
    if (res) {
      return res;
    } else {
      return false;
    }
  } catch (error) {
    console.log(error);
    if (error?.response?.status === 440) {
      window.location.href = "/";
    }
    return false;
  }
};

export const patchAPIHandler = async (endPoint, dataToSend) => {
  try {
    const res = await axios({
      method: "PATCH",
      url: Apiconfigs[endPoint],
      headers: {
        authorization: window.localStorage.getItem("token"),
      },
      data: dataToSend,
    });
    if (res) {
      return res;
    } else {
      return false;
    }
  } catch (error) {
    console.log(error);
    if (error?.response?.status === 440) {
      window.location.href = "/";
    }
    toast.error(error?.response?.data?.responseMessage);
    return false;
  }
};

export const putAPIHandler = async (endPoint, dataToSend) => {
  try {
    const res = await axios({
      method: "PUT",
      url: Apiconfigs[endPoint],
      headers: {
        authorization: window.localStorage.getItem("token"),
      },
      data: dataToSend,
    });
    if (res) {
      return res;
    } else {
      return false;
    }
  } catch (error) {
    console.log(error);
    if (error?.response?.status === 440) {
      window.location.href = "/";
    }
    toast.error(error?.response?.data?.message);
    return false;
  }
};

export const getParticularObjectDataHanndler = async (endPoint, data) => {
  try {
    const res = await axios({
      method: "GET",
      url: `${Apiconfigs[endPoint]}`,
      headers: {
        authorization: window.localStorage.getItem("token"),
      },
      params: data,
    });
    if (res) {
      return res;
    } else {
      return false;
    }
  } catch (error) {
    console.log(error);
    if (error?.response?.status === 440) {
      window.location.href = "/";
    }
    return error?.response?.data?.responseMessage;
  }
};

export const deleteDataAPIHandler = async (endPoint, _id) => {
  try {
    const res = await axios({
      method: "DELETE",
      url: Apiconfigs[endPoint],
      headers: {
        authorization: window.localStorage.getItem("token"),
      },
      params: _id,
    });
    if (res) {
      return res;
    } else {
      return false;
    }
  } catch (error) {
    toast.error(error.response.data.message);
    if (error?.response?.status === 440) {
      window.location.href = "/";
    }
    return false;
  }
};

export const postAPIHandlerIdInPath = async (endPoint, _id) => {
  try {
    const res = await axios({
      method: "POST",
      url: `${Apiconfigs[endPoint]}/${_id}`,
      headers: {
        authorization: window.localStorage.getItem("token"),
      },
    });
    if (res) {
      return res;
    } else {
      return false;
    }
  } catch (error) {
    console.log(error);
    if (error?.response?.status === 440) {
      window.location.href = "/";
    }
    toast.error(error?.response?.data?.responseMessage);
    return false;
  }
};
export const postAPIHandlerIdInBody = async (endPoint, dataSend) => {
  try {
    const res = await axios({
      method: "POST",
      url: Apiconfigs[endPoint],
      headers: {
        authorization: window.localStorage.getItem("token"),
      },
      data: dataSend,
    });

    if (res) {
      return res;
    } else {
      return false;
    }
  } catch (error) {
    console.log(error);
    if (error?.response?.status === 440) {
      window.location.href = "/";
    }
    toast.error(error?.response?.data?.responseMessage);
    return false;
  }
};

export const getPerticularIdByPath = async (endPoint, _id, dataSend) => {
  try {
    const res = await axios({
      method: "GET",
      url: `${Apiconfigs[endPoint]}/${_id}`,
      headers: {
        authorization: window.localStorage.getItem("token"),
      },
      params: dataSend,
    });
    if (res) {
      return res;
    } else {
      return false;
    }
  } catch (error) {
    console.log(error);
    if (error?.response?.status === 440) {
      window.location.href = "/";
    }
    return false;
  }
};
export const putAPIHandlerInpath = async (endPoint, id, dataToSend) => {
  try {
    const res = await axios({
      method: "PUT",
      url: `${Apiconfigs[endPoint]}/${id}`,
      headers: {
        authorization: window.localStorage.getItem("token"),
      },
      data: dataToSend,
    });
    if (res) {
      return res;
    } else {
      return false;
    }
  } catch (error) {
    console.log(error);
    if (error?.response?.status === 440) {
      window.location.href = "/";
    }
    toast.error(error?.response?.data?.message);
    return false;
  }
};
export const getPerticularIdByQuery = async (endPoint, dataSend) => {
  try {
    const res = await axios({
      method: "GET",
      url: Apiconfigs[endPoint],
      headers: {
        authorization: window.localStorage.getItem("token"),
      },
      params: dataSend,
    });
    if (res) {
      return res;
    } else {
      return false;
    }
  } catch (error) {
    console.log(error);
    if (error?.response?.status === 440) {
      window.location.href = "/";
    }
    return false;
  }
};

export const postAPIHandlerIdInBodyWithResponse = async (
  endPoint,
  dataSend
) => {
  try {
    const res = await axios({
      method: "POST",
      url: Apiconfigs[endPoint],
      headers: {
        authorization: window.localStorage.getItem("token"),
      },
      data: dataSend,
    });
    if (res) {
      return res;
    } else {
      return false;
    }
  } catch (error) {
    if (error?.response?.status === 440) {
      window.location.href = "/";
    }
    return error?.response;
  }
};
