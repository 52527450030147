import React from "react";
import { Box } from "@material-ui/core";
import { getProfileBatch } from "src/constants";
export default function ProfileBatch({ data }) {
  const keyCheck =
    data?.genre_data !== undefined ? data?.genre_data : data?.genre_detail;

  return (
    <Box ml={0.5}>
      {keyCheck && keyCheck[0] && (
        <img
          src={getProfileBatch(keyCheck[0]["genre_detail.name"])}
          alt=""
          width="16px"
        />
      )}
    </Box>
  );
}
