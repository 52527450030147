import React from "react";
import { Redirect } from "react-router-dom";

export default function walletGuard(props) {
  const { children } = props;
  if (
    window.localStorage.getItem("checkWalletPIn") === null ||
    "" ||
    undefined
  ) {
    return <Redirect to="/crypto-login" />;
  }
  return <>{children}</>;
}
