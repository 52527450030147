import React, { useState, useContext, useEffect } from "react";
import {
  Typography,
  Box,
  Paper,
  Grid,
  Button,
  IconButton,
  Avatar,
  makeStyles,
  Menu,
  MenuItem,
} from "@material-ui/core";
import toast from "react-hot-toast";
import ListItemText from "@material-ui/core/ListItemText";
import { MdMoreVert } from "react-icons/md";
import ShareModal from "./ShareModal";
import ReportModal from "./ReportModal";
import BlockModal from "./BlockModal";
import { BiBlock, BiEdit } from "react-icons/bi";
import Slider from "react-slick";
import moment from "moment";
import { IMAGE_PATH } from "src/constants";
import { SocialContext } from "src/context/SocialAuth";
import { getDataHandlerAPI, putAPIHandler } from "src/Apiconfigs/service";
import { useHistory, useLocation } from "react-router-dom";
import PostCommonModal from "src/component/PostCommonModal";
import PostCardAccordian from "src/PostCard/PostCardAccordian";
import PostCardView from "src/PostCard/PostCardView";
import VideoCard from "src/PostCard/VideoCard";
import ReactHashtag from "react-hashtag";
import TipModal from "./TipModal";
import Player from "./VideoManager/Player";
import { IoMdShareAlt } from "react-icons/io";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import PoolCards from "./PoolCard";
import PollResultCards from "./PollResult";
import CreateFeedModal from "src/component/CreateFeedModal";
import { formatTextDescrition } from "src/utils";
import DeleteConfirmation from "./DeleteConfirmation";

const useStyles = makeStyles((theme) => ({
  hidden: {
    display: "-webkit-box",
    WebkitLineClamp: 2,
    overflow: "hidden",
    WebkitBoxOrient: "unset",
    color: "#fff",
    maxHeight: "40px",
  },
  cryptoBox: {
    background: "rgba(58, 193, 176, 0.25)",
    border: "1px solid #00FFDF",
    borderRadius: "8px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "29px",
    padding: "5px 10px",
  },
  "& backImg": {
    filter: " blur(12px)",
    color: "transparent",
  },
  flexbox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "end",
  },
  timelineClx: {
    background: "#0d0d0d",
    padding: "10px 10px",
    marginLeft: "56px",
  },
  usericon: {
    display: "flex",
  },
}));

export default function ({
  data,
  index,
  type,
  typeData,
  arrayData,
  setImagePostList,
  setOpenModalPar,
}) {
  const location = useLocation();
  const classes = useStyles();
  const history = useHistory();
  const social = useContext(SocialContext);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openReport, setOpenReport] = useState(false);
  const [openBlock, setOpenBlock] = useState(false);
  const [openShare, setOpenShare] = useState(false);
  const [reportListData, setReportListData] = useState([]);
  const [postIdData, setPostIdData] = useState("");
  const [reportLoader, setreportLoader] = useState(true);
  const [openModal, setOpenModal] = useState(false);
  const [modalData, setModalData] = useState();
  const [isHidden, setIsHidden] = useState(true);
  const [postTipOpen, setPostTipOpen] = useState(false);
  const [openFeedModal, setOpenFeedModal] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [postTipId, setPostTipId] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [copyMsg, setCopyMsg] = useState("");
  const [currentPostId, setCurrentPostId] = useState(0);
  const [indexNo, setIndexNo] = useState("");
  const [feedmodal, setFeedModal] = React.useState(false);
  const [shareFedd, setShareFedd] = useState("");

  useEffect(() => {
    const hashKey = location.search;
    setOpenModal(false);
  }, [location.search]);

  useEffect(() => {
    setTimeout(() => {
      setCopyMsg("");
    }, 2000);
  }, [copyMsg]);

  const postTipParticular = (id) => {
    setPostTipOpen(true);
    setPostTipId(id);
  };

  const handleClick = (event, data) => {
    setAnchorEl(event.currentTarget);
    setPostIdData(data);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const getreportList = async () => {
    try {
      const token = window.localStorage.getItem("token");
      const response = await getDataHandlerAPI("report_keywords", token);
      if (response.data) {
        setReportListData(response.data.data);
        setreportLoader(false);
      } else {
        setReportListData([]);
        setreportLoader(false);
      }
    } catch (err) {
      console.log(err);
      setreportLoader(false);
    }
  };
  const settings = {
    dots: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    autoPlay: false,
    centerMode: false,
    autoplaySpeed: 3000,
    beforeChange: (oldIndex, newIndex) => {
      setCurrentPostId(newIndex);
    },
  };

  function lengthFinder(data) {
    if (data?.length > 3) {
      return data.slice(0, 3);
    } else {
      return data;
    }
  }

  const deleteHandler = async (id) => {
    const dataSend = {
      post_id: id,
    };
    try {
      setIsLoading(true);
      const response = await putAPIHandler("deletePost", dataSend);
      if (response.status === 200) {
        toast.success(response.data.message);
        setIsLoading(false);
        setDeleteModalOpen(false);
        handleClose();
        let newFormValues = [...arrayData];
        newFormValues.splice(index, 1);
        setImagePostList(newFormValues);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  const threeDoteButton = (
    <>
      <IconButton
        onClick={(e) => handleClick(e, data)}
        aria-controls="customized-menu"
        aria-haspopup="true"
      >
        <MdMoreVert fontSize="25px" />
      </IconButton>
      <Menu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem
          onClick={() => {
            setShareFedd("timeline");
            setOpenShare(true);
            setAnchorEl(false);
            setOpenFeedModal(true);
          }}
          className="postShareMenuIcons"
        >
          <IoMdShareAlt style={{ fontSize: "17px" }} />
          &nbsp;
          <ListItemText primary="Share" />
        </MenuItem>

        {social?.userData?.user_id !== data?.user_data?.user_id && (
          <MenuItem
            onClick={() => {
              setOpenReport(true);
              setAnchorEl(false);
              getreportList();
            }}
            className="postShareMenuIcons"
          >
            <img src="images/report.png" style={{ width: "17px" }} />
            &nbsp;
            <ListItemText primary="Report" style={{ fontSize: "14px" }} />
          </MenuItem>
        )}
        {social?.userData?.user_id !== data?.user_data?.user_id && (
          <MenuItem
            onClick={() => {
              setOpenBlock(true);
              setAnchorEl(false);
            }}
            className="postShareMenuIcons"
          >
            <BiBlock style={{ fontSize: "17px" }} />
            &nbsp;
            <ListItemText primary="Block User" />
          </MenuItem>
        )}
        {social?.userData?.user_id === data?.user_data?.user_id && (
          <MenuItem
            onClick={() => {
              setFeedModal(true);
              setAnchorEl(false);
            }}
            className="postShareMenuIcons"
          >
            <BiEdit style={{ fontSize: "17px" }} />
            &nbsp;
            <ListItemText primary="Edit" />
          </MenuItem>
        )}
      </Menu>
    </>
  );

  const isExpiredData =
    moment().unix() >= moment(data?.pollDetails?.poll_end_date).unix();

  function checkDiscription(description) {
    return data?.shared_user_data ? data?.caption : data?.description;
  }

  return (
    <>
      {typeData === "modalcino" ? (
        <Grid container spacing={2} key={`modal1${index}`}>
          <Grid item lg={9} md={8} sm={12} xs={12}>
            <Box className="commentBoxModal">
              <Box className={classes.flexbox}>
                {data?.post_type === "crypto" ? (
                  <img src="images/social_icon.png" width="25px" />
                ) : (
                  <img src="images/loginlogopin.png" width="25px" />
                )}
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <Box className="closeModalIcon">{threeDoteButton}</Box>
              </Box>
              <ShareModal
                openShare={openShare}
                handleCloseShare={() => setOpenShare(false)}
                url={`${window.location.origin}/post-detail?${postIdData?.id}`}
                index={index}
                type="sharePost"
                data={data}
                timeLine={shareFedd}
                social={social}
              />
              <BlockModal
                openBlock={openBlock}
                handleCloseBlock={() => setOpenBlock(false)}
                index={index}
                postIdData={postIdData}
                social={social}
              />
              <Box className="slickSection">
                <Slider {...settings}>
                  {data?.post_meta_data?.length === 0 && (
                    <>
                      <Box
                        className="imgSection"
                        onClick={() => {
                          if (typeData !== "modalcino") {
                            setModalData(data?.id);
                            setOpenModal(true);
                          }
                        }}
                      >
                        {checkDiscription(data) && (
                          <ReactHashtag
                            renderHashtag={(hashtagValue) => (
                              <div
                                className="hasgTagTextColor"
                                onClick={() =>
                                  history.push({
                                    pathname: "/trending-hash",
                                    search: hashtagValue,
                                  })
                                }
                              >
                                &nbsp;{hashtagValue}&nbsp;
                              </div>
                            )}
                          >
                            {formatTextDescrition(checkDiscription(data))}
                          </ReactHashtag>
                        )}
                        <Box></Box>
                      </Box>
                    </>
                  )}
                  {data?.post_meta_data &&
                    data?.post_meta_data?.map((value, i) => {
                      return (
                        <>
                          {value?.post_type === "image" ? (
                            <Box
                              className="imgSection"
                              style={{
                                borderRadius: "10px",
                                overflow: "hidden",
                              }}
                            >
                              <img
                                src={
                                  value?.url !== null &&
                                  `${IMAGE_PATH}` + value?.url
                                }
                              />
                            </Box>
                          ) : value?.post_type === "text" ? (
                            <Box
                              className="imgSection"
                              onClick={() => {
                                if (typeData !== "modalcino") {
                                  setModalData(data?.id);
                                  setOpenModal(true);
                                }
                              }}
                            >
                              <Typography
                                variant="h6"
                                style={{
                                  wordBreak: "break-word",
                                }}
                              >
                                {checkDiscription(data) && (
                                  <ReactHashtag
                                    renderHashtag={(hashtagValue) => (
                                      <div
                                        className="hasgTagTextColor"
                                        onClick={() =>
                                          history.push({
                                            pathname: "/trending-hash",
                                            search: hashtagValue,
                                          })
                                        }
                                      >
                                        &nbsp;{hashtagValue}&nbsp;
                                      </div>
                                    )}
                                  >
                                    {formatTextDescrition(
                                      checkDiscription(data)
                                    )}
                                  </ReactHashtag>
                                )}
                              </Typography>
                            </Box>
                          ) : (
                            <Box
                              className="imgSection"
                              style={{
                                borderRadius: "10px",
                              }}
                            >
                              <Player
                                video={
                                  value?.url !== null &&
                                  `${IMAGE_PATH}` + value?.url
                                }
                                poster={
                                  value?.thumbnail_url !== null &&
                                  `${IMAGE_PATH}` + value?.thumbnail_url
                                }
                                id={data?.id}
                                currentPostId={currentPostId}
                                index={i}
                              />
                            </Box>
                          )}
                        </>
                      );
                    })}
                </Slider>
              </Box>
            </Box>
          </Grid>
          <Grid item lg={3} md={4} sm={12} xs={12}>
            <Box className="commentBoxModal">
              <Box className="newboxHeightForComment">
                <Box className="UserBoxModal">
                  <figure style={{ margin: "0" }}>
                    <Avatar
                      src={
                        data?.user_data?.profile_url !== null &&
                        `${IMAGE_PATH}` + data?.user_data?.profile_url
                      }
                    />
                  </figure>
                  <Box className="UserNAmeText">
                    <Box
                      onClick={() =>
                        history.push({
                          pathname: "/view-othersprofile",
                          search: data?.user_id?.toString(),
                        })
                      }
                    >
                      {" "}
                      <Typography variant="h5" style={{ color: "#ffffff" }}>
                        {data?.user_data?.name}
                      </Typography>
                    </Box>
                    <Typography
                      variant="body2"
                      component="small"
                      style={{ color: "#FDC763" }}
                    >
                      {moment(data?.created_at).startOf("").fromNow()}
                      &nbsp;{data?.type}
                    </Typography>
                  </Box>
                </Box>
                {data?.post_meta_data?.length !== 0 && (
                  <>
                    <Box style={{ maxHeight: "150px", overflow: "scroll" }}>
                      <Typography
                        variant="body2"
                        style={{ wordBreak: "break-word", paddingTop: "5px" }}
                      >
                        {checkDiscription(data) && (
                          <ReactHashtag
                            renderHashtag={(hashtagValue) => (
                              <div
                                className="hasgTagTextColor"
                                onClick={() =>
                                  history.push({
                                    pathname: "/trending-hash",
                                    search: hashtagValue,
                                  })
                                }
                              >
                                &nbsp;{hashtagValue}&nbsp;
                              </div>
                            )}
                          >
                            {formatTextDescrition(checkDiscription(data))}
                          </ReactHashtag>
                        )}
                      </Typography>
                    </Box>

                    {data?.shared_user_data && (
                      <SeconedUserData
                        classes={classes}
                        data={data}
                        history={history}
                      />
                    )}
                  </>
                )}
                <Box className={classes.modalComment}>
                  <PostCardAccordian
                    data={data}
                    social={social}
                    index={index}
                    type={type}
                    calssType="modal"
                    postTipParticular={postTipParticular}
                    setOpenModalPar={setOpenModalPar}
                    arrayData={arrayData}
                    setImagePostList={setImagePostList}
                  />
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
      ) : (
        <>
          {data?.is_poll == "yes" ? (
            <Paper
              elevation={1}
              key={`postCard${index}`}
              className="main_postCardBox"
            >
              <Box className="PostBox1">
                <ShareModal
                  openShare={openFeedModal}
                  handleCloseShare={() => setOpenFeedModal(false)}
                  url={`${window.location.origin}/post-detail?${postIdData?.id}`}
                  index={index}
                  type="sharePost"
                  data={data}
                  timeLine={shareFedd}
                  social={social}
                />
                <DeleteConfirmation
                  open={deleteModalOpen}
                  setOpen={(item) => setDeleteModalOpen(item)}
                  deleteHandler={deleteHandler}
                  postIdData={postIdData}
                  isLoading={isLoading}
                  type="post"
                />

                <Box mb={1} className="responsiveTextPostBox">
                  <Grid container style={{ paddingBottom: "8px" }}>
                    <Grid item xs={8} align="left">
                      <Box
                        className="UserBox"
                        onClick={() =>
                          history.push({
                            pathname: "/view-othersprofile",
                            search: data?.user_id?.toString(),
                          })
                        }
                      >
                        <figure>
                          <Avatar
                            src={
                              data?.user_data?.profile_url !== null &&
                              `${IMAGE_PATH}` + data?.user_data?.profile_url
                            }
                          />
                        </figure>
                        <Box>
                          <Typography
                            variant="h5"
                            style={{
                              color: "#ffffff",
                              wordBreak: "break-all",
                            }}
                          >
                            {data?.user_data?.name}
                          </Typography>
                          <Typography
                            variant="body2"
                            component="small"
                            style={{ color: "#FDC763" }}
                          >
                            {moment(data?.created_at).startOf("").fromNow()}
                            &nbsp;{data?.type}
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={4} align="right">
                      <Box className={classes.flexbox}>
                        {data?.post_type === "crypto" ? (
                          <img src="images/social_icon.png" width="25px" />
                        ) : (
                          <img src="images/loginlogopin.png" width="25px" />
                        )}
                        {threeDoteButton}
                      </Box>
                    </Grid>
                  </Grid>
                  <Box style={{ marginLeft: "56px" }}>
                    {isExpiredData ? (
                      <PollResultCards pollDetails={data} />
                    ) : (
                      <PoolCards pollDetails={data} social={social} />
                    )}
                  </Box>
                </Box>

                <PostCardAccordian
                  data={data}
                  social={social}
                  arrayData={arrayData}
                  type={type}
                  typeData={typeData}
                  postTipParticular={postTipParticular}
                  index={index}
                  calssType="main"
                  setOpenModalPar={setOpenModalPar}
                  setImagePostList={setImagePostList}
                />
              </Box>
            </Paper>
          ) : (
            <>
              {data?.post_meta_data?.length !== 0 && (
                <Paper
                  elevation={1}
                  key={`postCard${index}`}
                  className="main_postCardBox"
                >
                  <Box className="PostBox1">
                    <ShareModal
                      openShare={openFeedModal}
                      handleCloseShare={() => setOpenFeedModal(false)}
                      url={`${window.location.origin}/post-detail?${postIdData?.id}`}
                      index={index}
                      type="sharePost"
                      data={data}
                      timeLine={shareFedd}
                      social={social}
                    />
                    <DeleteConfirmation
                      open={deleteModalOpen}
                      setOpen={(item) => setDeleteModalOpen(item)}
                      deleteHandler={deleteHandler}
                      postIdData={postIdData}
                      isLoading={isLoading}
                      type="post"
                    />
                    <BlockModal
                      openBlock={openBlock}
                      handleCloseBlock={() => setOpenBlock(false)}
                      index={index}
                      postIdData={postIdData}
                      social={social}
                    />
                    <Box mb={1} className="responsiveTextPostBox">
                      <Grid container>
                        <Grid item xs={8} align="left">
                          <Box
                            className="UserBox"
                            onClick={() =>
                              history.push({
                                pathname: "/view-othersprofile",
                                search: data?.user_id?.toString(),
                              })
                            }
                          >
                            <figure>
                              <Avatar
                                src={
                                  data?.user_data?.profile_url !== null &&
                                  `${IMAGE_PATH}` + data?.user_data?.profile_url
                                }
                              />
                            </figure>
                            <Box>
                              <Typography
                                variant="h5"
                                style={{
                                  color: "#ffffff",
                                  wordBreak: "break-all",
                                }}
                              >
                                {data?.user_data?.name}
                              </Typography>
                              <Typography
                                variant="body2"
                                component="small"
                                style={{ color: "#FDC763" }}
                              >
                                {moment(data?.created_at).startOf("").fromNow()}
                                &nbsp;{data?.type}
                              </Typography>
                            </Box>
                          </Box>
                        </Grid>
                        <Grid item xs={4} align="right">
                          <Box className={classes.flexbox}>
                            {data?.post_type === "crypto" ? (
                              <img src="images/social_icon.png" width="25px" />
                            ) : (
                              <img src="images/loginlogopin.png" width="25px" />
                            )}
                            {threeDoteButton}
                          </Box>
                        </Grid>
                      </Grid>
                      {data?.post_meta_data &&
                        data?.post_meta_data[0]?.post_type !== "text" &&
                        data?.post_meta_data?.length !== 0 && (
                          <>
                            <div
                              variant="body2"
                              style={{
                                wordBreak: "break-word",
                                margin: "11px 0px 10px",
                                marginLeft: "56px",
                              }}
                            >
                              <div
                                className={isHidden ? classes.hidden : null}
                                style={{ color: "#fff" }}
                              >
                                {checkDiscription(data) && (
                                  <ReactHashtag
                                    renderHashtag={(hashtagValue) => (
                                      <div
                                        className="hasgTagTextColor"
                                        onClick={() =>
                                          history.push({
                                            pathname: "/trending-hash",
                                            search: hashtagValue,
                                          })
                                        }
                                      >
                                        &nbsp;{hashtagValue}&nbsp;
                                      </div>
                                    )}
                                  >
                                    {formatTextDescrition(
                                      checkDiscription(data)
                                    )}
                                  </ReactHashtag>
                                )}
                              </div>
                              {data &&
                                checkDiscription(data) !== undefined &&
                                checkDiscription(data)?.length > 150 && (
                                  <Box display="flex" justifyContent="end">
                                    <Button
                                      size="small"
                                      onClick={() => setIsHidden(!isHidden)}
                                      style={{
                                        color: "aquamarine",
                                        fontSize: "12px",
                                      }}
                                    >
                                      {isHidden ? "More" : "Less"}
                                    </Button>
                                  </Box>
                                )}
                            </div>
                          </>
                        )}

                      {window.location.pathname === "/own-page-home" ? (
                        <Box className="heightbox">
                          <Typography
                            variant="h6"
                            style={{
                              wordBreak: "break-word",
                              margin: "11px 0px 10px",
                            }}
                          >
                            {checkDiscription(data) && (
                              <ReactHashtag
                                renderHashtag={(hashtagValue) => (
                                  <div
                                    className="hasgTagTextColor"
                                    onClick={() =>
                                      history.push({
                                        pathname: "/trending-hash",
                                        search: hashtagValue,
                                      })
                                    }
                                  >
                                    &nbsp;{hashtagValue}&nbsp;
                                  </div>
                                )}
                              >
                                {formatTextDescrition(checkDiscription(data))}
                              </ReactHashtag>
                            )}
                          </Typography>
                        </Box>
                      ) : (
                        <>
                          {data?.post_meta_data &&
                          data?.post_meta_data?.length > 1 ? (
                            <Box
                              style={
                                data?.shared_user_data
                                  ? {
                                      background: "#0d0d0d",
                                      marginLeft: "56px",
                                    }
                                  : {
                                      marginLeft: "56px",
                                    }
                              }
                              className={
                                data?.shared_user_data && "singleImage"
                              }
                            >
                              <Box
                                id="fullsizeimg"
                                style={{ position: "relative" }}
                              >
                                <Box
                                  className={
                                    data?.post_meta_data?.length >= 3
                                      ? "wo_adaptive_media"
                                      : "wo_adaptive_media wo_adaptive_media_two"
                                  }
                                >
                                  {data?.post_meta_data &&
                                    lengthFinder(data?.post_meta_data)?.map(
                                      (value, i) => {
                                        return (
                                          <PostCardView
                                            value={value}
                                            data={data}
                                            typeData={typeData}
                                            setModalData={(item) =>
                                              setModalData(item)
                                            }
                                            setOpenModal={(item) =>
                                              setOpenModal(item)
                                            }
                                            index={i}
                                            key={`postCardView${i}`}
                                            setIndexNo={setIndexNo}
                                          />
                                        );
                                      }
                                    )}
                                </Box>{" "}
                              </Box>
                            </Box>
                          ) : (
                            <>
                              {data?.post_meta_data &&
                              data?.post_meta_data[0]?.post_type === "image" ? (
                                <>
                                  {data?.post_meta_data[0]?.url !== "" && (
                                    <Box
                                      id="fullsizeimg "
                                      className="singleImage backImg"
                                      onClick={() => {
                                        if (typeData !== "modalcino") {
                                          setModalData(data?.id);
                                          setOpenModal(true);
                                        }
                                      }}
                                      style={
                                        data?.shared_user_data
                                          ? {
                                              background: " #0d0d0d",
                                              marginLeft: "56px",
                                            }
                                          : {
                                              marginLeft: "56px",
                                            }
                                      }
                                    >
                                      <Box className="postImg singleImage">
                                        <LazyLoadImage
                                          effect="blur"
                                          style={{
                                            cursor: "pointer",
                                            width: " 100%",
                                          }}
                                          src={
                                            data?.post_meta_data[0]?.url !==
                                              null &&
                                            `${IMAGE_PATH}` +
                                              data?.post_meta_data[0]?.url
                                          }
                                        />
                                      </Box>
                                    </Box>
                                  )}
                                </>
                              ) : data?.post_meta_data &&
                                data?.post_meta_data[0]?.post_type ===
                                  "text" ? (
                                <Box
                                  style={{
                                    cursor: "pointer",
                                    marginLeft: "56px",
                                  }}
                                  className="postImg textPost"
                                  onClick={() => {
                                    if (typeData !== "modalcino") {
                                      setModalData(data?.id);
                                      setOpenModal(true);
                                    }
                                  }}
                                >
                                  <Typography
                                    variant="h6"
                                    style={{
                                      wordBreak: "break-word",
                                    }}
                                  >
                                    {checkDiscription(data) && (
                                      <ReactHashtag
                                        renderHashtag={(hashtagValue) => (
                                          <div
                                            className="hasgTagTextColor"
                                            onClick={() =>
                                              history.push({
                                                pathname: "/trending-hash",
                                                search: hashtagValue,
                                              })
                                            }
                                          >
                                            &nbsp;{hashtagValue}&nbsp;
                                          </div>
                                        )}
                                      >
                                        {formatTextDescrition(
                                          checkDiscription(data)
                                        )}
                                      </ReactHashtag>
                                    )}
                                  </Typography>
                                </Box>
                              ) : (
                                <Box
                                  style={
                                    data?.shared_user_data
                                      ? {
                                          background: " #0d0d0d",
                                          marginLeft: "56px",
                                        }
                                      : {
                                          marginLeft: "56px",
                                        }
                                  }
                                  className={
                                    data?.shared_user_data && "singleImage"
                                  }
                                >
                                  <div className="videos">
                                    <VideoCard
                                      id={data?.id}
                                      data={data}
                                      typeData={typeData}
                                      setOpenModal={(item) =>
                                        setOpenModal(item)
                                      }
                                      setModalData={(item) =>
                                        setModalData(item)
                                      }
                                    />
                                  </div>
                                </Box>
                              )}
                            </>
                          )}
                          {data?.shared_user_data && (
                            <SeconedUserData
                              classes={classes}
                              data={data}
                              history={history}
                            />
                          )}
                        </>
                      )}
                    </Box>

                    <PostCardAccordian
                      data={data}
                      social={social}
                      arrayData={arrayData}
                      type={type}
                      typeData={typeData}
                      postTipParticular={postTipParticular}
                      index={index}
                      calssType="main"
                      setOpenModalPar={setOpenModalPar}
                      setImagePostList={(item) => setImagePostList(item)}
                    />
                    {openModal && (
                      <PostCommonModal
                        modalData={modalData}
                        type="commentModal"
                        arrayData={arrayData}
                        setOpenModal={(item) => setOpenModal(item)}
                        openModal={openModal}
                        setImagePostList={(item) => setImagePostList(item)}
                      />
                    )}
                  </Box>
                </Paper>
              )}
            </>
          )}
        </>
      )}

      {postTipOpen && (
        <TipModal
          type="post"
          id={postTipId}
          open={postTipOpen}
          setOpen={(item) => setPostTipOpen(item)}
        />
      )}
      <ReportModal
        openReport={openReport}
        handleCloseReport={() => setOpenReport(false)}
        reportListData={reportListData}
        reportLoader={reportLoader}
        postId={postIdData?.id}
        social={social}
        arrayData={arrayData}
        setImagePostList={(item) => setImagePostList(item)}
      />
      {feedmodal && (
        <CreateFeedModal
          setFeedModal={setFeedModal}
          feedmodal={feedmodal}
          previousData={data}
          type="edit"
          timeLine={shareFedd}
        />
      )}
    </>
  );
}

function SeconedUserData({ classes, data, history }) {
  return (
    <Box className={classes.timelineClx}>
      <Box className={classes.usericon}>
        <Avatar src={`${IMAGE_PATH}` + data?.shared_user_data?.profile_url} />
        &nbsp;&nbsp;&nbsp;
        <Box>
          <Typography
            variant="h5"
            style={{
              whiteSpace: "nowrap",
            }}
          >
            {data?.shared_user_data?.name
              ? data?.shared_user_data?.name
              : "..."}
          </Typography>
          <Typography
            variant="body2"
            component="small"
            style={{ color: "#FDC763" }}
          >
            {moment(data?.shared_timestamp).startOf("").fromNow()}
          </Typography>
          <Box>
            <Typography
              variant="h6"
              style={{
                wordBreak: "break-word",
                textAlign: "start",
              }}
            >
              {data?.description && (
                <ReactHashtag
                  renderHashtag={(hashtagValue) => (
                    <div
                      className="hasgTagTextColor"
                      onClick={() =>
                        history.push({
                          pathname: "/trending-hash",
                          search: hashtagValue,
                        })
                      }
                    >
                      &nbsp;{hashtagValue}&nbsp;
                    </div>
                  )}
                >
                  {formatTextDescrition(data?.description)}
                </ReactHashtag>
              )}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
